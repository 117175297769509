import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import PDFMerger from "pdf-merger-js";

import Swal from "sweetalert2";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import LOGO from "../../assets/images/logo.png";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
  convert_imagetopdf,
  document_contact,
} from "../../components/CustomComponent.js";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class ListRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_create: false,
      modal_approve: false,
      loading: false,
      data: [],

      data_search: "",
      datetime_search: "",
      page:1, 

      id: "",
      billing_note_code: "",
      invoice_list: [],

      cash_status: false,
      transfer_status: false,
      check_status: false,
      total_price: "",
      cash_price: "",
      transfer_price: "",
      check_price: "",
      payment_date: "",
      payment_file: "",
      account_number: "",
    };
  }
  async componentDidMount() {
    await this.Get();
  }
  Get = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.data_search,
    };
    let result = await GET(tokens, "api/billing-note/list/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({
        data: result.data,
        end_index: result.count < result.end_index ? result.count : result.end_index,
        count: result.count,
        page_number: result.page_number,
        count_status: result.count_status,
      });
    }
    this.setState({ loading: false });
  };
  getById = async (id) => {
    let body = {
      data_search: "",
      billing_note_id: id,
    };
    let result = await GET(tokens, "api/billing-note/list/page/0", body);
    if (result && result.status) {
      console.log(result.data);
      console.log(JSON.parse(result.data.invoice_list));
      this.setState({
        id: id,
        invoice_list: JSON.parse(result.data.invoice_list),
        total_price: total(JSON.parse(result.data.invoice_list), "total_price"), 
        cash_price: result.data.cash_price??"",
        cash_status: result.data.cash_price ? true : false,
        transfer_price: result.data.transfer_price??"",
        transfer_status: result.data.transfer_price ? true : false,
        check_price: result.data.check_price??"",
        account_number: result.data.account_number??"",
        check_status: result.data.check_price ? true : false,
        payment_date: result.data.payment_date?result.data.payment_date.split("T")[0]:format_date(new Date(), "yyyy-mm-dd", 'en'),
        payment_file: result.data.payment_file,
        modal_approve: true
      });
    }
  };
  Create = async () => {
    let body = {};
    let result = await POST(tokens, "api/billing-note/update/" + this.state.billing_note_id, body);
    if (result && result.status) {
      success_url("/Invoice/list");
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="Billing Note" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>Billing Note</b>
              </h3> 
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3 align-items-center">
                <input
                  className="form-control wpx-250 bg-contain border-0 me-3 mb-3"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ data_search: e.target.value });
                  }}
                  value={this.state.data_search}
                />
                <button
                  onClick={() => {
                    this.setState({ page: 1 });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-primary mx-1 mb-3"
                >
                  ค้นหา
                </button>
                <button
                  onClick={() => {
                    this.setState({ page: 1, data_search: "" });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-outline-primary mx-1 mb-3"
                >
                  รีเซ็ต
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">Billing Note</th>
                      <th className="text-left text-white">วันที่สร้าง</th>
                      <th className="text-left text-white">จำนวนเรื่อง ใบแจ้งหนี้ / รายการ</th>
                      {/* <th className="text-left text-white">ลูกค้า</th>
                      <th className="text-left text-white">บริการ</th>
                      <th className="text-left text-white">ผู้ขาย</th> */}
                      <th className="text-left text-white">สถานะ</th>
                      <th className="text-center text-white update">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{(this.state.page - 1) * 10 + index + 1}</td>
                          <td className="text-left">{item.billing_note_code ? item.billing_note_code : "-"}</td>
                          <td className="text-left">{item.createdAt ? format_date(item.createdAt) : "-"}</td>
                          <td className="text-left">{item.invoice_list ? JSON.parse(item.invoice_list).length : "-"}</td>
                          {/* <td className="text-left">{item.create_by ? item.create_by : "-"}</td>
                          <td className="text-left">{item.create_by ? item.create_by : "-"}</td>
                          <td className="text-left">{item.create_by ? item.create_by : "-"}</td> */}
                          <td className="text-left">{item.status === 1 ?"รอรับเงิน" : "รับเงินแล้ว"}</td>
                          <td className="text-center update">
                            <div className="w-100 d-flex justify-content-center">
                              {item.status === 1 &&(
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={async () => { 
                                  let invoice_list = item.invoice_list ? JSON.parse(item.invoice_list) : [];
                                  if(invoice_list && !invoice_list[0].broker_name){
                                    this.getById(item.billing_note_id)
                                  }else{
                                    window.location.href = "/BillingNote/update?id=" + item.billing_note_id;
                                  }
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                              </div>
                              )}
                              {/* <div
                                className="pointer wpx-30 hpx-30 rounded bg-warning-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={async () => {
                                  this.getById();
                                  this.setState({ modal_print: true });
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf1c1"}</label>
                              </div> */}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {this.state.data && this.state.data.length !== 0 && (
                <div className="d-flex justify-content-between mx-0 w-100 px-3 my-3">
                  <label>
                    แสดง {this.state.end_index} รายการ จาก {this.state.count} รายการ
                  </label>
                  <Pagination
                    count={Math.ceil(this.state.count / 10)}
                    page={this.state.page}
                    color="primary"
                    showFirstButton
                    shape="rounded"
                    showLastButton
                    onChange={(event, value) => {
                      this.setState({ page: value });
                      setTimeout(() => {
                        this.Get();
                      }, 10);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
         {/* เพิ่มสถานที่ทำงาน */}
         <Modal
          show={this.state.modal_approve}
          onHide={() => {
            this.setState({ modal_approve: false });
          }}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>
              <b>ยืนยันรายการจากตัวแทน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

          <div>
              <table className="table table-borderless table-striped">
                <thead className="bg-primary">
                  <tr>
                    <th className="text-center text-white">#</th>
                    <th className="text-left text-white">วันที่สร้าง</th>
                    <th className="text-left text-white">เลขที่ใบแจ้งหนี้</th>
                    <th className="text-left text-white">เลขเรื่อง</th>
                    <th className="text-left text-white">ลูกค้า</th>
                    <th className="text-left text-white">บริการ</th>
                    <th className="text-left text-white">ค่าบริการ</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.invoice_list &&
                    this.state.invoice_list.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center">งวดที่ {index + 1}</td>
                        <td className="text-left">{item.createdAt ? format_date(item.createdAt) : "-"}</td>
                        <td className="text-left">{item.invoice_code ? item.invoice_code : "-"}</td>
                        <td className="text-left">{item.receive_code ? item.receive_code : "-"}</td>
                        <td className="text-left">{item.full_name ? item.full_name : "-"}</td>
                        <td className="text-left">{item.menu_name ? item.menu_name : "-"}</td>
                        <td className="text-left">{item.total_price ? toFixed(item.total_price) : "-"}</td>
                      </tr>
                    ))}
                  <tr>
                    <td className="text-center"></td>
                    <td className="text-left"></td>
                    <td className="text-left"></td>
                    <td className="text-left"></td>
                    <td className="text-left"></td>
                    <td className="text-left">ยอดรวมทั้งหมด</td>
                    <td className="text-left">{toFixed(total(this.state.invoice_list, "total_price"))}</td>
                  </tr>
                </tbody>
              </table>
            </div> 
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 px-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_approve: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 px-1">
                <button
                  className="btn btn-danger w-100"
                  onClick={() => {
                    this.setState({ modal_approve: false });
                  }}
                >
                  ไม่อนุมัติ
                </button>
              </div> 
              <div className="w-50 px-1">
                <button
                  className="btn btn-warning w-100"
                  onClick={() => {
                    this.setState({ modal_approve: false });
                  }}
                >
                  ส่งกลับ
                </button>
              </div>
              <div className="w-50 px-1">
                <button
                  className="btn btn-success w-100"
                  onClick={() => {
                    window.location.href = "/BillingNote/update?id=" + this.state.id;
                  }}
                >
                  อนุมัติ
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
