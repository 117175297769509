import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import PDFMerger from "pdf-merger-js";
import JSZip from "jszip";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import LOGO from "../../assets/images/logo.png";
import { ReactExcel, readFile, generateObjects } from "@ramonak/react-excel";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";
import {
  locale,
  format_date_excel,
  tokens,
  days_between,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  number,
  LinktoBase64,
  // component
  DatePicker,
  convert_imagetopdf,
  document_bt46,
  document_contact,
  document_receipt_by_person,
  document_receipt,
} from "../../components/CustomComponent.js";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class ListRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_match: false,
      modal_update: false,
      modal_update_6: false,
      modal_update_7: false,
      modal_update_8: false,
      modal_update_11: false,
      modal_update_14: false,
      modal_update_14: false,
      modal_match2: false,
      modal_download: false,
      loading: false,
      data: [],
      data_menu_work: [],

      data_immigration_office: [],
      data_user: [],
      search: "",

      data_search: "",
      status_search: "",

      select_90d_date: "",
      select_visa_date: "",
      select_work_date: "",
      count_e_work: 0,
      count_wp: 0,
      fw_data: [],
      checklist_data: [],
      receive_id: "",
      receive_code: "",
      receive_date: null,
      branch: "",
      immigration_office: "",
      filing_type: "",
      billing_type: "",
      broker: "",
      emp_number: "",
      process_matter: "",
      cambodia_qty: 0,
      laos_qty: 0,
      myanmar_qty: 0,
      vietnam_qty: 0,
      total_qty: 0,
      remark: "",
      buy_health_insurance: null,
      type_health_insurance: "",
      buy_disease_service: null,
      type_disease_service: "",
      c_certificate: null,
      c_certificate_20: null,
      c_copy_committee: null,
      receive_status: 0,
      receive_date_s1: null,
      receive_by_s1: null,
      receive_date_s2: null,
      receive_by_s2: null,
      receive_date_s3: null,
      receive_by_s3: null,
      receive_date_s4: null,
      receive_by_s4: null,
      receive_date_s5: null,
      receive_by_s5: null,
      receive_date_s6: null,
      receive_by_s6: null,
      receive_date_s7: null,
      receive_by_s7: null,
      receive_date_s8: null,
      receive_by_s8: null,
      receive_date_s9: null,
      receive_by_s9: null,
      receive_date_s10: null,
      receive_by_s10: null,
      receive_date_s11: null,
      receive_by_s11: null,
      receive_date_s12: null,
      receive_by_s12: null,
      receive_date_s13: null,
      receive_by_s13: null,
      receive_date_s14: null,
      receive_by_s14: null,
      receive_date_s15: null,
      receive_by_s15: null,
      receive_date_s16: null,
      receive_by_s16: null,
      receive_date_s17: null,
      receive_by_s17: null,
      receive_date_s18: null,
      receive_by_s18: null,
      receive_date_s19: null,
      receive_by_s19: null,
      receive_date_s20: null,
      receive_by_s20: null,
      receive_date_s21: null,
      receive_by_s21: null,
      receive_status_date: null,
      receive_status_by: null,
      is_active: true,
      create_by: "",
      emp_data: null,
      menu_data: null,

      check_received_e_work: false,

      check_close: false,

      deliver_documents_s9: null,
      sending_document_date_s9: null,
      sending_messenger_s9: null,
      remark_s9: "",
      documents_s9: null,

      deliver_documents_s16: null,
      sending_document_date_s16: null,
      sending_messenger_s16: null,
      remark_s16: "",
      documents_s16: null,

      deliver_documents_s19: null,
      sending_document_date_s19: null,
      sending_messenger_s19: null,
      remark_s19: "",
      documents_s19: null,
      remark_s20: "",

      page: 1,
      end_index: 0,
      count: 0,
      page_number: 0,

      show_tab: 0,

      data_business_type: [],
      data_deputize: [],
      count_status: null,

      status_group: "",
      data_salary: [],
      data_post_box: [],

      data_workplace: [],

      // พยาน
      data_admin: [],
      witness_power_1: "",
      witness_power_2_th: "",
      witness_power_2_en: "",
      witness_contract_1: "",
      witness_contract_2_th: "",
      witness_contract_2_en: "",

      documents_employer_s22: "",
      documents_power_s22: "",
      documents_sub_power_s22: "",
      documents_scan_name: "",
      documents_employer_receipt: "",
      documents_employer_receipt_after: "",

      data_work_type: [],

      admin_fullname: "",

      rate_list: [],
      due_date: "",
      due_location: "",
      due_remark: "",
      save_name_list: "",

      installment: [],

      data_header: null,
      payment_person: "",

      house_number: "",
      due_time: "",
      due_province: "",
      due_district: "",
      bt_52_document_before: "",
      bt_52_document_after: "",
      bt_53_document_before: "",
      bt_53_document_after: "",
      bt_55_document: "",
      power_attorney_document: "",
      calling_visa_document: "",
      consent_document: "",
      house_registration_document: "",

      report_out: "",
      post_box_no: [
        { document_name: "สำเนาเอกสารนายจ้าง", no: "", remark: "" },
        { document_name: "สำเนาเอกสารลูกจ้าง", no: "", remark: "" },
        { document_name: "เอกสารการเงิน", no: "", remark: "" },
      ],
    };
  }
  async componentDidMount() {
    let data = localStorage.getItem("detail_user") ? localStorage.getItem("detail_user") : localStorage.getItem("user");
    data = data ? JSON.parse(data) : null;
    if (data) {
      this.setState({ admin_fullname: data.full_name });
    }
    this.setState({ loading: true });
    await this.Get();
    this.setState({ loading: false });
    await this.GetImmigration();
    await this.GetAllData();
    await this.GetUserAll();
  }
  // GET
  Get = async () => {
    let body = {
      data_search: this.state.data_search,
      receive_id: "",
      menu: "MOU",
      status_group: this.state.status_group,
      menu_id: Number(this.state.status_search),
    };
    let result = await GET(tokens, "api/receive-matter/list/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({
        data: result.data,
        end_index: result.count < result.end_index ? result.count : result.end_index,
        count: result.count,
        page_number: result.page_number,
        count_status: result.count_status,
      });
    }
  };
  GetUserAll = async () => {
    let body = {
      data_search: "",
      user_id: "",
    };
    let result = await GET(tokens, "api/user/list/page/0", body);
    if (result && result.status) {
      this.setState({ data_user: result.data.filter((e) => e.department === "แมสเซนเจอร์") });
    }
  };
  GetImmigration = async () => {
    let body = {
      data_search: "",
      prefix_id: "",
    };
    let result = await GET(tokens, "api/setting/immigration/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_immigration_office: result.data.map((e) => {
          return { value: e.id.toString(), label: e.imm_name_th };
        }),
      });
    }
  };
  GetAllData = async () => {
    let result = await GET(tokens, "api/header-doc/list");
    if (result && result.status) {
      this.setState({
        data_header: result.data,
      });
    }
    result = await GET(tokens, "api/receive-matter-menu/list/page/" + this.state.page, {
      data_search: [],
      menu_id: "",
    });
    if (result && result.status) {
      this.setState({
        data_menu_work: result.data,
      });
    }
    result = await GET(tokens, "api/setting/business-type/list/page/0", {
      data_search: "",
      business_type_id: "",
    });
    if (result && result.status) {
      this.setState({
        data_business_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.business_type_th };
        }),
      });
    }
    result = await GET(tokens, "api/setting/work-type/list/page/0", {
      data_search: "",
      work_type_id: "",
    });
    if (result && result.status) {
      this.setState({
        data_work_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.work_type_th, en: e.work_type_en };
        }),
      });
    }
    result = await GET(tokens, "api/setting/prefix/list/page/0", {
      data_search: "",
      prefix_id: "",
    });
    if (result && result.status) {
      this.setState({
        data_prefix_th: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_th };
        }),
        data_prefix_en: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_en };
        }),
      });
    }
    result = await GET(tokens, "api/user/list/page/0", {
      data_search: "",
      user_id: "",
    });
    if (result && result.status) {
      this.setState({
        data_admin: result.data.map((e) => {
          return {
            value: e.user_id,
            label: e.full_name,
            ...e,
          };
        }),
      });
    }
    result = await GET(tokens, "api/minimum-wage/list", {
      data_search: "",
      minimum_wage_id: "",
    });
    if (result && result.status) {
      this.setState({
        data_salary: result.data,
      });
    }
    result = await GET(tokens, "api/setting/cabinet-number/list", {
      data_search: "",
      cabinet_number_id: "",
    });
    if (result && result.status) {
      this.setState({
        data_post_box: result.data,
      });
    }
  };
  GetWorkplace = async (id) => {
    let body = {
      data_search: "",
      pw_id: "",
      emp_id: id,
    };
    let result = await GET(tokens, "api/place-work/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_workplace: result.data.map((e) => {
          return {
            value: e.id,
            label: e.address_number + (e.moo ? " หมู่ " + e.moo : "") + (e.soi ? " ซอย " + e.soi : "") + (e.street ? " ถนน " + e.street : "") + " ตำบล " + e.sub_district + " อำเภอ " + e.district + " จังหวัด " + e.province + " รหัสไปรษณีย์ " + e.zipcode,
          };
        }),
      });
    }
  };
  GetByID = async (id) => {
    this.setState({
      loading: true,
      select_90d_date: "",
      select_visa_date: "",
      select_work_date: "",
    });
    let body = {
      data_search: "",
      receive_id: id,
      menu: "",
    };
    let result = await GET(tokens, "api/receive-matter/list/page/0", body);
    if (result && result.status) {
      console.log(result.data);
      this.setState({
        installment: result.data.installment,
        count_e_work: result.data.count_e_work,
        count_wp: result.data.count_wp,
        checklist_data: result.data.checklist_data,
        fw_data: result.data.fw_data.map((e) => {
          return {
            ...e,
            visa_allowed_date: e.visa_allowed_date ? e.visa_allowed_date.split("T")[0] : "",
            visa_expiry_date: e.visa_expiry_date ? e.visa_expiry_date.split("T")[0] : "",
          };
        }),
        receive_id: result.data.receive_id,
        receive_code: result.data.receive_code,
        receive_date: result.data.receive_date,
        branch: result.data.branch,
        immigration_office: result.data.immigration_office,
        filing_type: result.data.filing_type,
        billing_type: result.data.billing_type,
        broker: result.data.broker,
        payment_person: result.data.payment_person,
        emp_number: result.data.emp_number,
        process_matter: result.data.process_matter,
        cambodia_qty: result.data.cambodia_qty,
        laos_qty: result.data.laos_qty,
        myanmar_qty: result.data.myanmar_qty,
        vietnam_qty: result.data.vietnam_qty,
        total_qty: result.data.total_qty,
        remark: result.data.remark,
        buy_health_insurance: result.data.buy_health_insurance,
        type_health_insurance: result.data.type_health_insurance,
        buy_disease_service: result.data.buy_disease_service,
        type_disease_service: result.data.type_disease_service,
        c_certificate: result.data.c_certificate,
        c_certificate_20: result.data.c_certificate_20,
        c_copy_committee: result.data.c_copy_committee,
        r_username: result.data.r_username,
        r_password: result.data.r_password,
        receive_status: result.data.receive_status,
        receive_date_s1: result.data.receive_date_s1,
        receive_by_s1: result.data.receive_by_s1,
        receive_date_s2: result.data.receive_date_s2,
        receive_by_s2: result.data.receive_by_s2,
        receive_date_s3: result.data.receive_date_s3,
        receive_by_s3: result.data.receive_by_s3,
        receive_date_s4: result.data.receive_date_s4,
        receive_by_s4: result.data.receive_by_s4,
        receive_date_s5: result.data.receive_date_s5,
        receive_by_s5: result.data.receive_by_s5,
        receive_date_s6: result.data.receive_date_s6,
        receive_by_s6: result.data.receive_by_s6,
        receive_date_s7: result.data.receive_date_s7,
        receive_by_s7: result.data.receive_by_s7,
        receive_date_s8: result.data.receive_date_s8,
        receive_by_s8: result.data.receive_by_s8,
        receive_date_s9: result.data.receive_date_s9,
        receive_by_s9: result.data.receive_by_s9,
        receive_date_s10: result.data.receive_date_s10,
        receive_by_s10: result.data.receive_by_s10,
        receive_date_s11: result.data.receive_date_s11,
        receive_by_s11: result.data.receive_by_s11,
        receive_date_s12: result.data.receive_date_s12,
        receive_by_s12: result.data.receive_by_s12,
        receive_date_s13: result.data.receive_date_s13,
        receive_by_s13: result.data.receive_by_s13,
        receive_date_s14: result.data.receive_date_s14,
        receive_by_s14: result.data.receive_by_s14,
        receive_date_s15: result.data.receive_date_s15,
        receive_by_s15: result.data.receive_by_s15,
        receive_date_s16: result.data.receive_date_s16,
        receive_by_s16: result.data.receive_by_s16,
        receive_date_s17: result.data.receive_date_s17,
        receive_by_s17: result.data.receive_by_s17,
        receive_date_s18: result.data.receive_date_s18,
        receive_by_s18: result.data.receive_by_s18,
        receive_date_s19: result.data.receive_date_s19,
        receive_by_s19: result.data.receive_by_s19,
        receive_date_s20: result.data.receive_date_s20,
        receive_by_s20: result.data.receive_by_s20,
        receive_date_s21: result.data.receive_date_s21,
        receive_by_s21: result.data.receive_by_s21,

        receive_status_date: result.data.receive_status_date ? JSON.parse(result.data.receive_status_date) : {},
        receive_status_by: result.data.receive_status_by ? JSON.parse(result.data.receive_status_by) : {},
        is_active: result.data.is_active,
        create_by: result.data.create_by,
        emp_data: result.data.emp_data,
        data_deputize: result.data.dpz_data ? result.data.dpz_data : [],
        menu_data: result.data.menu_data,

        deliver_documents_s9: result.data.deliver_documents_s9,
        sending_document_date_s9: result.data.sending_document_date_s9,
        sending_messenger_s9: result.data.sending_messenger_s9,
        remark_s9: result.data.remark_s9,
        documents_s9: result.data.documents_s9,

        deliver_documents_s16: result.data.deliver_documents_s16,
        sending_document_date_s16: result.data.sending_document_date_s16,
        sending_messenger_s16: result.data.sending_messenger_s16,
        remark_s16: result.data.remark_s16,
        documents_s16: result.data.documents_s16,

        deliver_documents_s19: result.data.deliver_documents_s19,
        sending_document_date_s19: result.data.sending_document_date_s19,
        sending_messenger_s19: result.data.sending_messenger_s19,
        remark_s19: result.data.remark_s19,
        documents_s19: result.data.documents_s19,
        remark_s20: result.data.remark_s20,

        documents_employer_s22: result.data.documents_employer_s22,
        documents_power_s22: result.data.documents_power_s22,
        documents_sub_power_s22: result.data.documents_sub_power_s22,
        documents_scan_name: result.data.documents_scan_name,
        documents_employer_receipt: result.data.documents_employer_receipt,
        documents_employer_receipt_after: result.data.documents_employer_receipt_after,

        check_received_e_work: false,

        check_close: false,

        // พยาน
        witness_power_1: result.data.witness_power_1 ? this.state.data_admin.filter((e) => e.value === result.data.witness_power_1)[0] : "",
        witness_power_2_th: result.data.witness_power_2_th ? this.state.data_admin.filter((e) => e.value === result.data.witness_power_2_th)[0] : "",
        witness_power_2_en: result.data.witness_power_2_en,
        witness_contract_1: result.data.witness_contract_1 ? this.state.data_admin.filter((e) => e.value === result.data.witness_contract_1)[0] : "",
        witness_contract_2_th: result.data.witness_contract_2_th,
        witness_contract_2_en: result.data.witness_contract_2_en,

        due_date: result.data.due_date,
        due_location: result.data.due_location,
        due_remark: result.data.due_remark,
        save_name_list: result.data.save_name_list,

        house_number: result.data.house_number,
        due_time: result.data.due_time,
        due_province: result.data.due_province,
        due_district: result.data.due_district,
        bt_52_document_before: result.data.bt_52_document_before,
        bt_52_document_after: result.data.bt_52_document_after,
        bt_53_document_before: result.data.bt_53_document_before,
        bt_53_document_after: result.data.bt_53_document_after,
        bt_55_document: result.data.bt_55_document,
        power_attorney_document: result.data.power_attorney_document,
        calling_visa_document: result.data.calling_visa_document,
        consent_document: result.data.consent_document,
        house_registration_document: result.data.house_registration_document,

        report_out: result.data.report_out,

        post_box_no: result.data.post_box_no
          ? JSON.parse(result.data.post_box_no)
          : [
              { document_name: "สำเนาเอกสารนายจ้าง", no: "", remark: "" },
              { document_name: "สำเนาเอกสารลูกจ้าง", no: "", remark: "" },
              { document_name: "เอกสารการเงิน", no: "", remark: "" },
            ],

        rate_list: result.data.rate_list
          ? result.data.rate_list.map((e) => {
              return {
                qty_price: e.qty_price,
                qty: e.qty,
                discount: e.discount,
                discount_percent: e.discount_percent,
                vat: e.vat,
                remark: e.remark,
                service_type: e.service_type,
                total_price: e.service_type === "รายหัว" ? e.qty_price * e.qty - e.qty_price * e.qty * (e.discount_percent / 100) : e.vat + e.qty_price - (e.vat + e.qty_price) * (e.discount_percent / 100),
              };
            })
          : [],
      });
      if (result.data.quotation_id) {
        let body_2 = {
          data_search: "",
          quotation_id: result.data.quotation_id,
          menu: "",
          holder: 1,
        };
        let result_2 = await GET(tokens, "api/setting/quotation/list/page/0", body_2);
        if (result_2 && result_2.status) {
          console.log(result_2.data);
          this.setState({ installment: result_2.data.installment });
        }
      } else {
        this.setState({ installment: [] });
      }
    }
    this.setState({ loading: false });
  };
  Status = (item) => {
    let component = null;
    let class_style = "pointer rounded hpx-30 px-2 py-1 text-nowrap ";
    let Match_modal = () => {
      this.GetByID(item.receive_id);
      this.setState({ modal_match: true });
    };
    let Update_modal = () => {
      this.GetByID(item.receive_id);
      this.setState({ modal_update: true });
    };
    let Update_6_modal = async () => {
      await this.GetByID(item.receive_id);
      this.setState({ modal_update_6: true });
      if (item.receive_status === 61) {
        this.setState({
          fw_data: this.state.fw_data.map((e) => {
            return { ...e, status_90d: true, status_visa: true, status_work: true };
          }),
        });
      }
    };
    let Update_7_modal = async () => {
      await this.GetByID(item.receive_id);
      this.setState({ modal_update_7: true });
    };
    let Update_8_modal = async () => {
      await this.GetByID(item.receive_id);
      this.setState({ modal_update_8: true });
    };
    let Update_11_modal = async () => {
      await this.GetByID(item.receive_id);
      this.setState({ modal_update_11: true });
    };
    let Update_13_modal = async () => {
      await this.GetByID(item.receive_id);
      this.setState({ modal_update_13: true });
    };
    let Update_14_modal = async () => {
      await this.GetByID(item.receive_id);
      this.setState({ modal_update_14: true });
    };
    switch (item.receive_status) {
      case 1:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Match_modal()}>
            รอ Matching
          </span>
        );
        break;
      case 2:
        component = <span>มอบอำนาจ Matching</span>;
        break;
      case 3:
        component = <span>ใช้ User ของนายจ้าง</span>;
        break;
      case 4:
        component = <span className="text-danger">Machting ไม่ได้</span>;
        break;
      case 5:
        component = <span className="text-success">Machting สำเร็จ</span>;
        break;
      case 6:
        component = (
          <span className="text-info pointer" onClick={() => Update_modal()}>
            บันทึกลูกจ้างสำเร็จ {item.fw_data === item.total_qty ? "" : "(" + item.fw_data.filter((e) => e.status_complete_info).length + "/" + item.total_qty + ")"}
          </span>
        );
        break;
      case 7:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            รอตรวจสอบ บต.46+มอบอำนาจ
          </span>
        );
        break;
      case 8:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            {item.menu_data.id === 7 ? "เอกสารไม่ถูกต้อง" : item.menu_data.id === 6 ? "เอกสารไม่ถูกต้อง" : "เอกสารไม่ถูกต้อง บต.46"}
          </span>
        );
        break;
      case 9:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            {item.menu_data.id === 7 ? "เอกสารถูกต้องพร้อมยื่น" : item.menu_data.id === 6 ? "เอกสารถูกต้อง" : "เอกสาร บต.46+มอบอำนาจ ถูกต้อง"}
          </span>
        );
        break;
      case 10:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            {item.menu_data.id === 7 ? "ส่งเอกสาร ตม.๒๘ + ตม.๓๐ แล้ว" : item.menu_data.id === 6 ? "ส่งเอกสารแล้ว" : "ส่งเอกสาร บต.46+มอบอำนาจ แล้ว"}
          </span>
        );
        break;
      case 11:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            {item.fw_data && item.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length !== 0 ? "เอกสารไม่ถูกต้อง บต.46+มอบอำนาจ ครั้งสุดท้าย" : "รอตรวจสอบ บต.46+มอบอำนาจ ครั้งสุดท้าย"}
          </span>
        );
        break;
      case 12:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            {item.menu_data.id === 20 ? "รอบันทึกเลขประจำตัว" : "เอกสารถูกต้อง พร้อมยื่น"}
          </span>
        );
        break;
      case 13:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            ได้เลข WP {item.count_wp === item.total_qty ? "" : "(" + item.count_wp + "/" + item.total_qty + ")"}
          </span>
        );
        break;
      case 14:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            {[3, 4, 5, 6].includes(item.menu_data.id) ? (item.fw_data.filter((e) => e.fee_status).length === 0 ? "รอเก็บค่าบริการ" : "บันทึกรับเงินแล้ว " + "(" + item.fw_data.filter((e) => e.fee_status).length + "/" + item.fw_data.length + ")") : item.menu_data.id === 20 ? "รอบันทึกรับเงิน" : "ได้เลข WP แล้ว รออนุมัติ"}
          </span>
        );
        break;
      case 15:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            แนบใบเสร็จรับเงิน + ทะเบียนใบอนุญาตทำงาน
          </span>
        );
        break;
      case 16:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            {[3, 4, 5, 6].includes(item.menu_data.id) ? "ต่ออายุสำเร็จ" : item.menu_data.id === 8 ? "กรอกข้อมูล VISA แล้ว" : item.menu_data.id === 7 ? "เอกสาร ตม.๒๘ + ตม.๓๐ ถูกต้อง" : "แนบใบเสร็จแล้ว รอจัดส่ง"}
          </span>
        );
        break;
      case 17:
        component = (
          <span className={class_style + "bg-warning-light text-primary"} onClick={() => Update_modal()}>
            ส่งใบเสร็จให้ลูกค้าแล้ว รอออก eWork
          </span>
        );
        break;
      case 18:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            {item.menu_data.id === 7 ? "ส่งเอกสาร ตม.๒๘ + ตม.๓๐ แล้ว" : "ออก eWork แล้ว" + (item.count_e_work === item.total_qty ? "" : "(" + item.count_e_work + "/" + item.total_qty + ")")}
          </span>
        );
        break;
      case 19:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            {item.menu_data.id === 11 ? "รับ Passport แล้ว" : item.menu_data.id === 7 ? "รับ ตม.๒๘ + ตม.๓๐ แล้ว" : "รับ eWork แล้ว รอจัดส่ง"}
          </span>
        );
        break;
      case 20:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            {[3, 4, 5, 6].includes(item.menu_data.id) ? "ส่งเอกสารคืน" : [7, 8, 20].includes(item.menu_data.id) ? "จัดส่งเอกสารแล้ว" : item.menu_data.id === 11 ? "จัดส่ง Passport แล้ว" : item.menu_data.id === 7 ? "" : "ส่ง eWork แล้ว"}
          </span>
        );
        break;
      case 21:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            ปิดงาน
          </span>
        );
        break;
      case 22:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            เปิดเรื่องแล้ว
          </span>
        );
        break;
      case 23:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_modal()}>
            รอต่ออายุ
          </span>
        );
        break;
      case 24:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            ลงชื่อ scan
          </span>
        );
        break;
      case 25:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            รอกรอกข้อมูล VISA
          </span>
        );
        break;
      case 26:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            เอกสารถูกต้องพร้อมยื่น
          </span>
        );
        break;
      case 27:
        component = (
          <span className={class_style + "bg-cyan-light text-cyan"} onClick={() => Update_modal()}>
            บันทึกวันนัดรับ Passport แล้ว
          </span>
        );
        break;
      case 60:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_6_modal()}>
            {item.fw_data.filter((e) => e.status_complete_info).length === 0 ? "เปิดเรื่องแล้ว" : "บันทึกลูกจ้างสำเร็จ " + (item.fw_data === item.total_qty ? "" : "(" + item.fw_data.filter((e) => e.status_complete_info).length + "/" + item.total_qty + ")")}
          </span>
        );
        break;
      case 61:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            เก็บค่าบริการ
          </span>
        );
        break;
      case 62:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            เก็บค่าบริการ
          </span>
        );
        break;
      case 63:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            รอตรวจสอบเอกสาร
          </span>
        );
        break;
      case 64:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            จัดส่งเอกสารใบเสร็จ
          </span>
        );
        break;
      case 65:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            เอกสารไม่ถูกต้อง
          </span>
        );
        break;
      case 66:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            แนบเอกสาร
          </span>
        );
        break;
      case 67:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            แนบใบเสร็จรับเงินแล้ว
          </span>
        );
        break;
      case 68:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            ต่ออายุสำเร็จ
          </span>
        );
        break;
      case 69:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            แนบใบเสร็จ
          </span>
        );
        break;
      case 610:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            แนบใบเสร็จแล้ว
          </span>
        );
        break;
      case 611:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_6_modal()}>
            ปิดงาน
          </span>
        );
        break;
      case 70:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            เปิดเรื่องแล้ว
          </span>
        );
        break;
      case 71:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            บันทึกลูกจ้างสำเร็จ {item.fw_data === item.total_qty ? "" : "(" + item.fw_data.filter((e) => e.status_complete_info).length + "/" + item.total_qty + ")"}
          </span>
        );
        break;
      case 72:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            ตรวจสอบเอกสาร
          </span>
        );
        break;
      case 73:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            รอตรวจสอบเอกสาร
          </span>
        );
        break;
      case 74:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            เอกสาร ตม.28+ตม.30+หนังสือมอบอำนาจถูกต้อง
          </span>
        );
        break;
      case 75:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            เอกสาร ตม.28+ตม.30+หนังสือมอบอำนาจไม่ถูกต้อง
          </span>
        );
        break;
      case 76:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            รอตรวจสอบเอกสาร
          </span>
        );
        break;
      case 77:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            ส่งเอกสาร ตม.28+ตม.30+หนังสือมอบอำนาจช่วง แล้ว
          </span>
        );
        break;
      case 78:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            เอกสาร ตม.28+ตม.30+หนังสือมอบอำนาจช่วง ไม่ถูกต้อง
          </span>
        );
        break;
      case 79:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            จัดส่งเอกสารใบเสร็จ
          </span>
        );
        break;
      case 710:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            เอกสารถูกต้อง
          </span>
        );
        break;
      case 711:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            แนบ ตม.28+ตม.30 ที่ประทับตราเข้าสู่ระบบ
          </span>
        );
        break;
      case 712:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            จัดส่งเอกสารแล้ว
          </span>
        );
        break;
      case 713:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_7_modal()}>
            ปิดงาน
          </span>
        );
        break;
      case 80:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            เปิดเรื่องแล้ว
          </span>
        );
        break;
      case 81:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            บันทึกลูกจ้างสำเร็จ {item.fw_data === item.total_qty ? "" : "(" + item.fw_data.filter((e) => e.status_complete_info).length + "/" + item.total_qty + ")"}
          </span>
        );
        break;
      case 82:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            ตรวจสอบเอกสาร และ แนบไฟล์
          </span>
        );
        break;
      case 83:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            รอตรวจสอบเอกสาร
          </span>
        );
        break;
        case 84:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            จัดส่งเอกสารใบเสร็จ
          </span>
        );
        break;
      case 85:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            เอกสาร ย้ายตรา visa ไม่ถูกต้อง
          </span>
        );
        break;
      case 86:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            รอตรวจสอบเอกสาร
          </span>
        );
        break;
      case 87:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            ส่งเอกสาร ย้ายตรา visa แล้ว
          </span>
        );
        break;
      case 88:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            เอกสาร ย้ายตรา visa ไม่ถูกต้อง
          </span>
        );
        break;
      case 89:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            เอกสารถูกต้อง
          </span>
        );
        break;
      case 810:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            กรอกข้อมูล visa เล่มใหม่แล้ว
          </span>
        );
        break;
      case 811:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            จัดส่งเอกสารแล้ว
          </span>
        );
        break;
      case 812:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_8_modal()}>
            ปิดงาน
          </span>
        );
        break;
      case 110:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            {item.fw_data.filter((e) => e.status_complete_info).length === 0 ? "เปิดเรื่องแล้ว" : "บันทึกลูกจ้างสำเร็จ " + (item.fw_data === item.total_qty ? "" : "(" + item.fw_data.filter((e) => e.status_complete_info).length + "/" + item.total_qty + ")")}
          </span>
        );
        break;
      case 111:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            รอกรอกเลขตู้
          </span>
        );
        break;
      case 112:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            รอตรวจสอบเอกสาร
          </span>
        );
        break;
      case 113:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            เอกสารไม่ถูกต้อง
          </span>
        );
        break;
      case 114:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            จัดส่งเอกสาร
          </span>
        );
        break;
      case 115:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            แนบเอกสารจากสถานฑูต
          </span>
        );
        break;
      case 116:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            แนบเอกสารแล้ว
          </span>
        );
        break;
      case 117:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            จัดส่งเอกสารแล้ว
          </span>
        );
        break;
      case 118:
        component = (
          <span className={class_style + "bg-primary-light text-primary"} onClick={() => Update_11_modal()}>
            ปิดงาน
          </span>
        );
        break;
      case 130:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            เปิดเรื่องแล้ว
          </span>
        );
        break;
      case 131:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            บันทึกลูกจ้างสำเร็จ {item.fw_data === item.total_qty ? "" : "(" + item.fw_data.filter((e) => e.status_complete_info).length + "/" + item.total_qty + ")"}
          </span>
        );
        break;
      case 132:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            ตรวจสอบเอกสาร
          </span>
        );
        break;
      case 133:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            รอตรวจสอบเอกสาร
          </span>
        );
        break;
      case 134:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            เอกสาร บต.53+หนังสือมอบอำนาจถูกต้อง
          </span>
        );
        break;
      case 135:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            เอกสาร บต.53+หนังสือมอบอำนาจ ไม่ถูกต้อง
          </span>
        );
        break;
      case 136:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            รอตรวจสอบเอกสาร
          </span>
        );
        break;
      case 137:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            ส่งเอกสาร บต.53+หนังสือมอบอำนาจช่วง แล้ว
          </span>
        );
        break;
      case 138:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            เอกสาร บต.53+หนังสือมอบอำนาจช่วง ไม่ถูกต้อง
          </span>
        );
        break;
      case 139:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            จัดส่งเอกสารใบเสร็จ
          </span>
        );
        break;
      case 1310:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            เอกสารถูกต้อง
          </span>
        );
        break;
      case 1311:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            แนบไฟล์ใบแจ้งออกแล้ว
          </span>
        );
        break;
      case 1312:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            จัดส่งเอกสารแล้ว
          </span>
        );
        break;
      case 1313:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_13_modal()}>
            ปิดงาน
          </span>
        );
        break;
        case 140:
          component = (
            <span className={class_style + "bg-danger-light"} onClick={() => Update_14_modal()}>
              เปิดเรื่องแล้ว
            </span>
          );
          break;
          case 141:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_14_modal()}>
            บันทึกลูกจ้างสำเร็จ {item.fw_data === item.total_qty ? "" : "(" + item.fw_data.filter((e) => e.status_complete_info).length + "/" + item.total_qty + ")"}
          </span>
        );
        break;
        case 142:
          component = (
            <span className={class_style + "bg-danger-light"} onClick={() => Update_14_modal()}>
              ตรวจสอบเอกสาร
            </span>
          );
          break;
          case 143:
            component = (
              <span className={class_style + "bg-danger-light"} onClick={() => Update_14_modal()}>
                รอตรวจสอบเอกสาร
              </span>
            );
            break;
            case 144:
              component = (
                <span className={class_style + "bg-danger-light"} onClick={() => Update_14_modal()}>
                  เอกสารถูกต้อง
                </span>
              );
              break;
              case 145:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_14_modal()}>
            เอกสารไม่ถูกต้อง
          </span>
        );
        break;
        case 146:
          component = (
            <span className={class_style + "bg-danger-light"} onClick={() => Update_14_modal()}>
              รอตรวจสอบเอกสาร
            </span>
          );
          break;
          case 147:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_14_modal()}>
            ส่งเอกสารแล้ว
          </span>
        );
        break;
      case 148:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_14_modal()}>
            เอกสารไม่ถูกต้อง
          </span>
        );
        break;
      case 149:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_14_modal()}>
            จัดส่งเอกสาร
          </span>
        );
        break;
      case 1410:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_14_modal()}>
            เอกสารถูกต้อง
          </span>
        );
        break;
      case 1411:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_14_modal()}>
            แนบไฟล์ใบแจ้งออกแล้ว
          </span>
        );
        break;
      case 1412:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_14_modal()}>
            จัดส่งเอกสารแล้ว
          </span>
        );
        break;
      case 1413:
        component = (
          <span className={class_style + "bg-danger-light"} onClick={() => Update_14_modal()}>
            ปิดงาน
          </span>
        );
        break;
       
        
      default:
        break;
    }
    return component;
  };
  Update = async () => {
    this.setState({ loading: true });
    let fw_data = this.state.fw_data;
    let receive_status = this.state.receive_status;
    if (this.state.receive_status === 9) {
      if (!this.state.deliver_documents_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s9 === "Messenger" && !this.state.sending_messenger_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s9) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 10;
    }
    if (this.state.receive_status === 10 && fw_data.filter((e) => !e.af_bt_46_document).length === 0) {
      if (this.state.menu_data.id === 6) {
        receive_status = 23;
      } else {
        receive_status = 11;
      }
    }
    if (fw_data.filter((e) => e.wp_number !== "" && e.wp_number !== null).length !== 0 && this.state.receive_status === 12) {
      receive_status = 13;
    }
    if (this.state.menu_data.id === 20) {
      if (fw_data.filter((e) => !e.fw_number).length === 0 && this.state.receive_status === 12) {
        receive_status = 14;
      }
      if (fw_data.filter((e) => !e.fw_number).length === 0 && this.state.receive_status === 13) {
        receive_status = 14;
      }
    } else {
      if (fw_data.filter((e) => !e.wp_number).length === 0 && this.state.receive_status === 12) {
        receive_status = 14;
      }
      if (fw_data.filter((e) => !e.wp_number).length === 0 && this.state.receive_status === 13) {
        receive_status = 14;
      }
    }
    if (this.state.menu_data.id === 7) {
      for (let item of fw_data) {
        if (item.passport_image && item.work_pm_image && item.visa_image) {
          item.status_complete_info = true;
        }
      }
      if ((this.state.receive_status === 6 || this.state.receive_status === 22) && fw_data.filter((e) => e.first_name_th && e.fw_number && e.nationality && e.passport_image && e.work_pm_image && e.visa_image).length === fw_data.length) {
        receive_status = 7;
      } else if (this.state.receive_status === 9) {
        if (!this.state.deliver_documents_s9) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (this.state.deliver_documents_s9 === "Messenger" && !this.state.sending_messenger_s9) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.sending_document_date_s9) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.remark_s9) {
          alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
          this.setState({ loading: false });
          return;
        }
        receive_status = 11;
      } else if (this.state.receive_status === 10 && fw_data.filter((e) => !e.tm_28_document || !e.tm_30_document).length === 0) {
        receive_status = 11;
      } else if (this.state.receive_status === 16) {
        if (!this.state.deliver_documents_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (this.state.deliver_documents_s16 === "Messenger" && !this.state.sending_messenger_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.sending_document_date_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.remark_s16) {
          alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
          this.setState({ loading: false });
          return;
        }
        receive_status = 18;
      } else if (this.state.receive_status === 18 && fw_data.filter((e) => !e.tm_28_document_after || !e.tm_30_document_after).length === 0) {
        receive_status = 19;
      } else if (this.state.receive_status === 19) {
        if (!this.state.deliver_documents_s19) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (this.state.deliver_documents_s19 === "Messenger" && !this.state.sending_messenger_s19) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.sending_document_date_s19) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.remark_s19) {
          alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
          this.setState({ loading: false });
          return;
        }
        receive_status = 20;
      }
    } else if (this.state.menu_data.id === 3) {
      if (this.state.receive_status === 14 && fw_data.filter((e) => !e.fee_document).length === 0) {
        receive_status = 20;
      }
    } else if (this.state.menu_data.id === 8) {
      if ((this.state.receive_status === 22 || this.state.receive_status === 6) && fw_data.filter((e) => !e.passport_image || !e.passport_old_image).length === 0) {
        receive_status = 7;
      }
    } else if (this.state.menu_data.id === 11) {
      if ((this.state.receive_status === 22 || this.state.receive_status === 6) && fw_data.filter((e) => !e.profile_image || !e.passport_old_image || !e.work_pm_image || !e.visa_image || !e.id_card_image || !e.attachment_name).length === 0) {
        receive_status = 7;
      }
      if (this.state.receive_status === 26) {
        if (!this.state.due_date) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือกวันนัด");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.due_location) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือกสถานที่นัด");
          this.setState({ loading: false });
          return;
        }
        receive_status = 27;
      }
      if (this.state.receive_status === 27 && fw_data.filter((e) => !e.passport_image).length === 0) {
        receive_status = 19;
      }
      if (this.state.receive_status === 19) {
        if (!this.state.deliver_documents_s19) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (this.state.deliver_documents_s19 === "Messenger" && !this.state.sending_messenger_s19) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.sending_document_date_s19) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.remark_s19) {
          alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
          this.setState({ loading: false });
          return;
        }
        receive_status = 20;
      }
    } else if (this.state.receive_status === 16) {
      if (!this.state.deliver_documents_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s16 === "Messenger" && !this.state.sending_messenger_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s16) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 17;
    } else {
      if (this.state.receive_status === 14 && fw_data.filter((e) => !e.bt_50_document || !e.fee_document || !e.is_download_s14).length === 0) {
        receive_status = 16;
      }
    }
    if (this.state.receive_status === 17 && fw_data.filter((e) => e.e_work_document || e.e_work_card_issued || e.e_work_card_received).length !== 0) {
      receive_status = 18;
    }
    if (this.state.receive_status === 17 && fw_data.filter((e) => !e.e_work_document || !e.e_work_card_issued || !e.e_work_card_received).length === 0) {
      receive_status = 19;
    }
    if (this.state.receive_status === 18 && fw_data.filter((e) => !e.e_work_document || !e.e_work_card_issued || !e.e_work_card_received).length === 0) {
      receive_status = 19;
    }
    if (this.state.receive_status === 19) {
      if (!this.state.deliver_documents_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s19 === "Messenger" && !this.state.sending_messenger_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s19) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 20;
    }
    if (this.state.receive_status === 20 && this.state.check_close) {
      receive_status = 21;
    }
    if ([3, 4, 5, 6, 20].includes(this.state.menu_data.id)) {
      if ([3, 4, 5, 6].includes(this.state.menu_data.id) && this.state.receive_status === 14) {
        receive_status = 23;
      } else if ([3, 4, 5, 6].includes(this.state.menu_data.id) && this.state.receive_status === 23 && fw_data.filter((e) => !e.document_renewal_status).length === 0) {
        receive_status = 16;
        for (let item of fw_data) {
          item.document_history = item.document_history ? JSON.parse(item.document_history) : [];
          item.document_history.push({
            passport_place: item.passport_place,
            passport_allowed_date: item.passport_allowed_date,
            passport_expiry_date: item.passport_expiry_date,
            visa_allowed_location: item.visa_allowed_location ? this.state.data_immigration_office.filter((e) => e.value === item.visa_allowed_location)[0].label : "",
            visa_allowed_date: item.visa_allowed_date,
            visa_expiry_date: item.visa_expiry_date,
            work_pm_allowed_location: item.work_pm_allowed_location ? this.state.data_immigration_office.filter((e) => e.value === item.work_pm_allowed_location)[0].label : "",
            work_pm_allowed_date: item.work_pm_allowed_date,
            work_pm_expiry_date: item.work_pm_expiry_date,
            notice_90d_allowed_location: item.notice_90d_allowed_location ? this.state.data_immigration_office.filter((e) => e.value === item.notice_90d_allowed_location)[0].label : "",
            notice_90d_allowed_date: item.notice_90d_allowed_date,
            notice_90d_expiry_date: item.notice_90d_expiry_date,
          });
          item.document_history = item.document_history.length !== 0 ? JSON.stringify(item.document_history) : "";
        }
      } else if ([20].includes(this.state.menu_data.id) && this.state.receive_status === 22 && this.state.documents_employer_s22 && this.state.documents_power_s22 && this.state.documents_sub_power_s22) {
        receive_status = 6;
      } else if ([20].includes(this.state.menu_data.id) && this.state.receive_status === 14 && fw_data.filter((e) => !e.fee_status).length === 0) {
        receive_status = 16;
      } else if (this.state.receive_status === 14 && fw_data.filter((e) => !e.fee_document || !e.fee_status).length === 0) {
        receive_status = 16;
      } else if (this.state.receive_status === 16) {
        if (!this.state.deliver_documents_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (this.state.deliver_documents_s16 === "Messenger" && !this.state.sending_messenger_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.sending_document_date_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.remark_s16) {
          alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
          this.setState({ loading: false });
          return;
        }
        receive_status = 20;
      }
    }
    if ([8].includes(this.state.menu_data.id)) {
      if (this.state.receive_status === 24 && this.state.documents_scan_name) {
        receive_status = 25;
      } else if (this.state.receive_status === 25 && fw_data.filter((e) => !e.visa_number || !e.visa_allowed_date || !e.visa_allowed_location || !e.visa_expiry_date).length === 0) {
        receive_status = 16;
      } else if (this.state.receive_status === 16) {
        if (!this.state.deliver_documents_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (this.state.deliver_documents_s16 === "Messenger" && !this.state.sending_messenger_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.sending_document_date_s16) {
          alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
          this.setState({ loading: false });
          return;
        }
        if (!this.state.remark_s16) {
          alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
          this.setState({ loading: false });
          return;
        }
        receive_status = 20;
      }
    }
    // แจ้งคนต่างด้าวออกจากงาน บต.53
    if (this.state.menu_data.id === 13) {
      if (fw_data.filter((e) => !e.fw_number || !e.work_permit_document || !e.passport_image).length === 0) {
        receive_status = 130;
      }
    }
    let body = {
      receive_status: receive_status,
      fw_data: fw_data,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
      remark_s20: this.state.remark_s20,

      documents_employer_s22: this.state.documents_employer_s22,
      documents_power_s22: this.state.documents_power_s22,
      documents_sub_power_s22: this.state.documents_sub_power_s22,
      documents_scan_name: this.state.documents_scan_name,
      documents_employer_receipt: this.state.documents_employer_receipt,
      documents_employer_receipt_after: this.state.documents_employer_receipt_after,

      house_number: this.state.house_number,
      due_time: this.state.due_time,
      due_province: this.state.due_province,
      due_district: this.state.due_district,
      bt_52_document_before: this.state.bt_52_document_before,
      bt_52_document_after: this.state.bt_52_document_after,
      bt_53_document_before: this.state.bt_53_document_before,
      bt_53_document_after: this.state.bt_53_document_after,
      bt_55_document: this.state.bt_55_document,
      power_attorney_document: this.state.power_attorney_document,
      calling_visa_document: this.state.calling_visa_document,
      consent_document: this.state.consent_document,
      house_registration_document: this.state.house_registration_document,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_6 = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if (receive_status === 61) {
      // STATUS 61 นายจ้าง
      receive_status = 63;
    } else if (receive_status === 62) {
      // STATUS 62 ลูกจ้าง
      receive_status = 63;
    } else if (receive_status === 66) {
      // STATUS 66 ลูกจ้าง
      if (this.state.documents_employer_receipt) {
        receive_status = 67;
      }
    } else if (receive_status === 67) {
      if (fw_data.filter((e) => !e.document_renewal_status).length === 0) {
        receive_status = 68;
        for (let item of fw_data) {
          item.document_history = item.document_history ? JSON.parse(item.document_history) : [];
          item.document_history.push({
            passport_place: item.passport_place,
            passport_allowed_date: item.passport_allowed_date,
            passport_expiry_date: item.passport_expiry_date,
            visa_allowed_location: item.visa_allowed_location ? this.state.data_immigration_office.filter((e) => e.value === item.visa_allowed_location)[0].label : "",
            visa_allowed_date: item.visa_allowed_date,
            visa_expiry_date: item.visa_expiry_date,
            work_pm_allowed_location: item.work_pm_allowed_location ? this.state.data_immigration_office.filter((e) => e.value === item.work_pm_allowed_location)[0].label : "",
            work_pm_allowed_date: item.work_pm_allowed_date,
            work_pm_expiry_date: item.work_pm_expiry_date,
            notice_90d_allowed_location: item.notice_90d_allowed_location ? this.state.data_immigration_office.filter((e) => e.value === item.notice_90d_allowed_location)[0].label : "",
            notice_90d_allowed_date: item.notice_90d_allowed_date,
            notice_90d_expiry_date: item.notice_90d_expiry_date,
          });
          item.document_history = item.document_history.length !== 0 ? JSON.stringify(item.document_history) : "";
        }
      }
    } else if (receive_status === 68) {
      // STATUS 68 ต่ออายุสำเร็จ
      if (!this.state.deliver_documents_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s16 === "Messenger" && !this.state.sending_messenger_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s16) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 69;
    } else if (receive_status === 69) {
      // STATUS 69 แนบใบเสร็จ
      if (this.state.documents_employer_receipt_after) {
        receive_status = 610;
      }
    } else if (this.state.receive_status === 610 && this.state.check_close) {
      // STATUS 611 แนบใบเสร็จแล้ว
      receive_status = 611;
    }
    let body = {
      receive_status: receive_status,
      fw_data: fw_data,

      documents_employer_receipt: this.state.documents_employer_receipt,
      documents_employer_receipt_after: this.state.documents_employer_receipt_after,
      // 130
      bt_53_document_before: this.state.bt_53_document_before,
      power_attorney_document: this.state.power_attorney_document,
      bt_53_document_after: this.state.bt_53_document_after,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
      remark_s20: this.state.remark_s20,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_7 = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if (receive_status === 70) {
      // STATUS 70 เปิดเรื่องแล้ว
      for (let fw of fw_data) {
        if (fw.emp_id && fw.place_work && fw.fw_number && fw.nationality && fw.prefix_en && fw.first_name_en && fw.visa_image && fw.passport_image && fw.work_pm_image && fw.issue_date && fw.issue_reason) {
          fw.status_complete_info = true;
        }
      }
      if (fw_data.filter((e) => e.status_complete_info).length === fw_data.length) {
        receive_status = 72;
      }
    } else if (receive_status === 71) {
      // STATUS 71 บันทึกลูกจ้างสำเร็จ 1/2
      for (let fw of fw_data) {
        if (fw.emp_id && fw.place_work && fw.fw_number && fw.nationality && fw.prefix_en && fw.first_name_en && fw.visa_image && fw.passport_image && fw.work_pm_image && fw.issue_date && fw.issue_reason) {
          fw.status_complete_info = true;
        }
      }
      if (fw_data.filter((e) => e.status_complete_info).length === fw_data.length) {
        receive_status = 72;
      }
    } else if (receive_status === 72) {
      // STATUS 72 ตรวจสอบเอกสาร
      receive_status = 73;
    } else if (receive_status === 74) {
      // STATUS 74 เอกสาร บต.53+หนังสือมอบอำนาจถูกต้อง
      if (!this.state.deliver_documents_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s9 === "Messenger" && !this.state.sending_messenger_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s9) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 76;
    } else if (receive_status === 710) {
      if (this.state.report_out) {
        receive_status = 711;
      }
    } else if (receive_status === 711) {
      // STATUS 711 แนบไฟล์ใบแจ้งออกแล้ว
      if (!this.state.deliver_documents_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s19 === "Messenger" && !this.state.sending_messenger_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s19) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 712;
    } else if (this.state.receive_status === 712 && this.state.check_close) {
      // STATUS 712 จัดส่งเอกสารแล้ว
      receive_status = 713;
    }
    let body = {
      receive_status: receive_status,
      fw_data: fw_data,

      documents_employer_receipt: this.state.documents_employer_receipt,
      documents_employer_receipt_after: this.state.documents_employer_receipt_after,
      bt_53_document_before: this.state.bt_53_document_before,
      power_attorney_document: this.state.power_attorney_document,
      bt_53_document_after: this.state.bt_53_document_after,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
      remark_s20: this.state.remark_s20,

      report_out: this.state.report_out,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_8 = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if (receive_status === 80) {
      // STATUS 80 เปิดเรื่องแล้ว
      for (let fw of fw_data) {
        if (fw.emp_id && fw.place_work && fw.fw_number && fw.nationality && fw.prefix_en && fw.first_name_en && fw.visa_image && fw.passport_image && fw.passport_old_image && fw.work_pm_image && fw.profile_image) {
          fw.status_complete_info = true;
        }
      }
      if (fw_data.filter((e) => e.status_complete_info).length === fw_data.length) {
        receive_status = 82;
      }
    } else if (receive_status === 81) {
      // STATUS 81 บันทึกลูกจ้างสำเร็จ 1/2
      for (let fw of fw_data) {
        if (fw.emp_id && fw.place_work && fw.fw_number && fw.nationality && fw.prefix_en && fw.first_name_en && fw.visa_image && fw.passport_image && fw.passport_old_image && fw.work_pm_image && fw.profile_image) {
          fw.status_complete_info = true;
        }
      }
      if (fw_data.filter((e) => e.status_complete_info).length === fw_data.length) {
        receive_status = 82;
      }
    } else if (receive_status === 82) {
      // STATUS 82 ตรวจสอบเอกสาร และ แนบไฟล์
      if (fw_data.filter((e) => e.document_evidence).length === fw_data.length) {
        receive_status = 83;
      }
    } else if (receive_status === 89) {
      // STATUS 89 เอกสารถูกต้อง
      if (fw_data.filter((e) => e.visa_image && e.visa_allowed_date && e.visa_allowed_location && e.visa_expiry_date && e.visa_number).length === fw_data.length) {
        receive_status = 810;
      }
    } else if (receive_status === 810) {
      // STATUS 810 กรอกข้อมูล visa เล่มใหม่แล้ว
      if (!this.state.deliver_documents_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s19 === "Messenger" && !this.state.sending_messenger_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s19) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 811;
    } else if (this.state.receive_status === 811 && this.state.check_close) {
      // STATUS 811 จัดส่งเอกสารแล้ว
      receive_status = 812;
    }
    let body = {
      receive_status: receive_status,
      fw_data: fw_data,

      documents_employer_receipt: this.state.documents_employer_receipt,
      documents_employer_receipt_after: this.state.documents_employer_receipt_after,
      bt_53_document_before: this.state.bt_53_document_before,
      power_attorney_document: this.state.power_attorney_document,
      bt_53_document_after: this.state.bt_53_document_after,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
      remark_s20: this.state.remark_s20,

      report_out: this.state.report_out,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_11 = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if (receive_status === 115) {
      // STATUS 115 แนบเอกสารจากสถานฑูต
      if (this.state.documents_employer_receipt) {
        receive_status = 116;
      }
    } else if (receive_status === 116) {
      // STATUS 116 แนบเอกสารแล้ว
      if (!this.state.deliver_documents_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s16 === "Messenger" && !this.state.sending_messenger_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s16) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 117;
    } else if (this.state.receive_status === 117 && this.state.check_close) {
      // STATUS 117 จัดส่งเอกสารแล้ว
      receive_status = 118;
    }
    let body = {
      receive_status: receive_status,
      fw_data: fw_data,

      documents_employer_receipt: this.state.documents_employer_receipt,
      documents_employer_receipt_after: this.state.documents_employer_receipt_after,
      // 130
      bt_53_document_before: this.state.bt_53_document_before,
      power_attorney_document: this.state.power_attorney_document,
      bt_53_document_after: this.state.bt_53_document_after,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
      remark_s20: this.state.remark_s20,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_13 = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if (receive_status === 130) {
      // STATUS 130 เปิดเรื่องแล้ว
      for (let fw of fw_data) {
        if (fw.emp_id && fw.place_work && fw.fw_number && fw.nationality && fw.prefix_en && fw.first_name_en && fw.visa_image && fw.passport_image && fw.work_pm_image && fw.issue_date && fw.issue_reason) {
          fw.status_complete_info = true;
        }
      }
      if (fw_data.filter((e) => e.status_complete_info).length === fw_data.length) {
        receive_status = 132;
      }
    } else if (receive_status === 131) {
      // STATUS 131 บันทึกลูกจ้างสำเร็จ 1/2
      for (let fw of fw_data) {
        if (fw.emp_id && fw.place_work && fw.fw_number && fw.nationality && fw.prefix_en && fw.first_name_en && fw.visa_image && fw.passport_image && fw.work_pm_image && fw.issue_date && fw.issue_reason) {
          fw.status_complete_info = true;
        }
      }
      if (fw_data.filter((e) => e.status_complete_info).length === fw_data.length) {
        receive_status = 132;
      }
    } else if (receive_status === 132) {
      // STATUS 132 ตรวจสอบเอกสาร
      receive_status = 133;
    } else if (receive_status === 134) {
      // STATUS 134 เอกสาร บต.53+หนังสือมอบอำนาจถูกต้อง
      if (!this.state.deliver_documents_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s9 === "Messenger" && !this.state.sending_messenger_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s9) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 136;
    } else if (receive_status === 1310) {
      if (this.state.report_out) {
        receive_status = 1311;
      }
    } else if (receive_status === 1311) {
      // STATUS 1311 แนบไฟล์ใบแจ้งออกแล้ว
      if (!this.state.deliver_documents_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s19 === "Messenger" && !this.state.sending_messenger_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s19) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 1312;
    } else if (this.state.receive_status === 1312 && this.state.check_close) {
      // STATUS 1312 จัดส่งเอกสารแล้ว
      receive_status = 1313;
    }
    let body = {
      receive_status: receive_status,
      fw_data: fw_data,

      documents_employer_receipt: this.state.documents_employer_receipt,
      documents_employer_receipt_after: this.state.documents_employer_receipt_after,
      bt_53_document_before: this.state.bt_53_document_before,
      power_attorney_document: this.state.power_attorney_document,
      bt_53_document_after: this.state.bt_53_document_after,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
      remark_s20: this.state.remark_s20,

      report_out: this.state.report_out,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_14 = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if (receive_status === 140) {
      // STATUS 140 เปิดเรื่องแล้ว
      for (let fw of fw_data) {
        if (fw.emp_id && fw.place_work && fw.fw_number && fw.nationality && fw.prefix_en && fw.first_name_en && fw.visa_image && fw.passport_image && fw.work_pm_image) {
          fw.status_complete_info = true;
        }
      }
      if (fw_data.filter((e) => e.status_complete_info).length === fw_data.length) {
        receive_status = 142;
      }
    } else if (receive_status === 141) {
      // STATUS 141 บันทึกลูกจ้างสำเร็จ 1/2
      for (let fw of fw_data) {
        if (fw.emp_id && fw.place_work && fw.fw_number && fw.nationality && fw.prefix_en && fw.first_name_en && fw.visa_image && fw.passport_image && fw.work_pm_image) {
          fw.status_complete_info = true;
        }
      }
      if (fw_data.filter((e) => e.status_complete_info).length === fw_data.length) {
        receive_status = 142;
      }
    } else if (receive_status === 142) {
      // STATUS 142 ตรวจสอบเอกสาร
      receive_status = 143;
    } else if (receive_status === 144) {
      // STATUS 144 เอกสาร บต.53+หนังสือมอบอำนาจถูกต้อง
      if (!this.state.deliver_documents_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s9 === "Messenger" && !this.state.sending_messenger_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s9) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 146;
    } else if (receive_status === 1310) {
      if (this.state.report_out) {
        receive_status = 1311;
      }
    } else if (receive_status === 1311) {
      // STATUS 1311 แนบไฟล์ใบแจ้งออกแล้ว
      if (!this.state.deliver_documents_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s19 === "Messenger" && !this.state.sending_messenger_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s19) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s19) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 1312;
    } else if (this.state.receive_status === 1312 && this.state.check_close) {
      // STATUS 1312 จัดส่งเอกสารแล้ว
      receive_status = 1313;
    }
    let body = {
      receive_status: receive_status,
      fw_data: fw_data,

      documents_employer_receipt: this.state.documents_employer_receipt,
      documents_employer_receipt_after: this.state.documents_employer_receipt_after,
      bt_53_document_before: this.state.bt_53_document_before,
      power_attorney_document: this.state.power_attorney_document,
      bt_53_document_after: this.state.bt_53_document_after,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
      remark_s20: this.state.remark_s20,

      report_out: this.state.report_out,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_fw = async () => {
    this.setState({ loading: true });
    let body = {
      receive_status: 14,
      fw_data: this.state.fw_data,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    this.setState({ loading: false });
  };
  Update_fw_6 = async () => {
    this.setState({ loading: true });
    let body = {
      receive_status: this.state.receive_status,
      fw_data: this.state.fw_data,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    this.setState({ loading: false });
  };
  Update_up = async () => {
    let body = {
      r_username: this.state.r_username,
      r_password: this.state.r_password,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/RecieveAll/list");
    }
    this.setState({ loading: false });
  };
  import_file = async (e, type) => {
    let file = e.target.files[0];
    if (file.size > 5000000) {
      alert("error", "แจ้งเตือน", "ขนาดไฟล์เกินกำหนด ไฟล์ต้องมีขนาดไม่เกิน 5 mb");
      return;
    }
    if (!file.type.includes("image") && !file.type.includes("pdf")) {
      alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {
      let files = file.type.includes("pdf") ? e.target.result.split(",")[1] : await convert_imagetopdf(e.target.result);
      let fw_data = this.state.fw_data;
      if (type === "bt_46_document") {
        fw_data[this.state.index].bt_46_document = files;
      } else if (type === "power_attorney_document") {
        fw_data[this.state.index].power_attorney_document = files;
      } else if (type === "af_bt_46_document") {
        fw_data[this.state.index].af_bt_46_document = files;
      } else if (type === "profile_image") {
        fw_data[this.state.index].profile_image = files;
      } else if (type === "id_card_image") {
        fw_data[this.state.index].id_card_image = files;
      } else if (type === "af_power_attorney_document") {
        fw_data[this.state.index].af_power_attorney_document = files;
      } else if (type === "af_contract_document") {
        fw_data[this.state.index].af_contract_document = files;
      } else if (type === "bt_50_document") {
        fw_data[this.state.index].bt_50_document = files;
      } else if (type === "fee_document") {
        fw_data[this.state.index].fee_document = files;
      } else if (type === "receipt_document") {
        fw_data[this.state.index].receipt_document = files;
      } else if (type === "work_permit_document") {
        fw_data[this.state.index].work_permit_document = files;
      } else if (type === "e_work_document") {
        fw_data[this.state.index].e_work_document = files;
      } else if (type === "e_received_document") {
        fw_data[this.state.index].e_received_document = files;
      } else if (type === "documents_employee_receipt") {
        fw_data[this.state.index].documents_employee_receipt = files;
      } else if (type === "visa_image") {
        fw_data[this.state.index].visa_image = files;
      } else if (type === "work_pm_image") {
        fw_data[this.state.index].work_pm_image = files;
      } else if (type === "passport_image") {
        fw_data[this.state.index].passport_image = files;
      } else if (type === "passport_old_image") {
        fw_data[this.state.index].passport_old_image = files;
      } else if (type === "attachment_name") {
        fw_data[this.state.index].attachment_name = files;
      } else if (type === "bt_44_document") {
        fw_data[this.state.index].bt_44_document = files;
      } else if (type === "bt_54_document") {
        fw_data[this.state.index].bt_54_document = files;
      } else if (type === "bt_55_document") {
        fw_data[this.state.index].bt_55_document = files;
      } else if (type === "document_evidence") {
        fw_data[this.state.index].document_evidence = files;
      } else if (type === "bt_44_update") {
        fw_data[this.state.index].bt_44_update = files;
      } else if (type === "bt_44_to") {
        fw_data[this.state.index].bt_44_to = files;
      } else if (type === "bt_44_due_to") {
        fw_data[this.state.index].bt_44_due_to = files;
      } else if (type === "new_register_permit_document") {
        fw_data[this.state.index].new_register_permit_document = files;
      } else if (type === "tm_6_document") {
        fw_data[this.state.index].tm_6_document = files;
      } else if (type === "tm_7_document") {
        fw_data[this.state.index].tm_7_document = files;
      } else if (type === "tm_28_document") {
        fw_data[this.state.index].tm_28_document = files;
      } else if (type === "tm_30_document") {
        fw_data[this.state.index].tm_30_document = files;
      } else if (type === "tm_28_document_after") {
        fw_data[this.state.index].tm_28_document_after = files;
      } else if (type === "tm_30_document_after") {
        fw_data[this.state.index].tm_30_document_after = files;
      } else if (type === "photo_15inch_document") {
        fw_data[this.state.index].photo_15inch_document = files;
      } else if (type === "training_book_document") {
        fw_data[this.state.index].training_book_document = files;
      } else if (type === "pink_card_document") {
        fw_data[this.state.index].pink_card_document = files;
      } else if (type === "documents_s9") {
        this.setState({ documents_s9: files });
      } else if (type === "documents_s16") {
        this.setState({ documents_s16: files });
      } else if (type === "documents_s19") {
        this.setState({ documents_s19: files });
      } else if (type === "documents_employer_s22") {
        this.setState({ documents_employer_s22: files });
      } else if (type === "documents_power_s22") {
        this.setState({ documents_power_s22: files });
      } else if (type === "documents_sub_power_s22") {
        this.setState({ documents_sub_power_s22: files });
      } else if (type === "documents_scan_name") {
        this.setState({ documents_scan_name: files });
      } else if (type === "documents_employer_receipt") {
        this.setState({ documents_employer_receipt: files });
      } else if (type === "documents_employer_receipt_after") {
        this.setState({ documents_employer_receipt_after: files });
      } else if (type === "bt_52_document_before") {
        this.setState({ bt_52_document_before: files });
      } else if (type === "bt_52_document_after") {
        this.setState({ bt_52_document_after: files });
      } else if (type === "bt_53_document_before") {
        this.setState({ bt_53_document_before: files });
      } else if (type === "bt_53_document_after") {
        this.setState({ bt_53_document_after: files });
      } else if (type === "bt_55_document") {
        this.setState({ bt_55_document: files });
      } else if (type === "power_attorney_document2") {
        this.setState({ power_attorney_document: files });
      } else if (type === "calling_visa_document") {
        this.setState({ calling_visa_document: files });
      } else if (type === "consent_document") {
        this.setState({ consent_document: files });
      } else if (type === "house_registration_document") {
        this.setState({ house_registration_document: files });
      } else if (type === "report_out") {
        this.setState({ report_out: files });
      }
      this.setState({ fw_data: fw_data });
    };
    let array_file = document.getElementsByName("file");
    for (let item of array_file) {
      item.value = "";
      item.file = null;
    }
  };
  import_excel = (e) => {
    readFile(e.target.files[0])
      .then((readedData) => {
        this.setState({ import: readedData });
        setTimeout(() => {
          this.setState({ loading: true });
          let currentSheet = this.state.currentSheet;
          currentSheet.Sheet1 = currentSheet.Sheet1.slice(1);
          let array2 = [];
          for (let item of currentSheet.Sheet1[0]) {
            if (array2.findIndex((e) => e === item) !== -1) {
              array2.push(item + "_");
            } else {
              array2.push(item);
            }
          }
          currentSheet.Sheet1[0] = array2;
          const result = generateObjects(currentSheet);
          // ตรวจสอบจำนวนลูกจ้าง
          if (this.state.fw_data.length < result.length) {
            alert("warning", "แจ้งเตือน", "ข้อมูลที่นำเข้ามีมากกว่าจำนวนลูกจ้างในระบบ");
            return;
          }
          let array_check = ["ลำดับที่", "นายจ้างเลขประจำตัว", "เลขประจำตัว 13 หลัก", "คำนำหน้า", "คำนำหน้า EN", "ลูกจ้างชื่อ EN", "สัญชาติ", "เพศ", "ว-ด-ป เกิด คศ"];
          for (let i = 0; i < array_check.length; i++) {
            if (this.state.currentSheet["Sheet1"][0][i] !== array_check[i]) {
              alert("warning", "แจ้งเตือน", 'หัวคอลัมน์ "' + array_check[i] + '" ไม่ถูกต้อง');
              this.setState({ loading: false });
              return;
            }
          }
          this.setState({ data_import: result.filter((item) => item["ลำดับที่"] !== ""), loading: false });
        }, 10);
      })
      .catch((error) => console.error(error));
    document.getElementById("file").value = "";
    document.getElementById("file").file = null;
  };
  create_import = async () => {
    this.setState({ loading: true });
    let result = this.state.data_import;
    let address_list = [];
    for (let item of result) {
      if (address_list.findIndex((e) => e.address_number === item["สถานที่ทำงานเลขที่ "] && e.moo === item["หมู่_"] && e.soi === item["ซอย_"] && e.sub_district === item["ตำบล/แขวง_"] && e.district === item["อำเภอ/เขต_"] && e.province === item["จังหวัด_"]) === -1) {
        if (item["สถานที่ทำงานเลขที่ "] === "" && item["หมู่_"] === "" && item["ซอย_"] === "" && item["ตำบล/แขวง_"] === "" && item["อำเภอ/เขต_"] === "" && item["จังหวัด_"] === "") {
        } else {
          address_list.push({
            address_number: item["สถานที่ทำงานเลขที่ "],
            moo: item["หมู่_"],
            soi: item["ซอย_"],
            sub_district: item["ตำบล/แขวง_"],
            district: item["อำเภอ/เขต_"],
            province: item["จังหวัด_"],
          });
        }
      }
    }
    // สร้างที่อยู่
    if (address_list.length !== 0) {
      let body = {
        import_data: address_list.map((e) => {
          return {
            emp_number: this.state.emp_number,
            address_number: e.address_number,
            moo: e.moo,
            soi: e.soi,
            sub_district: e.sub_district,
            district: e.district,
            province: e.province,
            zipcode: e.sub_district ? subdistrict.filter((item) => item.sub_district_name === e.sub_district.trim())[0]?.zip_code : "",
          };
        }),
      };
      let result2 = await POST(tokens, "api/employer/import", body);
      if (result2 && result2.status) {
        await this.GetWorkplace(this.state.emp_id);
      }
    }
    let immigration_list = [];
    for (let item of result) {
      if (immigration_list.findIndex((e) => e === item["ออกให้ที่_"]) === -1 && item["ออกให้ที่_"] !== "" && this.state.data_immigration_office.findIndex((e) => e.label === item["ออกให้ที่_"]) === -1) {
        immigration_list.push(item["ออกให้ที่_"]);
      }
    }
    // สร้าง ตม.
    if (immigration_list.length !== 0) {
      let body = {
        import_data: immigration_list.map((e) => {
          return {
            imm_name_th: e,
            imm_name_en: "",
          };
        }),
      };
      let result2 = await POST(tokens, "api/setting/immigration/import", body);
      if (result2 && result2.status) {
        await this.GetImmigration();
      }
    }
    let fw_data_empty = this.state.fw_data.filter((e) => !e.fw_number);
    let fw_data_have = this.state.fw_data.filter((e) => e.fw_number);
    let fw_index = 0;

    var data = result
      .filter((item) => item["ลำดับที่"] !== "")
      .map((item, index) => {
        // สถานที่ทำงาน
        let place_work = this.state.data_workplace2.filter((e) => e.address_number === item["สถานที่ทำงานเลขที่ "] && e.moo === item["หมู่_"] && e.soi === item["ซอย_"] && e.sub_district === item["ตำบล/แขวง_"] && e.district === item["อำเภอ/เขต_"] && e.province === item["จังหวัด_"]);
        // อายุ
        let day = days_between(new Date(), item["ว-ด-ป เกิด คศ"] ? new Date(format_date_excel(item["ว-ด-ป เกิด คศ"], "date", "en")) : new Date()).day;
        let age = Number((day / 365).toString().split(".")[0]);

        let fw_data = fw_data_have.filter((e) => e.fw_number === item["เลขประจำตัว 13 หลัก"]);
        let fw_id = "";

        if (fw_data.length !== 0) {
          fw_id = fw_data[0].id;
        } else {
          fw_id = fw_data_empty[fw_index].id;
          fw_index += 1;
        }
        // จัดข้อมูล
        return {
          fw_id: fw_id,
          receive_id: this.state.receive_id,
          emp_id: this.state.emp_id,
          business_type: Number(this.state.business_type.value),
          province: item["จังหวัด"],
          place_work: place_work.length !== 0 ? place_work[0].id : "",
          phone: "",
          email: "",

          fw_number: item["เลขประจำตัว 13 หลัก"],
          nationality: item["สัญชาติ"] === "เมียนมา" ? 1 : item["สัญชาติ"] === "ลาว" ? 2 : item["สัญชาติ"] === "กัมพูชา" ? 3 : item["สัญชาติ"] === "เวียดนาม" ? 4 : "",
          prefix_th: item["คำนำหน้า"] ? Number(this.state.data_prefix_th.filter((e) => e.label === item["คำนำหน้า"])[0].value) : "",
          first_name_th: "",
          middle_name_th: "",
          last_name_th: "",
          prefix_en: item["คำนำหน้า"] ? Number(this.state.data_prefix_th.filter((e) => e.label === item["คำนำหน้า"])[0].value) : "",
          first_name_en: item["ลูกจ้างชื่อ EN"],
          middle_name_en: "",
          last_name_en: "",
          birth_date: item["ว-ด-ป เกิด คศ"] ? format_date_excel(item["ว-ด-ป เกิด คศ"], "yyyy-mm-dd", "en") : null,
          age: age,
          gender: item["เพศ"] === "M" ? "ชาย" : item["เพศ"] === "W" ? "หญิง" : "",
          job_position: "",

          // ข้อมูลที่อยู่ลูกจ้าง
          fw_housing_type: item["สถานที่พักอาศัยเลขที่ "] === item["สถานที่ทำงานเลขที่ "] ? "ที่เดียวกับสถานที่ทำงาน" : item["สถานที่พักอาศัยเลขที่ "] === this.state.address_number ? "ที่เดียวกับที่ตั้ง/ที่อยู่ของนายจ้าง" : "ที่อื่น",
          fw_house_number: item["สถานที่พักอาศัยเลขที่ "],
          fw_building_name: "",
          fw_moo: item["หมู่"],
          fw_soi: item["ซอย"],
          fw_street: item["ถนน"],
          fw_province: item["จังหวัด"].trim(),
          fw_district: item["อำเภอ/เขต"].trim(),
          fw_sub_district: item["ตำบล/แขวง"].trim(),
          fw_zipcode: item["ตำบล/แขวง"].trim() ? subdistrict.filter((e) => e.sub_district_name === item["ตำบล/แขวง"].trim())[0]?.zip_code : "",
          fw_phone: "",
          fw_fax: "",
          fw_entry_country_date: "",
          fw_entry_checkpoint: "",
          fw_place_of_birth: item["สถานที่เกิด"],
          fw_country: "",

          // Passport
          passport_type: item["Passport no."] ? "Passport" : "",
          passport_number: item["Passport no."],
          passport_place: item["ออกให้ที่"],
          passport_born_place: item["สถานที่เกิด"],
          passport_country: item["สัญชาติ"],
          passport_allowed_date: item["ออกให้วันที่"] ? format_date_excel(item["ออกให้วันที่"], "yyyy-mm-dd", "en") : null,
          passport_expiry_date: item["ใช้ได้ถึง"] ? format_date_excel(item["ใช้ได้ถึง"], "yyyy-mm-dd", "en") : null,
          passport_image: "",

          passport_old_image: "",

          // Visa
          visa_number: item["VISA no."],
          visa_allowed_location: item["ออกให้ที่_"] ? Number(this.state.data_immigration_office.filter((e) => e.label === item["ออกให้ที่_"])[0].value) : "",
          visa_allowed_date: item["ออกให้วันที่_"] ? format_date_excel(item["ออกให้วันที่_"], "yyyy-mm-dd", "en") : null,
          visa_expiry_date: item["ใช้ได้ถึง_"] ? format_date_excel(item["ใช้ได้ถึง_"], "yyyy-mm-dd", "en") : null,
          visa_stay_kingdom_date: item["อนุญาตให้อยู่ในราชอาณาจักรได้ถึงวันที่"] ? format_date_excel(item["อนุญาตให้อยู่ในราชอาณาจักรได้ถึงวันที่"], "yyyy-mm-dd", "en") : null,
          visa_image: "",

          insurance_type: 3,
          sso_hospital_name: item["รพ.สปส."],
        };
      });
    let body = {
      import_data: data,
    };
    let result2 = await POST(tokens, "api/foreign-worker/import", body);
    if (result2 && result2.status) {
      Swal.fire({
        icon: "success",
        title: "สำเร็จ",
        text: "การทำรายการสำเร็จแล้ว",
        confirmButtonText: "ตกลง",
      }).then((re) => {
        window.location.reload();
      });
    }
    this.setState({ loading: false });
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ขอใบอนุญาตทำงาน" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>รายการรับเรื่องทั้งหมด</b>
              </h3>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3 justify-content-between">
                <select
                  className="form-control w-auto bg-contain border-0 mx-1 mb-3"
                  onChange={(e) => {
                    this.setState({
                      status_search: e.target.value,
                    });
                    setTimeout(() => {
                      this.Get();
                    }, 1);
                  }}
                  value={this.state.status_search}
                >
                  <option value="">รายการรับเรื่องทั้งหมด</option>
                  {this.state.data_menu_work.map((item, index) => (
                    <option value={item.id}>{item.menu_name}</option>
                  ))}
                </select>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">วันที่รับเรื่อง</th>
                      <th className="text-left text-white">เลขเรื่อง</th>
                      <th className="text-left text-white">ชื่อประเภทบริการ</th>
                      <th className="text-left text-white">ลูกค้า</th>
                      <th className="text-left text-white">สถานะงาน</th>
                      <th className="text-left text-white">ผู้บันทึกข้อมูล</th>
                      <th className="text-center text-white">ใบรับเรื่อง</th>
                      <th className="text-center text-white">Name List</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{(this.state.page - 1) * 10 + index + 1}</td>
                          <td className="text-left">{item.receive_date ? format_date(item.receive_date) : "-"}</td>
                          <td className="text-left">{item.receive_code ? item.receive_code : "-"}</td>
                          <td className="text-left">{item.menu_data ? item.menu_data.menu_name : "-"}</td>
                          <td className="text-left">{item.emp_data ? item.emp_data.full_name_th : "-"}</td>
                          <td className="text-left">
                            {item.receive_status} {this.Status(item)}
                          </td>
                          <td className="text-left">{item.create_by ? item.create_by : "-"}</td>
                          <td className="text-center">
                            <div
                              className="pointer bg-primary-light rounded text-primary px-2 py-1 wpx-80 m-auto"
                              onClick={() => {
                                window.location.href = "/RecieveAll/update?id=" + item.receive_id;
                              }}
                            >
                              ใบรับเรื่อง
                            </div>
                          </td>
                          <td className="text-center">
                            <div
                              className="pointer bg-warning-light rounded text-primary px-2 py-1 wpx-100 m-auto"
                              onClick={() => {
                                window.location.href = "/Employer/update?receive_id=" + item.receive_id + "&type=name_list&receive_codes=" + item.receive_code + "&menu_id=" + item.menu_data.id + "&menu_name=" + item.menu_data.menu_name;
                              }}
                            >
                              Name List
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {this.state.data && this.state.data.length !== 0 && (
                <div className="d-flex justify-content-between mx-0 w-100 px-3 my-3">
                  <label>
                    แสดง {this.state.end_index} รายการ จาก {this.state.count} รายการ
                  </label>
                  <Pagination
                    count={Math.ceil(this.state.count / 10)}
                    page={this.state.page}
                    color="primary"
                    showFirstButton
                    shape="rounded"
                    showLastButton
                    onChange={(event, value) => {
                      this.setState({ page: value });
                      setTimeout(() => {
                        this.Get();
                      }, 10);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_employer_receipt"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_employer_receipt");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_employer_receipt_after"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_employer_receipt_after");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_employee_receipt"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_employee_receipt");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_passport_image"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "passport_image");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_passport_old_image"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "passport_old_image");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_work_pm_image"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "work_pm_image");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_visa_image"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "visa_image");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_attachment_name"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "attachment_name");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_46_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_46_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_power_attorney_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "power_attorney_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_power_attorney_document2"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "power_attorney_document2");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_af_bt_46_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "af_bt_46_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_profile_image"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "profile_image");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_id_card_image"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "id_card_image");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_af_power_attorney_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "af_power_attorney_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_af_contract_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "af_contract_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_50_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_50_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_fee_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "fee_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_receipt_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "receipt_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_work_permit_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "work_permit_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_e_work_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "e_work_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_e_received_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "e_received_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_s9"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_s9");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_s16"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_s16");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_s19"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_s19");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_employer_s22"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_employer_s22");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_power_s22"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_power_s22");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_sub_power_s22"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_sub_power_s22");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_scan_name"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_scan_name");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_documents_scan_name"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "documents_scan_name");
            }}
          />

          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_44_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_44_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_54_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_54_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_55_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_55_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_document_evidence"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "document_evidence");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_new_register_permit_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "new_register_permit_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_tm_6_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "tm_6_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_tm_7_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "tm_7_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_tm_28_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "tm_28_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_tm_30_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "tm_30_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_tm_28_document_after"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "tm_28_document_after");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_tm_30_document_after"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "tm_30_document_after");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_photo_15inch_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "photo_15inch_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_training_book_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "training_book_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_pink_card_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "pink_card_document");
            }}
          />

          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_52_document_before"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_52_document_before");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_52_document_after"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_52_document_after");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_53_document_before"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_53_document_before");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_53_document_after"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_53_document_after");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_bt_55_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "bt_55_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_power_attorney_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "power_attorney_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_calling_visa_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "calling_visa_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_consent_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "consent_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_house_registration_document"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "house_registration_document");
            }}
          />
          <input
            className="d-none"
            type="file"
            name="file"
            id="file_report_out"
            accept="application/pdf,image/*"
            onChange={(e) => {
              this.import_file(e, "report_out");
            }}
          />
        </div>
        {/* แก้ไขเลข WP */}
        <Modal
          show={this.state.modal_update_wp}
          onHide={() => {
            this.setState({ modal_update: true, modal_update_wp: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>เลข WP</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-md-12">
                <span>แก้ไขเลข WP</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  maxLength={13}
                  onChange={(e) => {
                    this.setState({ wp_number: number(e.target.value) });
                  }}
                  value={this.state.wp_number}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({
                      modal_update: true,
                      modal_update_wp: false,
                    });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    let fw_data = this.state.fw_data;
                    fw_data[this.state.index].wp_number = this.state.wp_number;
                    this.setState({
                      fw_data: fw_data,
                      modal_update: true,
                      modal_update_wp: false,
                    });
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* Matching */}
        <Modal
          show={this.state.modal_match}
          onHide={() => {
            this.setState({ modal_match: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>Matching {this.state.receive_code ? this.state.receive_code : ""}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>
              เลขประจำตัวนายจ้าง <b>{this.state.emp_data ? this.state.emp_data.emp_number : ""}</b>
            </label>
            <div className="d-flex align-items-center">
              <input
                className="mt-1 me-3"
                type="radio"
                name="receive_status"
                onChange={(e) => {
                  this.setState({ receive_status: 5 });
                }}
                checked={this.state.receive_status === 5}
              />
              Matching ได้
              <input
                className="mt-1 mx-3"
                type="radio"
                name="receive_status"
                onChange={(e) => {
                  this.setState({ receive_status: 4 });
                }}
                checked={this.state.receive_status === 4}
              />
              Matching ไม่ได้
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_match: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    this.Update();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* status 2 มอบอำนาจ Matching */}
        <Modal
          show={this.state.modal_match2}
          onHide={() => {
            this.setState({ modal_match2: false });
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <b>User ของนายจ้าง</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>
              ใช้ User ของนายจ้าง เลขประจำตัว <b>{this.state.emp_data ? this.state.emp_data.emp_number : ""}</b>
            </label>
            <div className="row">
              <div className="col-6">
                <span className="wpx-150">User</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  value={this.state.r_username}
                  onChange={(e) => {
                    this.setState({ r_username: e.target.value });
                  }}
                />
              </div>
              <div className="col-6">
                <span className="wpx-150">Password</span>
                <input
                  className="form-control mt-1"
                  type="text"
                  value={this.state.r_password}
                  onChange={(e) => {
                    this.setState({ r_password: e.target.value });
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({
                      modal_match2: false,
                    });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={async () => {
                    await this.Update_up();
                    setTimeout(() => {
                      this.setState({ modal_match2: false });
                    }, 10);
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* ดาวน์โหลดเอกสาร */}
        <Modal
          show={this.state.modal_download}
          onHide={() => {
            this.setState({ modal_download: false });
          }}
          size="xl"
        >
          <Modal.Header>
            <Modal.Title>
              <b>ดาวน์โหลดเอกสาร</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="card-body">
              <table className="table table-borderless table-striped">
                <thead className="bg-primary">
                  <tr>
                    <th className="text-center text-white">ลำดับ</th>
                    <th className="text-left text-white">ชื่อ-นามสกุล</th>
                    <th className="text-left text-white">เลขบัตรประจำตัว</th>
                    <th className="text-center text-white">
                      บต.46 + สัญญาจ้าง
                      <span
                        className="px-2 py-1 rounded bg-white text-primary text-12 pointer ms-3 icon"
                        onClick={() => {
                          document_bt46(
                            this.state.fw_data,
                            this.state.emp_data,
                            this.state.data_workplace,
                            this.state.data_deputize,
                            this.state.data_salary,
                            {
                              witness_contract_1: this.state.witness_contract_1,
                              witness_contract_2_th: this.state.witness_contract_2_th,
                              witness_contract_2_en: this.state.witness_contract_2_en,
                            },
                            this.state.data_prefix_th,
                            this.state.data_prefix_en,
                            this.state.data_work_type,
                            this.state.data_business_type
                          );
                        }}
                      >
                        {"\uf019"}
                      </span>
                    </th>
                    <th className="text-left text-white">
                      บต.46 + สัญญาจ้าง เซ็นแล้ว
                      {this.state.fw_data.filter((e) => e.af_bt_46_document).length !== 0 && (
                        <span
                          className="px-2 py-1 rounded bg-white text-primary text-12 pointer ms-3 icon"
                          onClick={() => {
                            document_bt46(
                              this.state.fw_data,
                              this.state.emp_data,
                              this.state.data_workplace,
                              this.state.data_deputize,
                              this.state.data_salary,
                              {
                                witness_contract_1: this.state.witness_contract_1,
                                witness_contract_2_th: this.state.witness_contract_2_th,
                                witness_contract_2_en: this.state.witness_contract_2_en,
                              },
                              this.state.data_prefix_th,
                              this.state.data_prefix_en,
                              this.state.data_work_type,
                              this.state.data_business_type
                            );
                          }}
                        >
                          {"\uf019"}
                        </span>
                      )}
                    </th>
                    <th className="text-center text-white">
                      ใบมอบอำนาจ
                      <span
                        className="px-2 py-1 rounded bg-white text-primary text-12 pointer ms-3 icon"
                        onClick={() => {
                          document_contact(
                            this.state.fw_data,
                            this.state.emp_data,
                            this.state.data_workplace,
                            this.state.data_deputize,
                            {
                              witness_power_1: this.state.witness_power_1,
                              witness_power_2_th: this.state.witness_power_2_th,
                              witness_power_2_en: this.state.witness_power_2_en,
                            },
                            this.state.data_prefix_th,
                            this.state.data_prefix_en
                          );
                        }}
                      >
                        {"\uf019"}
                      </span>
                    </th>
                    <th className="text-left text-white">
                      ใบมอบอำนาจ เซ็นแล้ว
                      {this.state.fw_data.filter((e) => e.af_power_attorney_document).length !== 0 && (
                        <span
                          className="px-2 py-1 rounded bg-white text-primary text-12 pointer ms-3 icon"
                          onClick={() => {
                            document_contact(
                              this.state.fw_data,
                              this.state.emp_data,
                              this.state.data_workplace,
                              this.state.data_deputize,
                              {
                                witness_power_1: this.state.witness_power_1,
                                witness_power_2_th: this.state.witness_power_2_th,
                                witness_power_2_en: this.state.witness_power_2_en,
                              },
                              this.state.data_prefix_th,
                              this.state.data_prefix_en
                            );
                          }}
                        >
                          {"\uf019"}
                        </span>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.fw_data &&
                    this.state.fw_data.map((item, index) => (
                      <tr key={index}>
                        <td className="text-center align-middle">{index + 1}</td>
                        <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                        <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                        <td className="text-center align-middle">
                          <span
                            className="text-primary pointer"
                            onClick={() => {
                              document_bt46(
                                [item],
                                this.state.emp_data,
                                this.state.data_workplace,
                                this.state.data_deputize,
                                this.state.data_salary,
                                {
                                  witness_contract_1: this.state.witness_contract_1,
                                  witness_contract_2_th: this.state.witness_contract_2_th,
                                  witness_contract_2_en: this.state.witness_contract_2_en,
                                },
                                this.state.data_prefix_th,
                                this.state.data_prefix_en,
                                this.state.data_work_type,
                                this.state.data_business_type
                              );
                            }}
                          >
                            บต.46 + สัญญาจ้าง.pdf
                          </span>
                        </td>
                        <td className="text-left align-middle">
                          {item.af_bt_46_document && (
                            <a target="_blank" download href={item.af_bt_46_document && item.af_bt_46_document.includes("://") ? item.af_bt_46_document : "data:application/pdf;base64," + item.af_bt_46_document}>
                              บต.46 + สัญญาจ้าง.pdf
                            </a>
                          )}
                        </td>

                        <td className="text-center align-middle">
                          <span
                            className="text-primary pointer"
                            onClick={() => {
                              document_contact(
                                this.state.fw_data,
                                this.state.emp_data,
                                this.state.data_workplace,
                                this.state.data_deputize,
                                {
                                  witness_power_1: this.state.witness_power_1,
                                  witness_power_2_th: this.state.witness_power_2_th,
                                  witness_power_2_en: this.state.witness_power_2_en,
                                },
                                this.state.data_prefix_th,
                                this.state.data_prefix_en
                              );
                            }}
                          >
                            {" "}
                            ใบมอบอำนาจ.pdf
                          </span>
                        </td>
                        <td className="text-left align-middle">
                          {item.af_power_attorney_document && (
                            <a target="_blank" download href={item.af_power_attorney_document && item.af_power_attorney_document.includes("://") ? item.af_power_attorney_document : "data:application/pdf;base64," + item.af_power_attorney_document}>
                              ใบมอบอำนาจ.pdf
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <button
                className="btn btn-primary px-5 mx-auto"
                onClick={() => {
                  this.setState({ modal_download: false });
                }}
              >
                ปิดหน้าต่าง
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        {/* import file */}
        <Modal
          show={this.state.modal_import}
          onHide={() => {
            this.setState({ modal_import: false });
          }}
          size="md"
        >
          <Modal.Body className="p-2">
            <div className="bg-light card py-5 border-dashed align-items-center">
              <b className="icon text-primary mt-4" style={{ fontSize: 48 }}>
                {" "}
                {"\uf56f"}{" "}
              </b>
              <b className="text-secondary">เลือกไฟล์ที่นี่</b>
              <button
                className="btn btn-primary wpx-120 mb-4"
                onClick={() => {
                  document.getElementById("file").click();
                }}
              >
                เลือกไฟล์
              </button>
              <input
                className="d-none"
                type="file"
                name="file"
                id="file"
                accept=".xls,.xlsx"
                onChange={(e) => {
                  this.import_excel(e);
                }}
              />
            </div>
            {this.state.data_import && (
              <div className="card border-0 mb-3">
                <div className="p-3">
                  <label>จำนวนที่นำเข้า {this.state.data_import.length} รายการ</label>
                </div>
              </div>
            )}
            <div className="d-none">
              <ReactExcel initialData={this.state.import} onSheetUpdate={(currentSheet) => this.setState({ currentSheet: currentSheet })} activeSheetClassName="d-none" reactExcelClassName="table table-borderless table-striped" />
            </div>
            <div className="w-100 d-flex justify-content-end mt-3">
              <button
                className="btn btn-primary px-4 me-3"
                onClick={() => {
                  this.create_import();
                }}
              >
                นำเข้าข้อมูล
              </button>
              <button
                className="btn btn-outline-danger px-4"
                onClick={() => {
                  this.setState({ modal_import: false, import: null, currentSheet: null, over: false, data_import: null });
                }}
              >
                ปิดหน้าต่าง
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* สถานะงาน 14 แจ้งเข้าทำงานเปลี่ยนนายจ้าง */}
        <Modal
          show={this.state.modal_update_14}
          onHide={() => {
            this.setState({ modal_update_14: false });
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>
                {this.state.menu_data ? this.state.menu_data.menu_name : ""} {this.state.receive_code ? this.state.receive_code : ""}
              </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* tab ด้านบน */}
            <div className="card mb-3">
              {/* ข้อมูลนายจ้าง */}
              <div>
                <div
                  className={this.state.show_tab === 1 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                  onClick={() => {
                    this.setState({
                      show_tab: this.state.show_tab === 1 ? 0 : 1,
                    });
                  }}
                >
                  ข้อมูลนายจ้าง {this.state.emp_data ? this.state.emp_data.full_name_th : ""}
                </div>
                {this.state.show_tab === 1 && (
                  <div className="card-body">
                    {this.state.emp_data && (
                      <div>
                        <div
                          className="pointer bg-cyan-light rounded text-primary px-2 py-1 wpx-150 text-center mb-3"
                          onClick={() => {
                            window.location.href = "/Employer/update?id=" + this.state.emp_data.emp_id;
                          }}
                        >
                          แก้ไขข้อมูลนายจ้าง
                        </div>
                        <div className="row">
                          {/* เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก) */}
                          <div className="mb-3 col-6">
                            <b>เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก)</b>
                          </div>
                          <div className="mb-3 col-6">{this.state.emp_data.emp_number ? this.state.emp_data.emp_number : "-"}</div>
                          {/* English */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>English</b>
                            </div>
                            {this.state.emp_data.full_name_en ? this.state.emp_data.full_name_en : "-"}
                          </div>
                          {/* ประเภทนายจ้าง */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทนายจ้าง</b>
                            </div>
                            {this.state.emp_data.emp_type === 1 ? "บุคคลธรรมดา" : this.state.emp_data.emp_type === 2 ? "นิติบุคคล" : this.state.emp_data.emp_type === 3 ? "นิติบุคคลต่างด้าว" : ""}
                          </div>
                          {/* จดทะเบียนเมื่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>จดทะเบียนเมื่อ</b>
                            </div>
                            {this.state.emp_data.register_date ? format_date(this.state.emp_data.register_date) : "-"}
                          </div>
                          {/* ทุนจดทะเบียน (บาท) */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ทุนจดทะเบียน (บาท)</b>
                            </div>
                            {this.state.emp_data.register_capital ? toFixed(this.state.emp_data.register_capital) : "-"}
                          </div>
                          {/* ประเภทกิจการ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทกิจการ</b>
                            </div>
                            {this.state.emp_data.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.emp_data.business_type.toString())[0].label : "-"}
                          </div>
                          {/* ชื่อผู้ติดต่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ชื่อผู้ติดต่อ</b>
                            </div>
                            {this.state.emp_data.contact_name ? this.state.emp_data.contact_name : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax ? this.state.emp_data.fax : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์มือถือ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์มือถือ</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* E-mail Address */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>E-mail Address</b>
                            </div>
                            {this.state.emp_data.email ? this.state.emp_data.email : "-"}
                          </div>
                          {/* เลขรหัสประจำบ้าน ๑๑ หลัก */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>เลขรหัสประจำบ้าน ๑๑ หลัก</b>
                            </div>
                            {this.state.emp_data.home_number ? this.state.emp_data.home_number : "-"}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " หมู่ " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " ซอย " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " ถนน " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " ตำบล " + this.state.emp_data.sub_district : ""}
                            {this.state.emp_data.district ? " อำเภอ " + this.state.emp_data.district : ""}
                            {this.state.emp_data.province ? " จังหวัด " + this.state.emp_data.province : ""}
                            {this.state.emp_data.zipcode ? " รหัสไปรษณีย์ " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ (English) */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่ (English)</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " Moo " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " Soi " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " Street " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " , " + subdistrict.filter((e) => e.sub_district_name === this.state.emp_data.sub_district)[0]?.sub_district_name_en : ""}
                            {this.state.emp_data.district ? " , " + district.filter((e) => e.district_name === this.state.emp_data.district)[0]?.district_name_en : ""}
                            {this.state.emp_data.province ? " , " + province.filter((e) => e.province_name === this.state.emp_data.province)[0]?.province_name_en : ""}
                            {this.state.emp_data.zipcode ? " " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone_2 ? this.state.emp_data.phone_2 : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax_2 ? this.state.emp_data.fax_2 : "-"}
                          </div>
                        </div>
                        <div className="row">
                          {this.state.data_deputize &&
                            this.state.data_deputize.map((item, index) => (
                              <div className="row">
                                {/* ผู้มีอำนาจผูกพัน */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>{index + 1}. ผู้มีอำนาจผูกพัน</b>
                                  </div>
                                  {item.deputize_full_name ? item.deputize_full_name : "-"}
                                </div>
                                {/* ตำแหน่ง */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>ตำแหน่ง</b>
                                  </div>
                                  {item.deputize_position ? item.deputize_position : "-"}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {this.state.receive_status >= 142 && (
                <div>
                  <div
                    className={this.state.show_tab === 142 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 142 ? 0 : 142,
                      });
                    }}
                  >
                    {this.state.receive_status_date[142] ? format_date(this.state.receive_status_date[142], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[140]} บันทึกลูกจ้างสำเร็จ
                  </div>
                  {this.state.show_tab === 142 && (
                    <div className="card-body">
                      <table className="table table-borderless table-striped">
                        <thead className="bg-primary">
                          <tr>
                            <th className="text-center text-white">ลำดับ</th>
                            <th className="text-left text-white">ชื่อ-นามสกุล</th>
                            <th className="text-left text-white">เลขบัตรประจำตัว</th>
                            <th className="text-left text-white">สัญชาติ</th> 
                            <th className="text-left text-white">Passport</th>
                            <th className="text-left text-white">Visa</th>
                            <th className="text-left text-white">Work</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.fw_data &&
                            this.state.fw_data.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center align-middle">{index + 1}</td>
                                <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                                <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                                <td className="text-left align-middle">
                                  {item.nationality === 1 ? "เมียนมา" : ""}
                                  {item.nationality === 2 ? "ลาว" : ""}
                                  {item.nationality === 3 ? "กัมพูชา" : ""}
                                  {item.nationality === 4 ? "เวียดนาม" : ""}
                                </td>
                                <td className="text-left align-middle">
                                  {item.passport_image && (
                                    <div className="w-100 d-flex justify-content-start">
                                      <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                        passport.pdf
                                      </a>
                                    </div>
                                  )}
                                </td>
                                <td className="text-left align-middle">
                                  {item.visa_image && (
                                    <div className="w-100 d-flex justify-content-start">
                                      <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                        visa.pdf
                                      </a>
                                    </div>
                                  )}
                                </td>
                                <td className="text-left align-middle">
                                  {item.work_pm_image && (
                                    <div className="w-100 d-flex justify-content-start">
                                      <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                        Work.pdf
                                      </a>
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 144 && this.state.receive_status !== 145 && (
                <div>
                  <div
                    className={this.state.show_tab === 144 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 144 ? 0 : 144,
                      });
                    }}
                  >
                    {this.state.receive_status_date[144] ? format_date(this.state.receive_status_date[144], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[144]} ตรวจสอบเอกสาร
                  </div>
                  {this.state.show_tab === 144 && (
                    <div className="card-body">
                      <div className="d-flex">
                        <label className="my-auto wpx-150">เอกสารถูกต้อง</label>
                        <label className="my-auto">
                          วันที่ตรวจสอบ {this.state.receive_status_date[144] ? format_date(this.state.receive_status_date[144], "dd/mm/yyyy") : ""} ผู้ตรวจสอบ {this.state.receive_status_by[144]}
                        </label>
                        <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 146 && (
                <div>
                  <div
                    className={this.state.show_tab === 146 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 146 ? 0 : 146,
                      });
                    }}
                  >
                    {this.state.receive_status_date[146] ? format_date(this.state.receive_status_date[146], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[146]}
                    จัดส่งเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 146 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s9} ,{this.state.sending_document_date_s9 ? format_date(this.state.sending_document_date_s9, "dd/mm/yyyy") : ""} {this.state.receive_status_by[146]}
                          </label>
                          {this.state.documents_s9 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s9 && this.state.documents_s9.includes("://") ? this.state.documents_s9 : "data:application/pdf;base64," + this.state.documents_s9}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 144 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 147 && this.state.receive_status !== 148 && (
                <div>
                  <div
                    className={this.state.show_tab === 149 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 149 ? 0 : 149,
                      });
                    }}
                  >
                    {this.state.receive_status_date[149] ? format_date(this.state.receive_status_date[149], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[149]} ตรวจสอบ บต.53 + หนังสือมอบอำนาจช่วง
                  </div>
                  {this.state.show_tab === 149 && (
                    <div className="card-body">
                      <div className="d-flex">
                        <label className="my-auto wpx-150">เอกสารถูกต้อง</label>
                        <label className="my-auto">
                          วันที่ตรวจสอบ {this.state.receive_status_date[149] ? format_date(this.state.receive_status_date[149], "dd/mm/yyyy") : ""} ผู้ตรวจสอบ {this.state.receive_status_by[149]}
                        </label>
                        <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 147 && this.state.receive_status !== 149 && (
                <div>
                  <div
                    className={this.state.show_tab === 148 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 148 ? 0 : 148,
                      });
                    }}
                  >
                    {this.state.receive_status_date[147] ? format_date(this.state.receive_status_date[147], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[147]}
                    จัดส่งเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 148 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s16} ,{this.state.sending_document_date_s16 ? format_date(this.state.sending_document_date_s16, "dd/mm/yyyy") : ""} {this.state.receive_status_by[147]}
                          </label>
                          {this.state.documents_s16 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s16 && this.state.documents_s16.includes("://") ? this.state.documents_s16 : "data:application/pdf;base64," + this.state.documents_s16}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 149 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 147 && this.state.receive_status !== 148 && (
                <div>
                  <div
                    className={this.state.show_tab === 1410 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 1410 ? 0 : 1410,
                      });
                    }}
                  >
                    {this.state.receive_status_date[1410] ? format_date(this.state.receive_status_date[1410], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[1410]} ตรวจสอบ บต.53 + หนังสือมอบอำนาจช่วง
                  </div>
                  {this.state.show_tab === 1410 && (
                    <div className="card-body">
                      <div className="d-flex">
                        <label className="my-auto wpx-150">เอกสารถูกต้อง</label>
                        <label className="my-auto">
                          วันที่ตรวจสอบ {this.state.receive_status_date[1410] ? format_date(this.state.receive_status_date[1410], "dd/mm/yyyy") : ""} ผู้ตรวจสอบ {this.state.receive_status_by[1410]}
                        </label>
                        <label className="icon text-24 text-success ms-2">{"\uf058"}</label>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 1410 && (
                <div>
                  <div
                    className={this.state.show_tab === 1411 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 1411 ? 0 : 1411,
                      });
                    }}
                  >
                    {this.state.receive_status_date[1411] ? format_date(this.state.receive_status_date[1411], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[1411]}
                    แนบเอกสารแจ้งออก
                  </div>
                  {this.state.show_tab === 1411 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">แนบเอกสารแจ้งออก</b>
                          <br />
                          {this.state.report_out && (
                            <a className="mx-3" target="_blank" download href={this.state.report_out && this.state.report_out.includes("://") ? this.state.report_out : "data:application/pdf;base64," + this.state.report_out}>
                              เอกสารแจ้งออก.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 149 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 1411 && (
                <div>
                  <div
                    className={this.state.show_tab === 1411 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 1411 ? 0 : 1411,
                      });
                    }}
                  >
                    {this.state.receive_status_date[1411] ? format_date(this.state.receive_status_date[1411], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[1411]}
                    จัดส่งเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 1411 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s16} ,{this.state.sending_document_date_s16 ? format_date(this.state.sending_document_date_s16, "dd/mm/yyyy") : ""} {this.state.receive_status_by[1411]}
                          </label>
                          {this.state.documents_s16 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s16 && this.state.documents_s16.includes("://") ? this.state.documents_s16 : "data:application/pdf;base64," + this.state.documents_s16}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 149 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 1412 && (
                <div>
                  <div
                    className={this.state.show_tab === 1412 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                    onClick={() => {
                      this.setState({
                        show_tab: this.state.show_tab === 1412 ? 0 : 1412,
                      });
                    }}
                  >
                    {this.state.receive_status_date[1412] ? format_date(this.state.receive_status_date[1412], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[1412]}
                    จัดส่งเอกสารแล้ว
                  </div>
                  {this.state.show_tab === 1412 && (
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <b className="mb-3">การจัดส่งเอกสาร</b>
                          <br />
                          <label>
                            {this.state.deliver_documents_s19} ,{this.state.sending_document_date_s19 ? format_date(this.state.sending_document_date_s19, "dd/mm/yyyy") : ""} {this.state.receive_status_by[1411]}
                          </label>
                          {this.state.documents_s19 && (
                            <a className="mx-3" target="_blank" download href={this.state.documents_s19 && this.state.documents_s19.includes("://") ? this.state.documents_s19 : "data:application/pdf;base64," + this.state.documents_s19}>
                              เอกสารแนบ.pdf
                            </a>
                          )}
                        </div>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            this.setState({ loading: true });
                            setTimeout(() => {
                              this.setState({ loading: false, receive_status: 149 });
                            }, 2000);
                          }}
                        >
                          <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.receive_status >= 1413 && (
                <div className="p-3">
                  <h5>
                    <b>
                      {this.state.receive_status_date[1413] ? format_date(this.state.receive_status_date[1413], "dd/mm/yyyy hh:mm") : ""} {this.state.receive_status_by[1413]} ปิดงาน
                    </b>
                  </h5>
                  <label>{this.state.remark_s20}</label>
                </div>
              )}
            </div>
            {/* status 140 เปิดเรื่องแล้ว  */}
            {this.state.receive_status === 140 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">Passport</th>
                      <th className="text-left text-white">Visa</th>
                      <th className="text-left text-white">Work</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">
                            {!item.passport_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_passport_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                  passport.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].passport_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.visa_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_visa_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                  visa.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].visa_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.work_pm_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_work_pm_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                  Work.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].work_pm_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 141 บันทึกลูกจ้างสำเร็จ 1/2  */}
            {this.state.receive_status === 141 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">Passport</th>
                      <th className="text-left text-white">Visa</th>
                      <th className="text-left text-white">Work</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">
                            {!item.passport_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_passport_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                  passport.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].passport_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.visa_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_visa_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                  visa.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].visa_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {!item.work_pm_image ? (
                              <button
                                className="btn btn-primary mx-1 px-4"
                                accept="application/pdf"
                                onClick={() => {
                                  this.setState({ index: index });
                                  document.getElementById("file_work_pm_image").click();
                                }}
                              >
                                แนบไฟล์
                              </button>
                            ) : (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                  Work.pdf
                                </a>
                                <div
                                  className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-start align-items-center ms-2"
                                  onClick={() => {
                                    let fw_data = this.state.fw_data;
                                    fw_data[index].work_pm_image = "";
                                    this.setState({ fw_data: fw_data });
                                  }}
                                >
                                  <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 142 ตรวจสอบเอกสาร */}
            {this.state.receive_status === 142 && (
              <div>
                <h5 className="mb-3">
                  <b>นำเข้ารายชื่อลูกจ้าง</b>
                </h5>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="mb-0">
                    <b>บันทึกรายชื่อลูกจ้าง</b>
                  </h6>
                  <div
                    className="pointer bg-danger-light rounded text-primary px-2 py-1 wpx-140 mx-2"
                    onClick={() => {
                      document_contact(
                        this.state.fw_data,
                        this.state.emp_data,
                        this.state.data_workplace,
                        this.state.dpz_data,
                        {
                          witness_power_1: this.state.witness_power_1,
                          witness_power_2_th: this.state.witness_power_2_th,
                          witness_power_2_en: this.state.witness_power_2_en,
                        },
                        this.state.data_prefix_th,
                        this.state.data_prefix_en
                      );
                    }}
                  >
                    พิมพ์ ใบมอบอำนาจ
                  </div>
                </div>
                <div className="w-100 table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th> 
                      <th className="text-left text-white">Passport</th>
                      <th className="text-left text-white">Visa</th>
                      <th className="text-left text-white">Work</th>
                      <th className="text-left text-white">เอกสาร</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">
                            {item.passport_image && (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.passport_image && item.passport_image.includes("://") ? item.passport_image : "data:application/pdf;base64," + item.passport_image}>
                                  passport.pdf
                                </a>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.visa_image && (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.visa_image && item.visa_image.includes("://") ? item.visa_image : "data:application/pdf;base64," + item.visa_image}>
                                  visa.pdf
                                </a>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            {item.work_pm_image && (
                              <div className="w-100 d-flex justify-content-start">
                                <a target="_blank" download href={item.work_pm_image && item.work_pm_image.includes("://") ? item.work_pm_image : "data:application/pdf;base64," + item.work_pm_image}>
                                  Work.pdf
                                </a>
                              </div>
                            )}
                          </td>
                          <td className="text-left align-middle">
                            <td className="text-left text-white">
                              <div className="d-flex">
                                <div className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1" onClick={() => {  }}>
                                  บต. 44
                                </div>
                                <div className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1" onClick={() => {  }}>
                                  บต. 52
                                </div>
                                <div className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1" onClick={() => {  }}>
                                  บต. 55
                                </div>
                                <div className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1" onClick={() => {  }}>
                                  บต. 46
                                </div>
                                <div className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1" onClick={() => {  }}>
                                  บต. 56 (กทม).
                                </div>
                                <div className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1" onClick={() => {  }}>
                                  บต. 56 (ตจว).
                                </div>
                                <div className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1" onClick={() => {  }}>
                                  สัญญาจ้าง
                                </div>
                                
                              </div>
                            </td>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                </div>
              </div>
            )}
            {/* status 144 เอกสารถูกต้อง */}
            {this.state.receive_status === 144 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>ส่งเอกสารให้นายจ้าง</b>
                  </h6>
                </div>
                <div className="card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">จัดส่งเอกสาร{required}</label>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "Line" });
                      }}
                      checked={this.state.deliver_documents_s9 === "Line"}
                    />
                    Line
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "Messenger" });
                      }}
                      checked={this.state.deliver_documents_s9 === "Messenger"}
                    />
                    Messenger
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s9: "ไปรษณีย์" });
                      }}
                      checked={this.state.deliver_documents_s9 === "ไปรษณีย์"}
                    />
                    ไปรษณีย์
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s9"
                      onChange={(e) => {
                        this.setState({
                          deliver_documents_s9: "มารับด้วยตัวเอง",
                        });
                      }}
                      checked={this.state.deliver_documents_s9 === "มารับด้วยตัวเอง"}
                    />
                    มารับด้วยตัวเอง
                  </div>
                  {this.state.deliver_documents_s9 === "Messenger" && (
                    <div className="d-flex align-items-center mb-3">
                      <label className="wpx-150">ผู้ส่งเอกสาร{required}</label>
                      <select
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ sending_messenger_s9: e.target.value });
                        }}
                        value={this.state.sending_messenger_s9}
                      >
                        <option value=""></option>
                        {this.state.data_user.map((item, index) => (
                          <option value={item.user_id}>{item.full_name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">วันที่ส่งเอกสาร{required}</label>
                    <DatePicker
                      className="form-control mt-1"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ sending_document_date_s9: e });
                      }}
                      value={this.state.sending_document_date_s9}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">เอกสารแนบ</label>
                    {!this.state.documents_s9 ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          document.getElementById("file_documents_s9").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.documents_s9 && this.state.documents_s9.includes("://") ? this.state.documents_s9 : "data:application/pdf;base64," + this.state.documents_s9}>
                          เอกสารแนบ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            this.setState({ documents_s9: "" });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="wpx-150">หมายเหตุ{required}</label>
                    <br />
                    <textarea
                      className="form-control mt-1"
                      placeholder="หมายเหตุ..."
                      onChange={(e) => {
                        this.setState({ remark_s9: e.target.value });
                      }}
                      value={this.state.remark_s9}
                      rows={5}
                    />
                  </div>
                  <div>{this.state.installment && this.state.installment.length !== 0 && this.state.installment.filter((e) => e.paid).length === this.state.installment.length ? <span className="fw-bold text-16 px-3 py-1 border border-success text-success rounded-3 mb-3">ชำระครบแล้ว</span> : <span className="fw-bold text-16 px-3 py-1 border border-danger text-danger rounded-3 mb-3">ค้างชำระ</span>}</div>
                </div>
              </div>
            )}
            {/* status 145 เอกสาร บต.53+หนังสือมอบอำนาจ ไม่ถูกต้อง */}
            {this.state.receive_status === 145 && (
              <div>
                <h5 className="mb-3">
                  <b>ผลการตรวจสอบเอกสาร บต. 53+หนังสือมอบอำนาจ</b>
                </h5>
                <label className="text-danger mb-2">ผลการตรวจสอบเอกสาร บต. 53+หนังสือมอบอำนาจ ไม่ถูกต้อง</label>
              </div>
            )}
            {/* status 148 เอกสาร บต.53+หนังสือมอบอำนาจช่วง ไม่ถูกต้อง */}
            {this.state.receive_status === 148 && (
              <div>
                <h5 className="mb-3">
                  <b>ผลการตรวจสอบเอกสาร บต. 53+หนังสือมอบอำนาจช่วง</b>
                </h5>
                <label className="text-danger mb-2">ผลการตรวจสอบเอกสาร บต. 53+หนังสือมอบอำนาจช่วง ไม่ถูกต้อง</label>
              </div>
            )}
            {/* status 1410 ส่งเอกสาร บต.53+หนังสือมอบอำนาจช่วง แล้ว */}
            {this.state.receive_status === 1410 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>แนบเอกสารแจ้งออก</b>
                  </h6>
                </div>
                <div className="d-flex mb-3">
                  <div className="wpx-200">แนบเอกสารแจ้งออก</div>
                  {!this.state.report_out ? (
                    <button
                      className="btn btn-primary mx-1 px-4"
                      accept="application/pdf"
                      onClick={() => {
                        this.setState({ index: 0 });
                        document.getElementById("file_report_out").click();
                      }}
                    >
                      แนบไฟล์
                    </button>
                  ) : (
                    <div className="w-100 d-flex justify-content-start">
                      <a target="_blank" download href={this.state.report_out && this.state.report_out.includes("://") ? this.state.report_out : "data:application/pdf;base64," + this.state.report_out}>
                        เอกสารแจ้งออก.pdf
                      </a>
                      <div
                        className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                        onClick={() => {
                          this.setState({ report_out: "" });
                        }}
                      >
                        <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* status 1411 แนบไฟล์ใบแจ้งออกแล้ว  */}
            {this.state.receive_status === 1411 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>ส่งเอกสารให้นายจ้าง</b>
                  </h6>
                </div>
                <div className="card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">จัดส่งเอกสาร{required}</label>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "Line" });
                      }}
                      checked={this.state.deliver_documents_s19 === "Line"}
                    />
                    Line
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "Messenger" });
                      }}
                      checked={this.state.deliver_documents_s19 === "Messenger"}
                    />
                    Messenger
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s19: "ไปรษณีย์" });
                      }}
                      checked={this.state.deliver_documents_s19 === "ไปรษณีย์"}
                    />
                    ไปรษณีย์
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s19"
                      onChange={(e) => {
                        this.setState({
                          deliver_documents_s19: "มารับด้วยตัวเอง",
                        });
                      }}
                      checked={this.state.deliver_documents_s19 === "มารับด้วยตัวเอง"}
                    />
                    มารับด้วยตัวเอง
                  </div>
                  {this.state.deliver_documents_s19 === "Messenger" && (
                    <div className="d-flex align-items-center mb-3">
                      <label className="wpx-150">ผู้ส่งเอกสาร{required}</label>
                      <select
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ sending_messenger_s19: e.target.value });
                        }}
                        value={this.state.sending_messenger_s19}
                      >
                        <option value=""></option>
                        {this.state.data_user.map((item, index) => (
                          <option value={item.user_id}>{item.full_name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">วันที่ส่งเอกสาร{required}</label>
                    <DatePicker
                      className="form-control mt-1"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ sending_document_date_s19: e });
                      }}
                      value={this.state.sending_document_date_s19}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">เอกสารแนบ</label>
                    {!this.state.documents_s19 ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          document.getElementById("file_documents_s19").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.documents_s19 && this.state.documents_s19.includes("://") ? this.state.documents_s19 : "data:application/pdf;base64," + this.state.documents_s19}>
                          เอกสารแนบ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            this.setState({ documents_s19: "" });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="wpx-150">หมายเหตุ{required}</label>
                    <br />
                    <textarea
                      className="form-control mt-1"
                      placeholder="หมายเหตุ..."
                      onChange={(e) => {
                        this.setState({ remark_s19: e.target.value });
                      }}
                      value={this.state.remark_s19}
                      rows={5}
                    />
                  </div>
                  <div>{this.state.installment && this.state.installment.length !== 0 && this.state.installment.filter((e) => e.paid).length === this.state.installment.length ? <span className="fw-bold text-16 px-3 py-1 border border-success text-success rounded-3 mb-3">ชำระครบแล้ว</span> : <span className="fw-bold text-16 px-3 py-1 border border-danger text-danger rounded-3 mb-3">ค้างชำระ</span>}</div>
                </div>
              </div>
            )}
            {/* status 1412 จัดส่งเอกสารแล้ว */}
            {this.state.receive_status === 1412 && (
              <div>
                <div className="d-flex justify-content-between align-items-center ps-4 mt-3">
                  <input
                    className="mt-1 me-3"
                    type="checkbox"
                    name="check_close"
                    onChange={(e) => {
                      this.setState({ check_close: !this.state.check_close });
                    }}
                    checked={this.state.check_close}
                  />
                  <span className="wpx-100">ปิดงาน</span>
                  <input
                    className="form-control w-100"
                    type="text"
                    placeholder="หมายเหตุ ...."
                    onChange={(e) => {
                      this.setState({ remark_s20: e.target.value });
                    }}
                    value={this.state.remark_s20}
                  />
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            {[140, 141, 142, 144].includes(this.state.receive_status) ? (
              <div className="w-100 d-flex">
                <div className="w-50 pe-1">
                  <button
                    className="btn btn-outline-primary w-100"
                    onClick={() => {
                      this.setState({ modal_update_14: false });
                    }}
                  >
                    ยกเลิก
                  </button>
                </div>
                <div className="w-50 ps-1">
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      this.Update_14();
                    }}
                  >
                    บันทึก
                  </button>
                </div>
              </div>
            ) : (
              <div className="w-100 d-flex">
                <div className="mx-auto pe-1">
                  <button
                    className="btn btn-outline-primary wpx-100"
                    onClick={() => {
                      this.setState({ modal_update_14: false });
                    }}
                  >
                    ปิด
                  </button>
                </div>
              </div>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
