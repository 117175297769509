import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import LOGO from "../../assets/images/logo.png";
import Swal from "sweetalert2";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
  document_bt46,
  document_contact,
  days_between,
  convert_imagetopdf,
} from "../../components/CustomComponent.js";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class ListRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_match2: false,
      modal_match4: false,
      modal_match7: false,
      modal_match_6: false,
      modal_match_7: false,
      modal_match_8: false,
      modal_match_11: false,
      modal_match_13: false,
      modal_match_14: false,
      loading: false,
      sender: false,
      data: [],
      data_workplace: [],
      data_user: [],

      data_search: "",

      checklist_data: [],
      fw_data: [],
      receive_id: "",
      receive_code: "",
      receive_date: null,
      branch: "",
      immigration_office: "",
      filing_type: "",
      billing_type: "",
      broker: "",
      emp_number: "",
      process_matter: "",
      cambodia_qty: 0,
      laos_qty: 0,
      myanmar_qty: 0,
      vietnam_qty: 0,
      total_qty: 0,
      remark: "",
      buy_health_insurance: null,
      type_health_insurance: "",
      buy_disease_service: null,
      type_disease_service: "",
      c_certificate: null,
      c_certificate_20: null,
      c_copy_committee: null,
      receive_status: 0,
      receive_date_s1: null,
      receive_by_s1: null,
      receive_date_s2: null,
      receive_by_s2: null,
      receive_date_s3: null,
      receive_by_s3: null,
      receive_date_s4: null,
      receive_by_s4: null,
      receive_date_s5: null,
      receive_by_s5: null,
      receive_date_s6: null,
      receive_by_s6: null,
      receive_date_s7: null,
      receive_by_s7: null,
      receive_date_s8: null,
      receive_by_s8: null,
      receive_date_s9: null,
      receive_by_s9: null,
      receive_date_s10: null,
      receive_by_s10: null,
      receive_date_s11: null,
      receive_by_s11: null,
      receive_date_s12: null,
      receive_by_s12: null,
      receive_date_s13: null,
      receive_by_s13: null,
      receive_date_s14: null,
      receive_by_s14: null,
      receive_date_s15: null,
      receive_by_s15: null,
      receive_date_s16: null,
      receive_by_s16: null,
      receive_date_s17: null,
      receive_by_s17: null,
      receive_date_s18: null,
      receive_by_s18: null,
      receive_date_s19: null,
      receive_by_s19: null,
      receive_date_s20: null,
      receive_by_s20: null,
      receive_date_s21: null,
      receive_by_s21: null,
      is_active: true,
      create_by: "",
      emp_data: null,
      menu_data: null,
      dpz_data: [],

      r_username: "",
      r_password: "",

      page: 1,
      end_index: 0,
      count: 0,
      page_number: 0,

      show_tab: 0,

      data_business_type: [],

      data_salary: [],

      // พยาน
      data_admin: [],
      witness_power_1: "",
      witness_power_2_th: "",
      witness_power_2_en: "",
      witness_contract_1: "",
      witness_contract_2_th: "",
      witness_contract_2_en: "",

      payment_person: "",

      data_prefix_th: [],
      data_prefix_en: [],

      data_work_type: [],
      status_documents_employer_receipt: "",
      remark_document_employer_receipt: "",

      house_number: "",
      due_time: "",
      due_province: "",
      due_district: "",
      bt_52_document_before: "",
      bt_52_document_after: "",
      bt_53_document_before: "",
      bt_53_status_before: null,
      bt_53_remark_before: "",
      bt_53_document_after: "",
      bt_55_document: "",
      power_attorney_document: "",
      power_attorney_status: null,
      power_attorney_remark: "",
      calling_visa_document: "",
      consent_document: "",
      house_registration_document: "",

      deliver_documents_s9: "",
      sending_document_date_s9: "",
      sending_messenger_s9: "",
      remark_s9: "",
      documents_s9: "",

      deliver_documents_s16: "",
      sending_document_date_s16: "",
      sending_messenger_s16: "",
      remark_s16: "",
      documents_s16: "",

      deliver_documents_s19: "",
      sending_document_date_s19: "",
      sending_messenger_s19: "",
      remark_s19: "",
      documents_s19: "",
      remark_s20: "",

      documents_employer_s22: "",
      documents_power_s22: "",
      documents_sub_power_s22: "",
      documents_scan_name: "",
      documents_employer_receipt: "",
      documents_employer_receipt_after: "",
      report_out: "",

      receive_status_date: {},
      receive_status_by: {},

      post_box_no: [
        { document_name: "สำเนาเอกสารนายจ้าง", no: "", remark: "" },
        { document_name: "สำเนาเอกสารลูกจ้าง", no: "", remark: "" },
        { document_name: "เอกสารการเงิน", no: "", remark: "" },
      ],
    };
  }
  async componentDidMount() {
    this.setState({ loading: true });
    await this.GetSalary();
    await this.GetBusinessType();
    await this.GetWorkType();
    await this.GetAdmin();
    await this.GetPrefix();
    await this.Get();
    this.setState({ loading: false });
  }
  GetBusinessType = async () => {
    let body = {
      data_search: "",
      business_type_id: "",
    };
    let result = await GET(tokens, "api/setting/business-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_business_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.business_type_th };
        }),
      });
    }
  };
  GetWorkType = async () => {
    let body = {
      data_search: "",
      work_type_id: "",
    };
    let result = await GET(tokens, "api/setting/work-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_work_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.work_type_th, en: e.work_type_en };
        }),
      });
    }
  };
  GetPrefix = async () => {
    let body = {
      data_search: "",
      prefix_id: "",
    };
    let result = await GET(tokens, "api/setting/prefix/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_prefix_th: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_th };
        }),
        data_prefix_en: result.data.map((e) => {
          return { value: e.id.toString(), label: e.prefix_name_en };
        }),
      });
    }
  };
  GetAdmin = async () => {
    let body = {
      data_search: "",
      user_id: "",
    };
    let result = await GET(tokens, "api/user/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_user: result.data.filter((e) => e.department === "แมสเซนเจอร์"),
        data_admin: result.data.map((e) => {
          return {
            value: e.user_id,
            label: e.full_name,
            ...e,
          };
        }),
      });
    }
  };
  Get = async () => {
    let body = {
      data_search: this.state.data_search,
      receive_id: "",
      menu: "RECIEVE",
      menu_id: 0,
    };
    let result = await GET(tokens, "api/receive-matter/list/page/" + this.state.page, body);
    if (result && result.status) {
      this.setState({
        data: result.data,
        end_index: result.count < result.end_index ? result.count : result.end_index,
        count: result.count,
        page_number: result.page_number,
      });
    }
  };
  GetWorkplace = async (id) => {
    let body = {
      data_search: "",
      pw_id: "",
      emp_id: id,
    };
    let result = await GET(tokens, "api/place-work/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_workplace: result.data.map((e) => {
          return {
            value: e.id,
            label: e.address_number + (e.moo ? " หมู่ " + e.moo : "") + (e.soi ? " ซอย " + e.soi : "") + (e.street ? " ถนน " + e.street : "") + " ตำบล " + e.sub_district + " อำเภอ " + e.district + " จังหวัด " + e.province + " รหัสไปรษณีย์ " + e.zipcode,
          };
        }),
      });
    }
  };
  GetSalary = async () => {
    let body = {
      data_search: "",
      minimum_wage_id: "",
    };
    let result = await GET(tokens, "api/minimum-wage/list", body);
    if (result && result.status) {
      this.setState({
        data_salary: result.data,
      });
    }
  };
  Status = (item) => {
    let component = null;
    switch (item.receive_status) {
      case 1:
        component = <label>รอ Matching</label>;
        break;
      case 2:
        component = <label>มอบอำนาจ Matching</label>;
        break;
      case 3:
        component = <label>ใช้ User ของนายจ้าง</label>;
        break;
      case 4:
        component = <label>Machting ไม่ได้</label>;
        break;
      case 5:
        component = <label>Machting สำเร็จ</label>;
        break;
      case 6:
        component = <label>บันทึกลูกจ้างสำเร็จ</label>;
        break;
      case 7:
        component = <label>{item.menu_data.id === 7 ? "รอตรวจสอบเอกสาร" : item.menu_data.id === 8 ? "แนบเอกสารแล้ว รอตรวจสอบ" : "รอตรวจสอบ บต.46+มอบอำนาจ"}</label>;
        break;
      case 8:
        component = <label>เอกสารไม่ถูกต้อง {item.menu_data.id === 7 ? "ตม.๒๘ + ตม.๓๐" : "บต.46+มอบอำนาจ"}</label>;
        break;
      case 9:
        component = <label>เอกสาร {item.menu_data.id === 7 ? "ตม.๒๘ + ตม.๓๐" : "บต.46+มอบอำนาจ"} ถูกต้อง</label>;
        break;
      case 10:
        component = <label>ส่งเอกสาร {item.menu_data.id === 7 ? "ตม.๒๘ + ตม.๓๐" : "บต.46+มอบอำนาจ"} แล้ว</label>;
        break;
      case 11:
        component = item.fw_data && item.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length !== 0 ? <label>เอกสารไม่ถูกต้อง {item.menu_data.id === 7 ? "ตม.๒๘ + ตม.๓๐" : "บต.46+มอบอำนาจ"} ครั้งสุดท้าย</label> : <label>รอตรวจสอบ {item.menu_data.id === 7 ? "ตม.๒๘ + ตม.๓๐" : "บต.46+มอบอำนาจ"} ครั้งสุดท้าย</label>;
        break;
      case 12:
        component = <label>เอกสารถูกต้อง พร้อมยื่น</label>;
        break;
      case 13:
        component = <label>ได้เลข WP</label>;
        break;
      case 14:
        component = <label>ได้เลข WP แล้ว รออนุมัติ</label>;
        break;
      case 15:
        component = <label>แนบใบเสร็จรับเงิน + ทะเบียนใบอนุญาตทำงาน</label>;
        break;
      case 16:
        component = <label>จัดส่งเอกสารใบเสร็จ</label>;
        break;
      case 17:
        component = <label>ส่งใบเสร็จให้ลูกค้าแล้ว รอออก eWork</label>;
        break;
      case 18:
        component = <label>ออก eWork แล้ว</label>;
        break;
      case 19:
        component = <label>รับ eWork แล้ว รอจัดส่ง</label>;
        break;
      case 20:
        component = <label>ส่ง eWork แล้ว</label>;
        break;
      case 21:
        component = <label>ปิดงาน</label>;
        break;
      case 22:
        component = <label>เปิดเรื่องแล้ว</label>;
        break;
      case 63:
        component = <label>รอตรวจสอบเอกสาร</label>;
        break;
      case 64:
        component = <label>จัดส่งเอกสารใบเสร็จ</label>;
        break;
      case 65:
        component = <label>เอกสารไม่ถูกต้อง</label>;
        break;
      case 73:
        component = <label>รอตรวจสอบเอกสาร</label>;
        break;
      case 75:
        component = <label>เอกสาร ตม.28+ตม.30+หนังสือมอบอำนาจ ไม่ถูกต้อง</label>;
        break;
      case 76:
        component = <label>รอตรวจสอบเอกสาร</label>;
        break;
      case 77:
        component = <label>รอตรวจสอบเอกสาร</label>;
        break;
      case 78:
        component = <label>เอกสาร ตม.28+ตม.30+หนังสือมอบอำนาจช่วง ไม่ถูกต้อง</label>;
        break;
      case 79:
        component = <label>จัดส่งเอกสารใบเสร็จ</label>;
        break;
        case 83:
          component = <label>รอตรวจสอบเอกสาร</label>;
          break;
          case 84:
            component = <label>จัดส่งเอกสารใบเสร็จ</label>;
            break;
        case 85:
          component = <label>เอกสาร ย้ายตรา visa ไม่ถูกต้อง</label>;
          break;
          case 86:
          component = <label>รอตรวจสอบเอกสาร</label>;
          break;
        case 87:
          component = <label>รอตรวจสอบเอกสาร</label>;
          break;
        case 88:
          component = <label>เอกสาร ย้ายตรา visa ไม่ถูกต้อง</label>;
          break;
        case 89:
          component = <label>จัดส่งเอกสารใบเสร็จ</label>;
          break;
      case 111:
        component = <label>รอกรอกเลขตู้</label>;
        break;
      case 112:
        component = <label>รอตรวจสอบเอกสาร</label>;
        break;
      case 113:
        component = <label>เอกสารไม่ถูกต้อง</label>;
        break;
      case 114:
        component = <label>จัดส่งเอกสาร</label>;
        break;
      case 133:
        component = <label>รอตรวจสอบเอกสาร</label>;
        break;
      case 135:
        component = <label>เอกสาร บต.53+หนังสือมอบอำนาจ ไม่ถูกต้อง</label>;
        break;
      case 136:
        component = <label>รอตรวจสอบเอกสาร</label>;
        break;
      case 137:
        component = <label>รอตรวจสอบเอกสาร</label>;
        break;
      case 138:
        component = <label>เอกสาร บต.53+หนังสือมอบอำนาจช่วง ไม่ถูกต้อง</label>;
        break;
      case 139:
        component = <label>จัดส่งเอกสารใบเสร็จ</label>;
        break;
      case 143:
        component = <label>รอตรวจสอบเอกสาร</label>;
        break;
        case 145:
        component = <label>เอกสารไม่ถูกต้อง</label>;
        break;
      case 146:
        component = <label>รอตรวจสอบเอกสาร</label>;
        break;
      case 147:
        component = <label>ส่งเอกสารแล้ว</label>;
        break;
      case 148:
        component = <label>เอกสารไม่ถูกต้อง</label>;
        break;
      case 149:
        component = <label>จัดส่งเอกสาร</label>;
        break;
      default:
        break;
    }
    return component;
  };
  getById = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      receive_id: id,
      menu: "",
    };
    let result = await GET(tokens, "api/receive-matter/list/page/0", body);
    if (result && result.status) {
      console.log("result.data:", result.data);
      console.log("result.data.receive_status:", result.data.receive_status);
      let fw_data =
        (result.data.receive_status === 76 || result.data.receive_status === 77|| result.data.receive_status === 86 || result.data.receive_status === 87 || result.data.receive_status === 11 || result.data.receive_status === 136 || result.data.receive_status === 137|| result.data.receive_status === 146 || result.data.receive_status === 147) && result.data.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === result.data.fw_data.length
          ? result.data.fw_data.map((e) => {
              return { ...e, status_document: null };
            })
          : result.data.fw_data;
      this.setState({
        checklist_data: result.data.checklist_data,
        fw_data: fw_data,
        receive_id: result.data.receive_id,
        payment_person: result.data.payment_person,
        receive_code: result.data.receive_code,
        receive_date: result.data.receive_date,
        branch: result.data.branch,
        immigration_office: result.data.immigration_office,
        filing_type: result.data.filing_type,
        billing_type: result.data.billing_type,
        broker: result.data.broker,
        emp_number: result.data.emp_number,
        process_matter: result.data.process_matter,
        cambodia_qty: result.data.cambodia_qty,
        laos_qty: result.data.laos_qty,
        myanmar_qty: result.data.myanmar_qty,
        vietnam_qty: result.data.vietnam_qty,
        total_qty: result.data.total_qty,
        remark: result.data.remark,
        buy_health_insurance: result.data.buy_health_insurance,
        type_health_insurance: result.data.type_health_insurance,
        buy_disease_service: result.data.buy_disease_service,
        type_disease_service: result.data.type_disease_service,
        c_certificate: result.data.c_certificate,
        c_certificate_20: result.data.c_certificate_20,
        c_copy_committee: result.data.c_copy_committee,
        receive_status: result.data.receive_status,
        receive_date_s1: result.data.receive_date_s1,
        receive_by_s1: result.data.receive_by_s1,
        receive_date_s2: result.data.receive_date_s2,
        receive_by_s2: result.data.receive_by_s2,
        receive_date_s3: result.data.receive_date_s3,
        receive_by_s3: result.data.receive_by_s3,
        receive_date_s4: result.data.receive_date_s4,
        receive_by_s4: result.data.receive_by_s4,
        receive_date_s5: result.data.receive_date_s5,
        receive_by_s5: result.data.receive_by_s5,
        receive_date_s6: result.data.receive_date_s6,
        receive_by_s6: result.data.receive_by_s6,
        receive_date_s7: result.data.receive_date_s7,
        receive_by_s7: result.data.receive_by_s7,
        receive_date_s8: result.data.receive_date_s8,
        receive_by_s8: result.data.receive_by_s8,
        receive_date_s9: result.data.receive_date_s9,
        receive_by_s9: result.data.receive_by_s9,
        receive_date_s10: result.data.receive_date_s10,
        receive_by_s10: result.data.receive_by_s10,
        receive_date_s11: result.data.receive_date_s11,
        receive_by_s11: result.data.receive_by_s11,
        receive_date_s12: result.data.receive_date_s12,
        receive_by_s12: result.data.receive_by_s12,
        receive_date_s13: result.data.receive_date_s13,
        receive_by_s13: result.data.receive_by_s13,
        receive_date_s14: result.data.receive_date_s14,
        receive_by_s14: result.data.receive_by_s14,
        receive_date_s15: result.data.receive_date_s15,
        receive_by_s15: result.data.receive_by_s15,
        receive_date_s16: result.data.receive_date_s16,
        receive_by_s16: result.data.receive_by_s16,
        receive_date_s17: result.data.receive_date_s17,
        receive_by_s17: result.data.receive_by_s17,
        receive_date_s18: result.data.receive_date_s18,
        receive_by_s18: result.data.receive_by_s18,
        receive_date_s19: result.data.receive_date_s19,
        receive_by_s19: result.data.receive_by_s19,
        receive_date_s20: result.data.receive_date_s20,
        receive_by_s20: result.data.receive_by_s20,
        receive_date_s21: result.data.receive_date_s21,
        receive_by_s21: result.data.receive_by_s21,
        is_active: result.data.is_active,
        create_by: result.data.create_by,
        emp_data: result.data.emp_data,
        menu_data: result.data.menu_data,
        dpz_data: result.data.dpz_data ? result.data.dpz_data : [],
        sender: ((result.data.menu_data.id === 7 && result.data.receive_status === 11) || (result.data.menu_data.id === 13 && result.data.receive_status === 136)) && fw_data.filter((e) => e.status_document === "ถูกต้อง").length === fw_data.length,

        // พยาน
        witness_power_1: result.data.witness_power_1 ? this.state.data_admin.filter((e) => e.value === result.data.witness_power_1)[0] : "",
        witness_power_2_th: result.data.witness_power_2_th ? this.state.data_admin.filter((e) => e.value === result.data.witness_power_2_th)[0] : "",
        witness_power_2_en: result.data.witness_power_2_en,
        witness_contract_1: result.data.witness_contract_1 ? this.state.data_admin.filter((e) => e.value === result.data.witness_contract_1)[0] : "",
        witness_contract_2_th: result.data.witness_contract_2_th,
        witness_contract_2_en: result.data.witness_contract_2_en,

        status_documents_employer_receipt: result.data.status_documents_employer_receipt,
        remark_document_employer_receipt: result.data.remark_document_employer_receipt,

        house_number: result.data.house_number,
        due_time: result.data.due_time,
        due_province: result.data.due_province,
        due_district: result.data.due_district,
        bt_52_document_before: result.data.bt_52_document_before,
        bt_52_document_after: result.data.bt_52_document_after,
        bt_53_document_before: result.data.bt_53_document_before,
        bt_53_status_before: result.data.bt_53_status_before,
        bt_53_remark_before: result.data.bt_53_remark_before,
        bt_53_document_after: result.data.bt_53_document_after,
        bt_55_document: result.data.bt_55_document,
        power_attorney_document: result.data.power_attorney_document,
        power_attorney_status: result.data.power_attorney_status,
        power_attorney_remark: result.data.power_attorney_remark,
        calling_visa_document: result.data.calling_visa_document,
        consent_document: result.data.consent_document,
        house_registration_document: result.data.house_registration_document,

        deliver_documents_s9: result.data.deliver_documents_s9,
        sending_document_date_s9: result.data.sending_document_date_s9,
        sending_messenger_s9: result.data.sending_messenger_s9,
        remark_s9: result.data.remark_s9,
        documents_s9: result.data.documents_s9,

        deliver_documents_s16: result.data.menu_data.id === 7 && result.data.receive_status === 11 ? "Messenger" : result.data.deliver_documents_s16,
        sending_document_date_s16: result.data.sending_document_date_s16,
        sending_messenger_s16: result.data.sending_messenger_s16,
        remark_s16: result.data.remark_s16,
        documents_s16: result.data.documents_s16,

        deliver_documents_s19: result.data.deliver_documents_s19,
        sending_document_date_s19: result.data.sending_document_date_s19,
        sending_messenger_s19: result.data.sending_messenger_s19,
        remark_s19: result.data.remark_s19,
        documents_s19: result.data.documents_s19,
        remark_s20: result.data.remark_s20,

        documents_employer_s22: result.data.documents_employer_s22,
        documents_power_s22: result.data.documents_power_s22,
        documents_sub_power_s22: result.data.documents_sub_power_s22,
        documents_scan_name: result.data.documents_scan_name,
        documents_employer_receipt: result.data.documents_employer_receipt,
        documents_employer_receipt_after: result.data.documents_employer_receipt_after,
        receive_status_date: result.data.receive_status_date ? JSON.parse(result.data.receive_status_date) : {},
        receive_status_by: result.data.receive_status_by ? JSON.parse(result.data.receive_status_by) : {},

        report_out: result.data.report_out,

        post_box_no: result.data.post_box_no
          ? JSON.parse(result.data.post_box_no)
          : [
              { document_name: "สำเนาเอกสารนายจ้าง", no: "", remark: "" },
              { document_name: "สำเนาเอกสารลูกจ้าง", no: "", remark: "" },
              { document_name: "เอกสารการเงิน", no: "", remark: "" },
            ],
      });
      if (result.data.emp_data) {
        await this.GetWorkplace(result.data.emp_data.emp_id);
      }
    }
    this.setState({ loading: false });
  };
  Update = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if ((receive_status === 3 || receive_status === 5) && this.state.fw_data.filter((e) => e.status_complete_info).length === this.state.fw_data.length) {
      receive_status = 7;
    }
    if (this.state.menu_data.id === 8) {
      if (receive_status === 7 && this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
        receive_status = 24;
      } else if (receive_status === 7 && this.state.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length >= 1) {
        receive_status = 8;
      }
      if (receive_status === 8 && this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
        receive_status = 24;
      }
    } else if (this.state.menu_data.id === 11) {
      if (receive_status === 7 && this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
        receive_status = 26;
      } else if (receive_status === 7 && this.state.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length >= 1) {
        receive_status = 8;
      }
      if (receive_status === 8 && this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
        receive_status = 26;
      }
    } else {
      if (receive_status === 7 && this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
        receive_status = 9;
      } else if (receive_status === 7 && this.state.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length >= 1) {
        receive_status = 8;
      }
      if (receive_status === 8 && this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
        receive_status = 9;
      }
    }
    if (receive_status === 11 && this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
      if (this.state.menu_data.id === 7) {
        if (this.state.sender) {
          if (!this.state.deliver_documents_s16) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
            this.setState({ loading: false });
            return;
          }
          if (this.state.deliver_documents_s16 === "Messenger" && !this.state.sending_messenger_s16) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
            this.setState({ loading: false });
            return;
          }
          if (!this.state.sending_document_date_s16) {
            alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
            this.setState({ loading: false });
            return;
          }
          if (!this.state.remark_s16) {
            alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
            this.setState({ loading: false });
            return;
          }
          receive_status = 18;
        }
      } else {
        receive_status = 12;
      }
    }
    let body = {
      receive_status: receive_status,

      r_username: this.state.r_username,
      r_password: this.state.r_password,

      fw_data: fw_data,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/ApproveRecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_6 = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if (receive_status === 63 && this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
      receive_status = 64;
    } else if (receive_status === 63 && this.state.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length >= 1) {
      receive_status = 65;
    } else if (receive_status === 65 && this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
      receive_status = 64;
    } else if (this.state.receive_status === 64) {
      if (!this.state.deliver_documents_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s9 === "Messenger" && !this.state.sending_messenger_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s9) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 66;
    }
    let body = {
      receive_status: receive_status,

      r_username: this.state.r_username,
      r_password: this.state.r_password,

      fw_data: fw_data,
      bt_53_status_before: this.state.bt_53_status_before,
      power_attorney_status: this.state.power_attorney_status,
      bt_53_remark_before: this.state.bt_53_remark_before,
      power_attorney_remark: this.state.power_attorney_remark,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/ApproveRecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_7 = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if (receive_status === 73) {
      if (this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
        receive_status = 74;
      } else {
        receive_status = 71;
      }
    } else if (receive_status === 76) {
      if (this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
        receive_status = 79;
      } else {
        this.setState({ loading: false });
        await Swal.fire({
          icon: "warning",
          title: "แจ้งเตือน",
          text: "การเปลี่ยนสถานะ",
          confirmButtonText: "จัดส่งเอกสารใหม่ (นายจ้าง)",
          showDenyButton: true,
          denyButtonText: "แก้ไข Name List",
          showCancelButton: true,
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            receive_status = 74;
          } else if (result.isDenied) {
            receive_status = 71;
          }
        });
        if (receive_status === 76) {
          return;
        }
      }
    } else if (this.state.receive_status === 79) {
      if (!this.state.deliver_documents_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s16 === "Messenger" && !this.state.sending_messenger_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s16) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 77;
    } else if (receive_status === 77) {
      if (this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
        receive_status = 710;
      } else {
        receive_status = 79;
      }
    }
    let body = {
      receive_status: receive_status,

      r_username: this.state.r_username,
      r_password: this.state.r_password,

      fw_data: fw_data,
      bt_53_status_before: this.state.bt_53_status_before,
      power_attorney_status: this.state.power_attorney_status,
      bt_53_remark_before: this.state.bt_53_remark_before,
      power_attorney_remark: this.state.power_attorney_remark,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/ApproveRecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_8 = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if (receive_status === 83) {
      if (this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
        receive_status = 84;
      } else {
        receive_status = 81;
      }
    }else if (receive_status === 84) {
      // STATUS 84 เอกสาร บต.53+หนังสือมอบอำนาจถูกต้อง
      if (!this.state.deliver_documents_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s9 === "Messenger" && !this.state.sending_messenger_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s9) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 86;
    } else if (receive_status === 86) {
      if (this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
        fw_data = fw_data.map(e=> {return {...e,visa_image:"",visa_allowed_date:"",visa_allowed_location:"",visa_expiry_date:"",visa_number:""}})
        console.log(fw_data)
        receive_status = 89;
      } else {
        receive_status = 84;
      }
    }
    let body = {
      receive_status: receive_status,

      r_username: this.state.r_username,
      r_password: this.state.r_password,

      fw_data: fw_data,
      bt_53_status_before: this.state.bt_53_status_before,
      power_attorney_status: this.state.power_attorney_status,
      bt_53_remark_before: this.state.bt_53_remark_before,
      power_attorney_remark: this.state.power_attorney_remark,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/ApproveRecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_11 = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if (receive_status === 112 && this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
      receive_status = 114;
    } else if (receive_status === 112 && this.state.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length >= 1) {
      receive_status = 113;
    } else if (receive_status === 113 && this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
      receive_status = 114;
    } else if (this.state.receive_status === 114) {
      if (!this.state.deliver_documents_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s9 === "Messenger" && !this.state.sending_messenger_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s9) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s9) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 115;
    }
    let body = {
      receive_status: receive_status,

      r_username: this.state.r_username,
      r_password: this.state.r_password,

      fw_data: fw_data,
      bt_53_status_before: this.state.bt_53_status_before,
      power_attorney_status: this.state.power_attorney_status,
      bt_53_remark_before: this.state.bt_53_remark_before,
      power_attorney_remark: this.state.power_attorney_remark,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/ApproveRecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_13 = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if (receive_status === 133) {
      if (this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
        receive_status = 134;
      } else {
        receive_status = 131;
      }
    } else if (receive_status === 136) {
      if (this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
        receive_status = 139;
      } else {
        this.setState({ loading: false });
        await Swal.fire({
          icon: "warning",
          title: "แจ้งเตือน",
          text: "การเปลี่ยนสถานะ",
          confirmButtonText: "จัดส่งเอกสารใหม่ (นายจ้าง)",
          showDenyButton: true,
          denyButtonText: "แก้ไข Name List",
          showCancelButton: true,
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            receive_status = 134;
          } else if (result.isDenied) {
            receive_status = 131;
          }
        });
        if (receive_status === 136) {
          return;
        }
      }
    } else if (this.state.receive_status === 139) {
      if (!this.state.deliver_documents_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s16 === "Messenger" && !this.state.sending_messenger_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s16) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 137;
    } else if (receive_status === 137) {
      if (this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
        receive_status = 1310;
      } else {
        receive_status = 139;
      }
    }
    let body = {
      receive_status: receive_status,

      r_username: this.state.r_username,
      r_password: this.state.r_password,

      fw_data: fw_data,
      bt_53_status_before: this.state.bt_53_status_before,
      power_attorney_status: this.state.power_attorney_status,
      bt_53_remark_before: this.state.bt_53_remark_before,
      power_attorney_remark: this.state.power_attorney_remark,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/ApproveRecieveAll/list");
    }
    this.setState({ loading: false });
  };
  Update_14 = async () => {
    this.setState({ loading: true });
    let receive_status = this.state.receive_status;
    let fw_data = this.state.fw_data;
    if (receive_status === 143) {
      if (this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
        receive_status = 144;
      } else {
        receive_status = 141;
      }
    } else if (receive_status === 146) {
      if (this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
        receive_status = 149;
      } else {
        this.setState({ loading: false });
        await Swal.fire({
          icon: "warning",
          title: "แจ้งเตือน",
          text: "การเปลี่ยนสถานะ",
          confirmButtonText: "จัดส่งเอกสารใหม่ (นายจ้าง)",
          showDenyButton: true,
          denyButtonText: "แก้ไข Name List",
          showCancelButton: true,
          cancelButtonText: "ยกเลิก",
        }).then((result) => {
          if (result.isConfirmed) {
            receive_status = 144;
          } else if (result.isDenied) {
            receive_status = 141;
          }
        });
        if (receive_status === 146) {
          return;
        }
      }
    } else if (this.state.receive_status === 149) {
      if (!this.state.deliver_documents_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (this.state.deliver_documents_s16 === "Messenger" && !this.state.sending_messenger_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก การจัดผู้ส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.sending_document_date_s16) {
        alert("warning", "แจ้งเตือน", "กรุณาเลือก วันที่จัดส่งเอกสาร");
        this.setState({ loading: false });
        return;
      }
      if (!this.state.remark_s16) {
        alert("warning", "แจ้งเตือน", "กรุณากรอก หมายเหตุ");
        this.setState({ loading: false });
        return;
      }
      receive_status = 147;
    } else if (receive_status === 147) {
      if (this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length) {
        receive_status = 1410;
      } else {
        receive_status = 149;
      }
    }
    let body = {
      receive_status: receive_status,

      r_username: this.state.r_username,
      r_password: this.state.r_password,

      fw_data: fw_data,
      bt_53_status_before: this.state.bt_53_status_before,
      power_attorney_status: this.state.power_attorney_status,
      bt_53_remark_before: this.state.bt_53_remark_before,
      power_attorney_remark: this.state.power_attorney_remark,

      deliver_documents_s9: this.state.deliver_documents_s9,
      sending_document_date_s9: this.state.sending_document_date_s9,
      sending_messenger_s9: this.state.sending_messenger_s9,
      remark_s9: this.state.remark_s9,
      documents_s9: this.state.documents_s9,

      deliver_documents_s16: this.state.deliver_documents_s16,
      sending_document_date_s16: this.state.sending_document_date_s16,
      sending_messenger_s16: this.state.sending_messenger_s16,
      remark_s16: this.state.remark_s16,
      documents_s16: this.state.documents_s16,

      deliver_documents_s19: this.state.deliver_documents_s19,
      sending_document_date_s19: this.state.sending_document_date_s19,
      sending_messenger_s19: this.state.sending_messenger_s19,
      remark_s19: this.state.remark_s19,
      documents_s19: this.state.documents_s19,
    };
    let result = await PUT(tokens, "api/receive-matter/update/" + this.state.receive_id, body);
    if (result && result.status) {
      success_url("/ApproveRecieveAll/list");
    }
    this.setState({ loading: false });
  };
  import_file = async (e, type) => {
    let file = e.target.files[0];
    if (file.size > 5000000) {
      alert("error", "แจ้งเตือน", "ขนาดไฟล์เกินกำหนด ไฟล์ต้องมีขนาดไม่เกิน 5 mb");
      return;
    }
    if (!file.type.includes("image") && !file.type.includes("pdf")) {
      alert("error", "แจ้งเตือน", "ไม่สามารถอัพโหลดไฟล์ได้เนื่องจากไม่รองรับรูปแบบไฟล์");
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async (e) => {
      let files = file.type.includes("pdf") ? e.target.result.split(",")[1] : await convert_imagetopdf(e.target.result);
      let fw_data = this.state.fw_data;
      if (type === "documents_s9") {
        this.setState({ documents_s9: files });
      } else if (type === "documents_s16") {
        this.setState({ documents_s16: files });
      } else if (type === "documents_s19") {
        this.setState({ documents_s19: files });
      }
      this.setState({ fw_data: fw_data });
    };
    let array_file = document.getElementsByName("file");
    for (let item of array_file) {
      item.value = "";
      item.file = null;
    }
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <input
          className="d-none"
          type="file"
          name="file"
          id="file_documents_s9"
          accept="application/pdf,image/*"
          onChange={(e) => {
            this.import_file(e, "documents_s9");
          }}
        />
        <input
          className="d-none"
          type="file"
          name="file"
          id="file_documents_s16"
          accept="application/pdf,image/*"
          onChange={(e) => {
            this.import_file(e, "documents_s16");
          }}
        />
        <Navbar page1="หน้าหลัก" page2="รายการ QC" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>รายการ QC</b>
              </h3>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3">
                <input
                  className="form-control wpx-250 bg-contain border-0 mx-1 mb-3"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ data_search: e.target.value });
                  }}
                  value={this.state.data_search}
                />
                <button
                  onClick={() => {
                    this.setState({ page: 1 });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-primary mx-1 mb-3"
                >
                  ค้นหา
                </button>
                <button
                  onClick={() => {
                    this.setState({ page: 1, data_search: "" });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-outline-primary mx-1 mb-3"
                >
                  รีเซ็ต
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">วันที่รับเรื่อง</th>
                      <th className="text-left text-white">เลขเรื่อง</th>
                      <th className="text-left text-white">ลูกค้า</th>
                      <th className="text-center text-white">ดำเนินการเรื่อง</th>
                      <th className="text-center text-white">ผู้บันทึกข้อมูล</th>
                      <th className="text-center text-white">สถานะ</th>
                      <th className="text-center text-white update">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{(this.state.page - 1) * 10 + index + 1}</td>
                          <td className="text-left">{item.receive_date ? format_date(item.receive_date) : "-"}</td>
                          <td className="text-left">{item.receive_code ? item.receive_code : "-"}</td>
                          <td className="text-left">{item.emp_data ? item.emp_data.full_name_th : "-"}</td>
                          <td className="text-left">{item.menu_data ? item.menu_data.menu_name : "-"}</td>
                          <td className="text-left">{item.create_by ? item.create_by : "-"}</td>
                          <td className="text-left">
                            {item.receive_status} {this.Status(item)}
                          </td>
                          <td className="text-center update">
                            <div className="w-100 d-flex justify-content-center">
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  window.location.href = "/RecieveAll/update?id=" + item.receive_id;
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf06e"}</label>
                              </div>
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  this.getById(item.receive_id);
                                  switch (item.receive_status) {
                                    case 2:
                                      this.setState({ modal_match2: true });
                                      break; // มอบอำนาจ Matching
                                    case 4:
                                      this.setState({ modal_match4: true });
                                      break; // Machting ไม่ได้
                                    case 7:
                                      this.setState({ modal_match7: true });
                                      break; // รอตรวจสอบ บต.46+มอบอำนาจ
                                    case 8:
                                      this.setState({ modal_match7: true });
                                      break; // เอกสารไม่ถูกต้อง บต.46
                                    case 11:
                                      this.setState({ modal_match7: true });
                                      break; // เอกสารไม่ถูกต้อง บต.46+มอบอำนาจ ครั้งสุดท้าย
                                    case 63:
                                      this.setState({ modal_match_6: true });
                                      break; // เอกสารไม่ถูกต้อง บต.46+มอบอำนาจ ครั้งสุดท้าย
                                    case 64:
                                      this.setState({ modal_match_6: true });
                                      break; // เอกสารไม่ถูกต้อง บต.46+มอบอำนาจ ครั้งสุดท้าย
                                    case 65:
                                      this.setState({ modal_match_6: true });
                                      break; // เอกสารไม่ถูกต้อง บต.46+มอบอำนาจ ครั้งสุดท้าย
                                      case 73:
                                        this.setState({ modal_match_7: true });
                                        break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                      case 75:
                                        this.setState({ modal_match_7: true });
                                        break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                      case 76:
                                        this.setState({ modal_match_7: true });
                                        break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                      case 77:
                                        this.setState({ modal_match_7: true });
                                        break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                      case 78:
                                        this.setState({ modal_match_7: true });
                                        break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                      case 79:
                                        this.setState({ modal_match_7: true });
                                        break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                        case 83:
                                          this.setState({ modal_match_8: true });
                                          break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                        case 84:
                                          this.setState({ modal_match_8: true });
                                          break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                        case 85:
                                          this.setState({ modal_match_8: true });
                                          break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                        case 86:
                                          this.setState({ modal_match_8: true });
                                          break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                        case 87:
                                          this.setState({ modal_match_8: true });
                                          break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                        case 88:
                                          this.setState({ modal_match_8: true });
                                          break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                        case 89:
                                          this.setState({ modal_match_8: true });
                                          break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                    case 111:
                                      this.setState({ modal_match_11: true });
                                      break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                    case 112:
                                      this.setState({ modal_match_11: true });
                                      break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                    case 113:
                                      this.setState({ modal_match_11: true });
                                      break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                    case 114:
                                      this.setState({ modal_match_11: true });
                                      break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                    case 133:
                                      this.setState({ modal_match_13: true });
                                      break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                    case 135:
                                      this.setState({ modal_match_13: true });
                                      break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                    case 136:
                                      this.setState({ modal_match_13: true });
                                      break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                    case 137:
                                      this.setState({ modal_match_13: true });
                                      break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                    case 138:
                                      this.setState({ modal_match_13: true });
                                      break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                    case 139:
                                      this.setState({ modal_match_13: true });
                                      break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                    case 143:
                                        this.setState({ modal_match_14: true });
                                        break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                        case 145:
                                      this.setState({ modal_match_14: true });
                                      break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                    case 146:
                                      this.setState({ modal_match_14: true });
                                      break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                    case 147:
                                      this.setState({ modal_match_14: true });
                                      break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                    case 148:
                                      this.setState({ modal_match_14: true });
                                      break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                    case 149:
                                      this.setState({ modal_match_14: true });
                                      break; // แนบ บต.53+หนังสือมอบอำนาจ แล้ว
                                    default:
                                      break;
                                  }
                                }}
                              >
                                <label className="pointer icon text-danger p-2">{"\uf044"}</label>
                              </div>
                              {item.receive_status === 2 && (
                                <div
                                  className="pointer bg-cyan-light rounded text-primary px-2 py-1 wpx-100"
                                  onClick={() => {
                                    this.setState({ modal_delete: false });
                                  }}
                                >
                                  ใบมอบอำนาจ
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {this.state.data && this.state.data.length !== 0 && (
                <div className="d-flex justify-content-between mx-0 w-100 px-3 my-3">
                  <label>
                    แสดง {this.state.end_index} รายการ จาก {this.state.count} รายการ
                  </label>
                  <Pagination
                    count={Math.ceil(this.state.count / 10)}
                    page={this.state.page}
                    color="primary"
                    showFirstButton
                    shape="rounded"
                    showLastButton
                    onChange={(event, value) => {
                      this.setState({ page: value });
                      setTimeout(() => {
                        this.Get();
                      }, 10);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* status 14 แจ้งเข้าทำงานเปลี่ยนนายจ้าง */}
        <Modal
          show={this.state.modal_match_14}
          onHide={() => {
            this.setState({ modal_match_14: false });
          }}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>
              <b>สถานะงาน</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* tab ด้านบน */}
            <div className="card mb-3">
              {/* ข้อมูลนายจ้าง */}
              <div>
                <div
                  className={this.state.show_tab === 1 ? "card-header pointer bg-primary-light" : "card-header pointer"}
                  onClick={() => {
                    this.setState({
                      show_tab: this.state.show_tab === 1 ? 0 : 1,
                    });
                  }}
                >
                  ข้อมูลนายจ้าง {this.state.emp_data ? this.state.emp_data.full_name_th : ""}
                </div>
                {this.state.show_tab === 1 && (
                  <div className="card-body">
                    {this.state.emp_data && (
                      <div>
                        <div
                          className="pointer bg-cyan-light rounded text-primary px-2 py-1 wpx-150 text-center mb-3"
                          onClick={() => {
                            window.location.href = "/Employer/update?id=" + this.state.emp_data.emp_id;
                          }}
                        >
                          แก้ไขข้อมูลนายจ้าง
                        </div>
                        <div className="row">
                          {/* เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก) */}
                          <div className="mb-3 col-6">
                            <b>เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก)</b>
                          </div>
                          <div className="mb-3 col-6">{this.state.emp_data.emp_number ? this.state.emp_data.emp_number : "-"}</div>
                          {/* English */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>English</b>
                            </div>
                            {this.state.emp_data.full_name_en ? this.state.emp_data.full_name_en : "-"}
                          </div>
                          {/* ประเภทนายจ้าง */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทนายจ้าง</b>
                            </div>
                            {this.state.emp_data.emp_type === 1 ? "บุคคลธรรมดา" : this.state.emp_data.emp_type === 2 ? "นิติบุคคล" : this.state.emp_data.emp_type === 3 ? "นิติบุคคลต่างด้าว" : ""}
                          </div>
                          {/* จดทะเบียนเมื่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>จดทะเบียนเมื่อ</b>
                            </div>
                            {this.state.emp_data.register_date ? format_date(this.state.emp_data.register_date) : "-"}
                          </div>
                          {/* ทุนจดทะเบียน (บาท) */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ทุนจดทะเบียน (บาท)</b>
                            </div>
                            {this.state.emp_data.register_capital ? toFixed(this.state.emp_data.register_capital) : "-"}
                          </div>
                          {/* ประเภทกิจการ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ประเภทกิจการ</b>
                            </div>
                            {this.state.emp_data.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.emp_data.business_type.toString())[0].label : "-"}
                          </div>
                          {/* ชื่อผู้ติดต่อ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>ชื่อผู้ติดต่อ</b>
                            </div>
                            {this.state.emp_data.contact_name ? this.state.emp_data.contact_name : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax ? this.state.emp_data.fax : "-"}
                          </div>
                          {/* หมายเลขโทรศัพท์มือถือ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์มือถือ</b>
                            </div>
                            {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                          </div>
                          {/* E-mail Address */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>E-mail Address</b>
                            </div>
                            {this.state.emp_data.email ? this.state.emp_data.email : "-"}
                          </div>
                          {/* เลขรหัสประจำบ้าน ๑๑ หลัก */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>เลขรหัสประจำบ้าน ๑๑ หลัก</b>
                            </div>
                            {this.state.emp_data.home_number ? this.state.emp_data.home_number : "-"}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " หมู่ " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " ซอย " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " ถนน " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " ตำบล " + this.state.emp_data.sub_district : ""}
                            {this.state.emp_data.district ? " อำเภอ " + this.state.emp_data.district : ""}
                            {this.state.emp_data.province ? " จังหวัด " + this.state.emp_data.province : ""}
                            {this.state.emp_data.zipcode ? " รหัสไปรษณีย์ " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* ที่ตั้ง/ที่อยู่ (English) */}
                          <div className="mb-3 d-flex col-12">
                            <div className="wpx-220">
                              <b>ที่ตั้ง/ที่อยู่ (English)</b>
                            </div>
                            {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                            {this.state.emp_data.moo ? " Moo " + this.state.emp_data.moo : ""}
                            {this.state.emp_data.soi ? " Soi " + this.state.emp_data.soi : ""}
                            {this.state.emp_data.street ? " Street " + this.state.emp_data.street : ""}
                            {this.state.emp_data.sub_district ? " , " + subdistrict.filter((e) => e.sub_district_name === this.state.emp_data.sub_district)[0].sub_district_name_en : ""}
                            {this.state.emp_data.district ? " , " + district.filter((e) => e.district_name === this.state.emp_data.district)[0].district_name_en : ""}
                            {this.state.emp_data.province ? " , " + province.filter((e) => e.province_name === this.state.emp_data.province)[0].province_name_en : ""}
                            {this.state.emp_data.zipcode ? " " + this.state.emp_data.zipcode : ""}
                          </div>
                          {/* หมายเลขโทรศัพท์ */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรศัพท์</b>
                            </div>
                            {this.state.emp_data.phone_2 ? this.state.emp_data.phone_2 : "-"}
                          </div>
                          {/* หมายเลขโทรสาร */}
                          <div className="mb-3 d-flex col-6">
                            <div className="wpx-220">
                              <b>หมายเลขโทรสาร</b>
                            </div>
                            {this.state.emp_data.fax_2 ? this.state.emp_data.fax_2 : "-"}
                          </div>
                        </div>
                        <div className="row">
                          {this.state.data_deputize &&
                            this.state.data_deputize.map((item, index) => (
                              <div className="row">
                                {/* ผู้มีอำนาจผูกพัน */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>{index + 1}. ผู้มีอำนาจผูกพัน</b>
                                  </div>
                                  {item.deputize_full_name ? item.deputize_full_name : "-"}
                                </div>
                                {/* ตำแหน่ง */}
                                <div className="mb-3 d-flex col-6">
                                  <div className="wpx-220">
                                    <b>ตำแหน่ง</b>
                                  </div>
                                  {item.deputize_position ? item.deputize_position : "-"}
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

            </div>
            {/* status 143 */}
            {this.state.receive_status === 143 && (
              <div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="mb-0">
                    <b>ตรวจสอบเอกสาร</b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-left text-white">เอกสาร</th>
                      <th className="text-center text-white">
                        การตรวจสอบเอกสาร
                        <div className="d-flex align-items-center mt-2">
                          <input
                            className="mt-1 me-3"
                            type="radio"
                            onChange={(e) => {
                              let fw_data = this.state.fw_data;
                              for (let item of fw_data) {
                                item.status_document = "ถูกต้อง";
                              }
                              this.setState({ fw_data: fw_data });
                            }}
                            checked={this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length}
                          />
                          ถูกต้อง
                          <input
                            className="mt-1 mx-3"
                            type="radio"
                            onChange={(e) => {
                              let fw_data = this.state.fw_data;
                              for (let item of fw_data) {
                                item.status_document = "ไม่ถูกต้อง";
                              }
                              this.setState({ fw_data: fw_data });
                            }}
                            checked={this.state.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length === this.state.fw_data.length}
                          />
                          ไม่ถูกต้อง
                        </div>
                      </th>
                      <th className="text-left text-white">หมายเหตุ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left align-middle">
                            <div className="d-flex overflow-scroll wpx-300">
                              <div
                                className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-2"
                                onClick={() => {
                                  document_contact(
                                    this.state.fw_data,
                                    this.state.emp_data,
                                    this.state.data_workplace,
                                    this.state.dpz_data,
                                    {
                                      witness_power_1: this.state.witness_power_1,
                                      witness_power_2_th: this.state.witness_power_2_th,
                                      witness_power_2_en: this.state.witness_power_2_en,
                                    },
                                    this.state.data_prefix_th,
                                    this.state.data_prefix_en
                                  );
                                }}
                              >
                                ใบมอบอำนาจ
                              </div>
                              <div className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1" onClick={() => {  }}>
                                  บต. 44
                                </div>
                                <div className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1" onClick={() => {  }}>
                                  บต. 52
                                </div>
                                <div className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1" onClick={() => {  }}>
                                  บต. 55
                                </div>
                                <div className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1" onClick={() => {  }}>
                                  บต. 46
                                </div>
                                <div className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1" onClick={() => {  }}>
                                  บต. 56 (กทม).
                                </div>
                                <div className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1" onClick={() => {  }}>
                                  บต. 56 (ตจว).
                                </div>
                                <div className="pointer bg-danger-light rounded text-primary px-2 py-1 text-nowrap text-center mx-1" onClick={() => {  }}>
                                  สัญญาจ้าง
                                </div>
                            </div>
                          </td>

                          <td className="text-left">
                            <div className="d-flex align-items-center mt-2">
                              <input
                                className="mt-1 me-3"
                                type="radio"
                                name={"status_document" + index}
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].status_document = "ถูกต้อง";
                                  this.setState({ fw_data: fw_data });
                                }}
                                checked={item.status_document === "ถูกต้อง"}
                              />
                              ถูกต้อง
                              <input
                                className="mt-1 mx-3"
                                type="radio"
                                name={"status_document" + index}
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].status_document = "ไม่ถูกต้อง";
                                  this.setState({ fw_data: fw_data });
                                }}
                                checked={item.status_document === "ไม่ถูกต้อง"}
                              />
                              ไม่ถูกต้อง
                            </div>
                          </td>
                          <td className="text-left">
                            <input
                              className="form-control mt-1"
                              type="text"
                              onChange={(e) => {
                                let fw_data = this.state.fw_data;
                                fw_data[index].remark_document = e.target.value;
                                this.setState({ fw_data: fw_data });
                              }}
                              value={item.remark_document}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 146 */}
            {this.state.receive_status === 146 && (
              <div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="mb-0">
                    <b>ตรวจสอบเอกสารที่รับกลับมาจากนายจ้าง</b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-center text-white">
                        การตรวจสอบเอกสาร
                        <div className="d-flex align-items-center mt-2">
                          <input
                            className="mt-1 me-3"
                            type="radio"
                            onChange={(e) => {
                              let fw_data = this.state.fw_data;
                              for (let item of fw_data) {
                                item.status_document = "ถูกต้อง";
                              }
                              this.setState({ fw_data: fw_data });
                            }}
                            checked={this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length}
                          />
                          ถูกต้อง
                          <input
                            className="mt-1 mx-3"
                            type="radio"
                            onChange={(e) => {
                              let fw_data = this.state.fw_data;
                              for (let item of fw_data) {
                                item.status_document = "ไม่ถูกต้อง";
                              }
                              this.setState({ fw_data: fw_data });
                            }}
                            checked={this.state.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length === this.state.fw_data.length}
                          />
                          ไม่ถูกต้อง
                        </div>
                      </th>
                      <th className="text-left text-white">หมายเหตุ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left">
                            <div className="d-flex align-items-center mt-2">
                              <input
                                className="mt-1 me-3"
                                type="radio"
                                name={"status_document" + index}
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].status_document = "ถูกต้อง";
                                  this.setState({ fw_data: fw_data });
                                }}
                                checked={item.status_document === "ถูกต้อง"}
                              />
                              ถูกต้อง
                              <input
                                className="mt-1 mx-3"
                                type="radio"
                                name={"status_document" + index}
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].status_document = "ไม่ถูกต้อง";
                                  this.setState({ fw_data: fw_data });
                                }}
                                checked={item.status_document === "ไม่ถูกต้อง"}
                              />
                              ไม่ถูกต้อง
                            </div>
                          </td>
                          <td className="text-left">
                            <input
                              className="form-control mt-1"
                              type="text"
                              onChange={(e) => {
                                let fw_data = this.state.fw_data;
                                fw_data[index].remark_document = e.target.value;
                                this.setState({ fw_data: fw_data });
                              }}
                              value={item.remark_document}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
            {/* status 149 จัดส่งเอกสารใบเสร็จ */}
            {this.state.receive_status === 149 && (
              <div>
                <h5 className="mb-3">
                  <b>สถานะงานถัดไป</b>
                </h5>
                <div className="d-flex align-items-center mb-3">
                  <h6 className="mb-0">
                    <b>จัดส่งเอกสารจัดหางาน</b>
                  </h6>
                </div>
                <div className="card p-3">
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">จัดส่งเอกสาร{required}</label>
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s16: "Line" });
                      }}
                      checked={this.state.deliver_documents_s16 === "Line"}
                      disabled
                    />
                    Line
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s16: "Messenger" });
                      }}
                      checked={this.state.deliver_documents_s16 === "Messenger"}
                    />
                    Messenger
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({ deliver_documents_s16: "ไปรษณีย์" });
                      }}
                      checked={this.state.deliver_documents_s16 === "ไปรษณีย์"}
                      disabled
                    />
                    ไปรษณีย์
                    <input
                      className="mt-1 mx-3"
                      type="radio"
                      name="deliver_documents_s16"
                      onChange={(e) => {
                        this.setState({
                          deliver_documents_s16: "มารับด้วยตัวเอง",
                        });
                      }}
                      checked={this.state.deliver_documents_s16 === "มารับด้วยตัวเอง"}
                      disabled
                    />
                    มารับด้วยตัวเอง
                  </div>
                  {this.state.deliver_documents_s16 === "Messenger" && (
                    <div className="d-flex align-items-center mb-3">
                      <label className="wpx-150">ผู้ส่งเอกสาร{required}</label>
                      <select
                        className="form-control mt-1"
                        placeholder="พิมพ์คำค้นหา..."
                        onChange={(e) => {
                          this.setState({ sending_messenger_s16: e.target.value });
                        }}
                        value={this.state.sending_messenger_s16}
                      >
                        <option value=""></option>
                        {this.state.data_user.map((item, index) => (
                          <option value={item.user_id}>{item.full_name}</option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">วันที่ส่งเอกสาร{required}</label>
                    <DatePicker
                      className="form-control mt-1"
                      placeholder="พิมพ์คำค้นหา..."
                      onChange={(e) => {
                        this.setState({ sending_document_date_s16: e });
                      }}
                      value={this.state.sending_document_date_s16}
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label className="wpx-150">เอกสารแนบ</label>
                    {!this.state.documents_s16 ? (
                      <button
                        className="btn btn-primary mx-1 px-4"
                        accept="application/pdf"
                        onClick={() => {
                          document.getElementById("file_documents_s16").click();
                        }}
                      >
                        แนบไฟล์
                      </button>
                    ) : (
                      <div className="w-100 d-flex justify-content-start">
                        <a target="_blank" download href={this.state.documents_s16 && this.state.documents_s16.includes("://") ? this.state.documents_s16 : "data:application/pdf;base64," + this.state.documents_s16}>
                          เอกสารแนบ.pdf
                        </a>
                        <div
                          className="pointer wpx-30 hpx-30 rounded bg-danger-light mx-1 d-flex justify-content-center align-items-center ms-2"
                          onClick={() => {
                            this.setState({ documents_s16: "" });
                          }}
                        >
                          <label className="pointer icon text-danger p-2">{"\uf2ed"}</label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="wpx-150">หมายเหตุ{required}</label>
                    <br />
                    <textarea
                      className="form-control mt-1"
                      placeholder="หมายเหตุ..."
                      onChange={(e) => {
                        this.setState({ remark_s16: e.target.value });
                      }}
                      value={this.state.remark_s16}
                      rows={5}
                    />
                  </div>
                  <div>{this.state.installment && this.state.installment.length !== 0 && this.state.installment.filter((e) => e.paid).length === this.state.installment.length ? <span className="fw-bold text-16 px-3 py-1 border border-success text-success rounded-3 mb-3">ชำระครบแล้ว</span> : <span className="fw-bold text-16 px-3 py-1 border border-danger text-danger rounded-3 mb-3">ค้างชำระ</span>}</div>
                </div>
              </div>
            )}
            {/* status 147 */}
            {this.state.receive_status === 147 && (
              <div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h6 className="mb-0">
                    <b>ตรวจสอบเอกสารที่รับกลับมาจากสำนักงานจัดหางาน</b>
                  </h6>
                </div>
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">ชื่อ-นามสกุล</th>
                      <th className="text-left text-white">เลขบัตรประจำตัว</th>
                      <th className="text-left text-white">สัญชาติ</th>
                      <th className="text-center text-white">
                        การตรวจสอบเอกสาร
                        <div className="d-flex align-items-center mt-2">
                          <input
                            className="mt-1 me-3"
                            type="radio"
                            onChange={(e) => {
                              let fw_data = this.state.fw_data;
                              for (let item of fw_data) {
                                item.status_document = "ถูกต้อง";
                              }
                              this.setState({ fw_data: fw_data });
                            }}
                            checked={this.state.fw_data.filter((e) => e.status_document === "ถูกต้อง").length === this.state.fw_data.length}
                          />
                          ถูกต้อง
                          <input
                            className="mt-1 mx-3"
                            type="radio"
                            onChange={(e) => {
                              let fw_data = this.state.fw_data;
                              for (let item of fw_data) {
                                item.status_document = "ไม่ถูกต้อง";
                              }
                              this.setState({ fw_data: fw_data });
                            }}
                            checked={this.state.fw_data.filter((e) => e.status_document === "ไม่ถูกต้อง").length === this.state.fw_data.length}
                          />
                          ไม่ถูกต้อง
                        </div>
                      </th>
                      <th className="text-left text-white">หมายเหตุ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fw_data &&
                      this.state.fw_data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle">{index + 1}</td>
                          <td className="text-left align-middle">{item.first_name_th ? item.first_name_th : "-"}</td>
                          <td className="text-left align-middle">{item.fw_number ? item.fw_number : "-"}</td>
                          <td className="text-left align-middle">
                            {item.nationality === 1 ? "เมียนมา" : ""}
                            {item.nationality === 2 ? "ลาว" : ""}
                            {item.nationality === 3 ? "กัมพูชา" : ""}
                            {item.nationality === 4 ? "เวียดนาม" : ""}
                          </td>
                          <td className="text-left">
                            <div className="d-flex align-items-center mt-2">
                              <input
                                className="mt-1 me-3"
                                type="radio"
                                name={"status_document" + index}
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].status_document = "ถูกต้อง";
                                  this.setState({ fw_data: fw_data });
                                }}
                                checked={item.status_document === "ถูกต้อง"}
                              />
                              ถูกต้อง
                              <input
                                className="mt-1 mx-3"
                                type="radio"
                                name={"status_document" + index}
                                onChange={(e) => {
                                  let fw_data = this.state.fw_data;
                                  fw_data[index].status_document = "ไม่ถูกต้อง";
                                  this.setState({ fw_data: fw_data });
                                }}
                                checked={item.status_document === "ไม่ถูกต้อง"}
                              />
                              ไม่ถูกต้อง
                            </div>
                          </td>
                          <td className="text-left">
                            <input
                              className="form-control mt-1"
                              type="text"
                              onChange={(e) => {
                                let fw_data = this.state.fw_data;
                                fw_data[index].remark_document = e.target.value;
                                this.setState({ fw_data: fw_data });
                              }}
                              value={item.remark_document}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex">
              <div className="w-50 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_match_14: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="w-50 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    if (this.state.bt_53_status_before === null || this.state.power_attorney_status === null) {
                      alert("warning", "แจ้งเตือน", "กรุณาตรวจสอบเอกสารให้ครบ");
                      return;
                    }
                    this.Update_14();
                  }}
                >
                  บันทึก
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
