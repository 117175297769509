import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../../components/Navbar.js";
import Select from "react-select";
import Pagination from "@mui/material/Pagination";
import PDFMerger from "pdf-merger-js";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import LOGO from "../../assets/images/logo.png";

import Swal from "sweetalert2";

import province from "../../assets/files/province.json";
import district from "../../assets/files/district.json";
import subdistrict from "../../assets/files/all_province.json";
import {
  locale,
  tokens,
  select_style,
  required,
  // alert
  success,
  success_url,
  alert,
  alert_url,
  // function
  logout,
  number_zero,
  format_date,
  toFixed,
  total,
  validate,
  random_charactor,
  float,
  // api
  GET,
  POST,
  PUT,
  DELETE,
  error_message,
  // storage
  removeStorage,
  getStorage,
  validate_email,
  validate_th,
  validate_en,
  validate_en_number,
  LinktoBase64,
  // component
  DatePicker,
  convert_imagetopdf,
  document_contact,
} from "../../components/CustomComponent.js";

import "../../components/global.js";
import "../../assets/css/bootstrap.css";
import "../../assets/css/index.css";

export default class ListRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_detail: false,
      modal_export: false,
      loading: false,
      data: [], 
      data_business_type: [],

      data_search: "",

      page: 1,

      createdAt: null,
      id: null,
      quotation_id: "",
      quotation_number: "",
      req_price_id: "",
      receive_id: "",
      emp_id: null,
      full_name: "",
      menu_id: "",
      menu_name: "",
      cambodia_qty: 0,
      laos_qty: 0,
      myanmar_qty: 0,
      vietnam_qty: 0,
      total_qty: 0,
      rate_list: [],
      installment: [],
      quotation_status: null,
      is_active: false,
      create_by: "",
      emp_data: null,
      is_vat: false,
      payment_person: ""
    };
  }
  async componentDidMount() {
    await this.Get();
    await this.GetBusinessType();
  }
  GetBusinessType = async () => {
    let body = {
      data_search: "",
      business_type_id: "",
    };
    let result = await GET(tokens, "api/setting/business-type/list/page/0", body);
    if (result && result.status) {
      this.setState({
        data_business_type: result.data.map((e) => {
          return { value: e.id.toString(), label: e.business_type_th };
        }),
      });
    }
  };
  Get = async () => {
    this.setState({ loading: true });
    let body = {
      data_search: this.state.data_search,
      holder: 1,
    };
    let result = await GET(tokens, "api/setting/quotation/list/page/" + this.state.page, body);
    if (result && result.status) { 
      this.setState({
        data: result.data,
        end_index: result.count < result.end_index ? result.count : result.end_index,
        count: result.count,
        page_number: result.page_number,
        count_status: result.count_status,
      });
    }
    this.setState({ loading: false });
  };
  getById = async (id) => {
    this.setState({ loading: true });
    let body = {
      data_search: "",
      quotation_id: id,
      menu: "",
      holder: 1,
    };
    let result = await GET(tokens, "api/setting/quotation/list/page/0", body);
    if (result && result.status) { 
      console.log(result.emp_data)
      this.setState({
        id: result.data.id,
        quotation_id: result.data.quotation_id,
        quotation_number: result.data.quotation_number,
        req_price_id: result.data.req_price_id,
        receive_id: result.data.receive_id,
        emp_id: result.emp_data?result.emp_data.emp_id:"",
        full_name: result.emp_data?result.emp_data.full_name_th:"",
        menu_id: result.data.menu_id,
        menu_name: result.data.menu_name,
        cambodia_qty: result.data.cambodia_qty,
        laos_qty: result.data.laos_qty,
        myanmar_qty: result.data.myanmar_qty,
        vietnam_qty: result.data.vietnam_qty,
        total_qty: result.data.total_qty,
        rate_list: result.data.rate_list,
        installment: result.data.installment,
        quotation_status: result.data.quotation_status,
        is_active: result.data.is_active,
        create_by: result.data.create_by,
        emp_data: result.emp_data,
        is_vat: result.data.is_vat,
        payment_person: result.data.payment_person
      });
    }
    this.setState({ loading: false });
  };
  Update = async () => {  
    this.setState({ loading: true });
    let status = 22;
    if(this.state.menu_name === "ขอใบอนุญาตทำงานแรงงานต่างด้าว" || this.state.menu_name === "ขอนำเข้าแรงงานต่างด้าว (MOU)"){
      status = 1;
    }
    if(this.state.menu_name === "แจ้งอยู่เกิน 90 วัน + ต่อ Work + ต่อ VISA"){
      status = 60;
    }
    if(this.state.menu_name === "แจ้งเข้าที่พัก (ตม.)"){
      status = 70;
    }
    if(this.state.menu_name === "ย้ายดวงตรา VISA"){
      status = 80;
    }
    if(this.state.menu_name === "เปลี่ยนเล่ม Passport"){
      status = 110;
    }
    if(this.state.menu_name === "แจ้งคนต่างด้าวออกจากงาน บต.53"){
      status = 130;
    }
    if(this.state.menu_name === "แจ้งเข้าทำงานเปลี่ยนนายจ้าง"){
      status = 140;
    }
    let body = {
      req_price_id: this.state.req_price_id,
      receive_date: new Date(),
      branch: 1,
      immigration_office: "",
      filing_type: "",
      billing_type: "",
      broker: "",
      process_matter: this.state.menu_id,
      cambodia_qty: this.state.cambodia_qty,
      laos_qty: this.state.laos_qty,
      myanmar_qty: this.state.myanmar_qty,
      vietnam_qty: this.state.vietnam_qty,
      total_qty: this.state.total_qty,
      remark: "",
      buy_health_insurance: null,
      type_health_insurance: "",
      buy_disease_service: null,
      type_disease_service: "",
      c_certificate: false,
      c_certificate_issue_date: "",
      c_certificate_20: false,
      c_copy_committee: false,
      c_copy_committee_expire_date: "",
      emp_number: this.state.emp_data.emp_number,
      data_deputize: [],
      data_workplace: [],
      place_work: "",
      witness_power_1: "",
      witness_power_2_th: "",
      witness_power_2_en: "",
      witness_contract_1: "",
      witness_contract_2_th: "",
      witness_contract_2_en: "",
      
      emp_period_y: "",
      emp_period_m: "",
      emp_period_d: "",
      contract_until_date: "",

      payment_choice: "",
      listname_choice: "",
      receive_status: status,
      rate_list: this.state.rate_list,
      quotation_id: this.state.quotation_id,
      payment_person: this.state.payment_person
    };
    let result = await POST(tokens, "api/receive-matter/create", body);
    if (result && result.status) {
     let body = {
      receive_id: result.data,
      quotation_status: 2
    };
    let result2 = await PUT(tokens, "api/setting/quotation/update/" + this.state.quotation_id, body);
    if (result2 && result2.status) {
      success_url("/Quotation/list");
    }
    }
    
    this.setState({ loading: false });
  };
  Export = async () => { 
  };
  total = () => {
    if (this.state.rate_list) {
      let total = 0;
      for (let item of this.state.rate_list) {
        total += (Number(item.qty_price) * Number(item.qty))-Number(item.discount)+Number(item.vat);
      }
      return total;
    } else {
      return 0;
    }
  };
  render() {
    return (
      <div className="w-100 min-vh-100 fade-in">
        {this.state.loading && (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )}
        <Navbar page1="หน้าหลัก" page2="ใบเสนอราคา" />
        {/* BODY */}
        <div className="body bg-light min-vh-100">
          <div className="p-3">
            {/* หัวข้อ และ ปุ่มเพิ่ม */}
            <div className="d-flex mx-0 align-items-center justify-content-between test mb-3">
              <h3 className="mb-3">
                <b>ใบเสนอราคา</b>
              </h3>
              <div className="d-flex">
                <button
                  className="btn btn-primary mx-1 update"
                  onClick={() => {
                    window.location.href = "/Quotation/create";
                  }}
                >
                  <label className="icon">{"\u002B"}</label> สร้างใบเสนอราคา
                </button>
              </div>
            </div>
            <div className="w-100 card border-0 p-0">
              <div className="d-flex mx-0 px-3 pt-3">
                <input
                  className="form-control wpx-250 bg-contain border-0 mx-1 mb-3"
                  type="text"
                  placeholder="กรอกข้อมูล"
                  onChange={(e) => {
                    this.setState({ data_search: e.target.value });
                  }}
                  value={this.state.data_search}
                />
                <button
                  onClick={() => {
                    this.setState({ page: 1 });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-primary mx-1 mb-3"
                >
                  ค้นหา
                </button>
                <button
                  onClick={() => {
                    this.setState({ page: 1, data_search: "" });
                    setTimeout(() => {
                      this.Get();
                    }, 10);
                  }}
                  className="btn btn-outline-primary mx-1 mb-3"
                >
                  รีเซ็ต
                </button>
              </div>
              <div className="table-responsive">
                <table className="table table-borderless table-striped">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white">ลำดับ</th>
                      <th className="text-left text-white">วันที่สร้าง</th>
                      <th className="text-left text-white">เลขที่ใบเสนอราคา</th>
                      <th className="text-left text-white">ลูกค้า</th>
                      <th className="text-left text-white">บริการที่เสนอราคา</th>
                      <th className="text-left text-white">ผู้ขาย</th>
                      <th className="text-left text-white">สถานะ</th>
                      <th className="text-center text-white update">จัดการ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data &&
                      this.state.data.map((item, index) => (
                        <tr key={index}>
                          <td className="text-center">{this.state.page ? (this.state.page - 1) * 10 + index + 1 : "-"}</td>
                          <td className="text-left">{item.createdAt ? format_date(item.createdAt) : "-"}</td>
                          <td className="text-left">{item.quotation_number ? item.quotation_number : "-"}</td>
                          <td className="text-left">{item.full_name ? item.full_name : "-"}</td>
                          <td className="text-left">{item.menu_name ? item.menu_name : "-"}</td>
                          <td className="text-left">{item.create_by ? item.create_by : "-"}</td>
                          <td className="text-left">{item.quotation_status ? item.quotation_status === 1 ? "รอเปิดเรื่อง" :item.quotation_status === 2 ? "เปิดเรื่องแล้ว" :"-": "-"}</td>
                          <td className="text-center update">
                            <div className="w-100 d-flex justify-content-center">
                              <div
                                className="pointer wpx-30 hpx-30 rounded bg-primary-light mx-1 d-flex justify-content-center align-items-center"
                                onClick={async () => {
                                  window.location.href = "/Quotation/update?id=" + item.quotation_id;
                                }}
                              >
                                <label className="pointer icon text-primary p-2">{"\uf044"}</label>
                              </div>
                              {/* {item.quotation_status === 1 && (
                              <div
                                className="pointer bg-warning-light rounded text-primary px-2 py-1 wpx-80 mx-1"
                                onClick={async () => {
                                  await this.getById(item.quotation_id);
                                  setTimeout(() => {
                                    this.setState({ modal_detail: true, createdAt: item.createdAt });
                                  }, 10);
                                }}
                              >
                                เปิดเรื่อง
                              </div>
                              )} */}
                              <div
                                className="pointer bg-cyan-light rounded text-primary px-2 py-1 wpx-100 mx-1"
                                onClick={async () => {
                                  await this.getById(item.quotation_id);
                                  setTimeout(() => {
                                    this.setState({ modal_export: true, createdAt: item.createdAt });
                                  }, 10);
                                }}
                              >
                                ใบเสนอราคา
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {this.state.data && this.state.data.length !== 0 && (
                <div className="d-flex justify-content-between mx-0 w-100 px-3 my-3">
                  <label>
                    แสดง {this.state.end_index} รายการ จาก {this.state.count} รายการ
                  </label>
                  <Pagination
                    count={Math.ceil(this.state.count / 10)}
                    page={this.state.page}
                    color="primary"
                    showFirstButton
                    shape="rounded"
                    showLastButton
                    onChange={(event, value) => {
                      this.setState({ page: value });
                      setTimeout(() => {
                        this.Get();
                      }, 10);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* เปิดเรื่อง */}
        <Modal
          show={this.state.modal_detail}
          onHide={() => {
            this.setState({ modal_detail: false });
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>เปิดเรื่อง</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>
              <b>ข้อมูลรายละเอียดรับเรื่อง</b>
            </h5>
            <hr />
            <div className="row">
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>วันที่สร้าง</b>
                </label>
                <label>{format_date(this.state.createdAt)}</label>
              </div>
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>ลูกค้า</b>
                </label>
                <label>{this.state.full_name}</label>
              </div>
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>บริการที่เสนอราคา</b>
                </label>
                <label>{this.state.menu_name}</label>
              </div>
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>สัญชาติ</b>
                </label>
                <label>{this.state.cambodia_qty ? "กัมพูชา " : ""}</label>
                <label>{this.state.laos_qty ? "ลาว " : ""}</label>
                <label>{this.state.myanmar_qty ? "เมียนมา " : ""}</label>
                <label>{this.state.vietnam_qty ? "เวียดนาม " : ""}</label>
              </div>
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>จำนวน</b>
                </label>
                <label>{this.state.total_qty}</label>
              </div>
              <div className="mb-2 col-12 d-flex">
                <label className="wpx-180">
                  <b>ค่าบริการ</b>
                </label>
                <div>
                  {this.state.rate_list.map((item, index) => (
                    <div>
                      <label className="wpx-600">
                        {index + 1}. {item.remark}
                      </label>
                    </div>
                  ))}
                  {this.state.is_vat && (
                    <div>
                    <label className="wpx-600">
                      ค่าบริการมี Vat
                    </label>
                  </div>
                  )}
                </div>
                <div className="ms-4">
                  {this.state.rate_list.map((item, index) => (
                    <div>
                      <label className="wpx-300">อัตราค่าบริการ {(item.qty_price * item.qty)-(item.discount)} บาท</label>
                    </div>
                  ))}
                  {this.state.is_vat && (
                    <div>
                    <label className="wpx-300">อัตราค่าบริการ {toFixed(this.total()*0.07)} บาท</label>
                  </div>
                  )}
                </div>
              </div>
              <div className="mb-2 col-12">
                <label className="wpx-180">
                  <b>รวมเป็นเงิน</b>
                </label>
                <label>
                  <b>{this.state.is_vat?toFixed(this.total()+(this.total()*0.07)):toFixed(this.total())}</b>
                </label>
              </div>
            </div>
            <hr />
            <h5>
              <b>ข้อมูลนายจ้าง</b>
            </h5>
            <hr />
            {this.state.emp_data && (
              <div className="row">
                {/* เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก) */}
                <div className="mb-3 col-6">
                  <b>เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก)</b>
                </div>
                <div className="mb-3 col-6">{this.state.emp_data.emp_number ? this.state.emp_data.emp_number : "-"}</div>
                {/* English */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>English</b>
                  </div>
                  {this.state.emp_data.full_name_en ? this.state.emp_data.full_name_en : "-"}
                </div>
                {/* ประเภทนายจ้าง */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ประเภทนายจ้าง</b>
                  </div>
                  {this.state.emp_data.emp_type === 1 ? "บุคคลธรรมดา" : this.state.emp_data.emp_type === 2 ? "นิติบุคคล" : this.state.emp_data.emp_type === 3 ? "นิติบุคคลต่างด้าว" : ""}
                </div>
                {/* จดทะเบียนเมื่อ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>จดทะเบียนเมื่อ</b>
                  </div>
                  {this.state.emp_data.register_date ? format_date(this.state.emp_data.register_date) : "-"}
                </div>
                {/* ทุนจดทะเบียน (บาท) */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ทุนจดทะเบียน (บาท)</b>
                  </div>
                  {this.state.emp_data.register_capital ? toFixed(this.state.emp_data.register_capital) : "-"}
                </div>
                {/* ประเภทกิจการ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ประเภทกิจการ</b>
                  </div>
                  {this.state.emp_data.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.emp_data.business_type.toString())[0].label : "-"}
                </div>
                {/* ชื่อผู้ติดต่อ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ชื่อผู้ติดต่อ</b>
                  </div>
                  {this.state.emp_data.contact_name ? this.state.emp_data.contact_name : "-"}
                </div>
                {/* หมายเลขโทรศัพท์ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>หมายเลขโทรศัพท์</b>
                  </div>
                  {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                </div>
                {/* หมายเลขโทรสาร */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>หมายเลขโทรสาร</b>
                  </div>
                  {this.state.emp_data.fax ? this.state.emp_data.fax : "-"}
                </div>
                {/* หมายเลขโทรศัพท์มือถือ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>หมายเลขโทรศัพท์มือถือ</b>
                  </div>
                  {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                </div>
                {/* E-mail Address */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>E-mail Address</b>
                  </div>
                  {this.state.emp_data.email ? this.state.emp_data.email : "-"}
                </div>
                {/* เลขรหัสประจำบ้าน ๑๑ หลัก */}
                <div className="mb-3 d-flex col-12">
                  <div className="wpx-220">
                    <b>เลขรหัสประจำบ้าน ๑๑ หลัก</b>
                  </div>
                  {this.state.emp_data.home_number ? this.state.emp_data.home_number : "-"}
                </div>
                {/* ที่ตั้ง/ที่อยู่ */}
                <div className="mb-3 d-flex col-12">
                  <div className="wpx-220">
                    <b>ที่ตั้ง/ที่อยู่</b>
                  </div>
                  {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                  {this.state.emp_data.moo ? " หมู่ " + this.state.emp_data.moo : ""}
                  {this.state.emp_data.soi ? " ซอย " + this.state.emp_data.soi : ""}
                  {this.state.emp_data.street ? " ถนน " + this.state.emp_data.street : ""}
                  {this.state.emp_data.sub_district ? " ตำบล " + this.state.emp_data.sub_district : ""}
                  {this.state.emp_data.district ? " อำเภอ " + this.state.emp_data.district : ""}
                  {this.state.emp_data.province ? " จังหวัด " + this.state.emp_data.province : ""}
                  {this.state.emp_data.zipcode ? " รหัสไปรษณีย์ " + this.state.emp_data.zipcode : ""}
                </div>
                {/* ที่ตั้ง/ที่อยู่ (English) */}
                <div className="mb-3 d-flex col-12">
                  <div className="wpx-220">
                    <b>ที่ตั้ง/ที่อยู่ (English)</b>
                  </div>
                  {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                  {this.state.emp_data.moo ? " Moo " + this.state.emp_data.moo : ""}
                  {this.state.emp_data.soi ? " Soi " + this.state.emp_data.soi : ""}
                  {this.state.emp_data.street ? " Street " + this.state.emp_data.street : ""}
                  {this.state.emp_data.sub_district ? " , " + subdistrict.filter((e) => e.sub_district_name === this.state.emp_data.sub_district)[0]?.sub_district_name_en : ""}
                  {this.state.emp_data.district ? " , " + district.filter((e) => e.district_name === this.state.emp_data.district)[0]?.district_name_en : ""}
                  {this.state.emp_data.province ? " , " + province.filter((e) => e.province_name === this.state.emp_data.province)[0]?.province_name_en : ""}
                  {this.state.emp_data.zipcode ? " " + this.state.emp_data.zipcode : ""}
                </div>
                {/* หมายเลขโทรศัพท์ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>หมายเลขโทรศัพท์</b>
                  </div>
                  {this.state.emp_data.phone_2 ? this.state.emp_data.phone_2 : "-"}
                </div>
                {/* หมายเลขโทรสาร */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>หมายเลขโทรสาร</b>
                  </div>
                  {this.state.emp_data.fax_2 ? this.state.emp_data.fax_2 : "-"}
                </div>
                {/* ผู้มีอำนาจผูกพัน */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ผู้มีอำนาจผูกพัน</b>
                  </div>
                  {this.state.emp_data.fax_2 ? this.state.emp_data.fax_2 : "-"}
                </div>
                {/* ตำแหน่ง */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ตำแหน่ง</b>
                  </div>
                  {this.state.emp_data.fax_2 ? this.state.emp_data.fax_2 : "-"}
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex justify-content-end">
              <div className="wpx-150 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_detail: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="wpx-150 ps-1">
                <button
                  className="btn btn-primary w-100"
                  onClick={() => {
                    Swal.fire({
                      icon: "info",
                      title: "แจ้งเตือน",
                      text: "ยืนยันการเปิดเรื่องใช่หรือไม่",
                      confirmButtonText: "ยืนยัน",
                      showCancelButton: true,
                      cancelButtonText: "ยกเลิก",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.Update();
                      } 
                    });
                    
                  }}
                >
                  ยืนยันเปิดเรื่อง
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
        {/* ออกใบเสนอราคา */}
        <Modal
          show={this.state.modal_export}
          onHide={() => {
            this.setState({ modal_export: false });
          }}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <b>ออกใบเสนอราคา</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>
              <b>ข้อมูลรายละเอียดรับเรื่อง</b>
            </h5>
            <hr />
            <div className="row">
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>วันที่สร้าง</b>
                </label>
                <label>{format_date(this.state.createdAt)}</label>
              </div>
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>ลูกค้า</b>
                </label>
                <label>{this.state.full_name}</label>
              </div>
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>บริการที่เสนอราคา</b>
                </label>
                <label>{this.state.menu_name}</label>
              </div>
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>สัญชาติ</b>
                </label>
                <label>{this.state.cambodia_qty ? "กัมพูชา " : ""}</label>
                <label>{this.state.laos_qty ? "ลาว " : ""}</label>
                <label>{this.state.myanmar_qty ? "เมียนมา " : ""}</label>
                <label>{this.state.vietnam_qty ? "เวียดนาม " : ""}</label>
              </div>
              <div className="mb-2 col-12 col-md-6">
                <label className="wpx-180">
                  <b>จำนวน</b>
                </label>
                <label>{this.state.total_qty}</label>
              </div>
              <div className="mb-2 col-12">
                <label className="wpx-180">
                  <b>ค่าบริการ</b>
                </label>
                <div>
                  {this.state.rate_list.map((item, index) => (
                    <div className="d-flex">
                      <label className="wpx-300"> {index + 1}. {item.remark} </label>
                      {this.state.menu_name.includes("90") && <label className="wpx-300">ค่าธรรมเนียม {item.vat} บาท</label>}
                      <label className="wpx-300">อัตราค่าบริการ {(item.qty_price * item.qty)-(item.discount)} บาท</label>
                    </div>
                  ))}
                  {this.state.is_vat && (
                    <div className="d-flex">
                    <label className="wpx-300"> ค่าบริการมี Vat </label>
                    <label className="wpx-300">อัตราค่าบริการ {toFixed(this.total()*0.07)} บาท</label>
                  </div>
                  )}
                </div>
              </div>
              <div className="mb-2 col-12">
                <label className="wpx-180"> <b>รวมเป็นเงิน</b> </label>
                <label>
                  <b>{this.state.is_vat?toFixed(this.total()+(this.total()*0.07)):toFixed(this.total())}</b>
                </label>
              </div>
              <div className="mb-2 col-12">
                <label className="wpx-180">
                  <b>งวดการเก็บค่าบริการ {this.state.installment.length} งวด</b>
                </label>
                <div>
                  {this.state.installment.map((item, index) => (
                    <div>
                        งวดที่ {index + 1}. {item.amount} บาท คิดเป็น {toFixed(item.percent)}% {item.remark ? "("+item.remark+")":""}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <hr />
            <h5>
              <b>ข้อมูลนายจ้าง</b>
            </h5>
            <hr />
            {this.state.emp_data && (
              <div>
              <div className="row">
                {/* เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก) */}
                <div className="mb-3 col-6">
                  <b>เลขนิติบุคคล / เลขประจำตัวผู้เสียภาษี (13 หลัก)</b>
                </div>
                <div className="mb-3 col-6">{this.state.emp_data.emp_number ? this.state.emp_data.emp_number : "-"}</div>
                {/* English */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>English</b>
                  </div>
                  {this.state.emp_data.full_name_en ? this.state.emp_data.full_name_en : "-"}
                </div>
                {/* ประเภทนายจ้าง */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ประเภทนายจ้าง</b>
                  </div>
                  {this.state.emp_data.emp_type === 1 ? "บุคคลธรรมดา" : this.state.emp_data.emp_type === 2 ? "นิติบุคคล" : this.state.emp_data.emp_type === 3 ? "นิติบุคคลต่างด้าว" : ""}
                </div>
                {/* จดทะเบียนเมื่อ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>จดทะเบียนเมื่อ</b>
                  </div>
                  {this.state.emp_data.register_date ? format_date(this.state.emp_data.register_date) : "-"}
                </div>
                {/* ทุนจดทะเบียน (บาท) */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ทุนจดทะเบียน (บาท)</b>
                  </div>
                  {this.state.emp_data.register_capital ? toFixed(this.state.emp_data.register_capital) : "-"}
                </div>
                {/* ประเภทกิจการ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ประเภทกิจการ</b>
                  </div>
                  {this.state.emp_data.business_type ? this.state.data_business_type.filter((e) => e.value === this.state.emp_data.business_type.toString())[0].label : "-"}
                </div>
                {/* ชื่อผู้ติดต่อ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ชื่อผู้ติดต่อ</b>
                  </div>
                  {this.state.emp_data.contact_name ? this.state.emp_data.contact_name : "-"}
                </div>
                {/* หมายเลขโทรศัพท์ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>หมายเลขโทรศัพท์</b>
                  </div>
                  {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                </div>
                {/* หมายเลขโทรสาร */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>หมายเลขโทรสาร</b>
                  </div>
                  {this.state.emp_data.fax ? this.state.emp_data.fax : "-"}
                </div>
                {/* หมายเลขโทรศัพท์มือถือ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>หมายเลขโทรศัพท์มือถือ</b>
                  </div>
                  {this.state.emp_data.phone ? this.state.emp_data.phone : "-"}
                </div>
                {/* E-mail Address */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>E-mail Address</b>
                  </div>
                  {this.state.emp_data.email ? this.state.emp_data.email : "-"}
                </div>
                {/* เลขรหัสประจำบ้าน ๑๑ หลัก */}
                <div className="mb-3 d-flex col-12">
                  <div className="wpx-220">
                    <b>เลขรหัสประจำบ้าน ๑๑ หลัก</b>
                  </div>
                  {this.state.emp_data.home_number ? this.state.emp_data.home_number : "-"}
                </div>
                {/* ที่ตั้ง/ที่อยู่ */}
                <div className="mb-3 d-flex col-12">
                  <div className="wpx-220">
                    <b>ที่ตั้ง/ที่อยู่</b>
                  </div>
                  {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                  {this.state.emp_data.moo ? " หมู่ " + this.state.emp_data.moo : ""}
                  {this.state.emp_data.soi ? " ซอย " + this.state.emp_data.soi : ""}
                  {this.state.emp_data.street ? " ถนน " + this.state.emp_data.street : ""}
                  {this.state.emp_data.sub_district ? " ตำบล " + this.state.emp_data.sub_district : ""}
                  {this.state.emp_data.district ? " อำเภอ " + this.state.emp_data.district : ""}
                  {this.state.emp_data.province ? " จังหวัด " + this.state.emp_data.province : ""}
                  {this.state.emp_data.zipcode ? " รหัสไปรษณีย์ " + this.state.emp_data.zipcode : ""}
                </div>
                {/* ที่ตั้ง/ที่อยู่ (English) */}
                <div className="mb-3 d-flex col-12">
                  <div className="wpx-220">
                    <b>ที่ตั้ง/ที่อยู่ (English)</b>
                  </div>
                  {this.state.emp_data.address_number ? this.state.emp_data.address_number : ""}
                  {this.state.emp_data.moo ? " Moo " + this.state.emp_data.moo : ""}
                  {this.state.emp_data.soi ? " Soi " + this.state.emp_data.soi : ""}
                  {this.state.emp_data.street ? " Street " + this.state.emp_data.street : ""}
                  {this.state.emp_data.sub_district ? " , " + subdistrict.filter((e) => e.sub_district_name === this.state.emp_data.sub_district)[0]?.sub_district_name_en : ""}
                  {this.state.emp_data.district ? " , " + district.filter((e) => e.district_name === this.state.emp_data.district)[0]?.district_name_en : ""}
                  {this.state.emp_data.province ? " , " + province.filter((e) => e.province_name === this.state.emp_data.province)[0]?.province_name_en : ""}
                  {this.state.emp_data.zipcode ? " " + this.state.emp_data.zipcode : ""}
                </div>
                {/* หมายเลขโทรศัพท์ */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>หมายเลขโทรศัพท์</b>
                  </div>
                  {this.state.emp_data.phone_2 ? this.state.emp_data.phone_2 : "-"}
                </div>
                {/* หมายเลขโทรสาร */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>หมายเลขโทรสาร</b>
                  </div>
                  {this.state.emp_data.fax_2 ? this.state.emp_data.fax_2 : "-"}
                </div>
              </div>
              {this.state.emp_data && this.state.emp_data.dpz_data && this.state.emp_data.dpz_data.filter(e=>e.deputize_selected).map((item,index)=>(
                <div className="row">
                {/* ผู้มีอำนาจผูกพัน */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>{index+1}. ผู้มีอำนาจผูกพัน</b>
                  </div>
                  {item.deputize_full_name ? item.deputize_full_name : "-"}
                </div>
                {/* ตำแหน่ง */}
                <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ตำแหน่ง</b>
                  </div>
                  {item.deputize_position ? item.deputize_position : "-"}
                </div>
                 {/* ผู้มีอำนาจผูกพัน(English) */}
                 <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ผู้มีอำนาจผูกพัน(English)</b>
                  </div>
                  {item.deputize_full_name_en ? item.deputize_full_name_en : "-"}
                </div>
                 {/* ตำแหน่ง(English) */}
                 <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>ตำแหน่ง(English)</b>
                  </div>
                  {item.deputize_position_en ? item.deputize_position_en : "-"}
                </div>
                 {/* เลข 13 หลัก */}
                 <div className="mb-3 d-flex col-6">
                  <div className="wpx-220">
                    <b>เลข 13 หลัก</b>
                  </div>
                  {item.deputize_id_card ? item.deputize_id_card : "-"}
                </div>
                </div>
                 ))}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex justify-content-end">
              <div className="wpx-150 pe-1">
                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => {
                    this.setState({ modal_export: false });
                  }}
                >
                  ยกเลิก
                </button>
              </div>
              <div className="wpx-180 ps-1">
                { this.state.quotation_status === 1 ? (
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      Swal.fire({
                        icon: "info",
                        title: "แจ้งเตือน",
                        text: "ยืนยันการเปิดเรื่องใช่หรือไม่",
                        confirmButtonText: "ยืนยัน",
                        showCancelButton: true,
                        cancelButtonText: "ยกเลิก",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          this.Update();
                        } 
                      });
                      
                    }}
                  >
                    ยืนยันออกใบรับเรื่อง
                  </button>
                ): (
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      window.location.href="/RecieveAll/update?id="+this.state.receive_id
                    }}
                  >
                    ใบรับเรื่อง
                  </button>
                )}
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
